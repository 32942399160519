import { useLayoutEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

const scroll = (elementToScroll: HTMLElement | null) => {
  elementToScroll?.scrollIntoView({
    block: 'start',
    behavior: 'smooth',
    inline: 'start',
  });
};

interface UseScrollToHashProps {
  scrollOnMount?: boolean;
  withHash?: boolean;
}

export const useScrollToHash = (opts: UseScrollToHashProps) => {
  const { scrollOnMount = true, withHash = false } = opts;
  const history = useHistory();
  const firstRender = useRef(!scrollOnMount);

  /**
   * The id (without hash) of the element to scroll
   * @param {String} id
   */
  const scrollTo = (id: string) => {
    const elementToScroll = document.getElementById(id);

    if (elementToScroll && withHash) {
      history.replace({ hash: id, search: history.location.search });
    }

    scroll(elementToScroll);
  };

  useLayoutEffect(() => {
    const {
      location: { hash },
    } = history;

    if (hash) {
      const id = hash?.substring(1);
      const elementToScroll = document.getElementById(id);

      if (!firstRender.current && elementToScroll) {
        setTimeout(() => {
          scroll(elementToScroll);
        }, 100);
      }

      // eslint-disable-next-line immutable/no-mutation
      firstRender.current = false;
    }
  }, [history]);

  return { scrollTo };
};
