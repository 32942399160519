import { Edit, Lock, NavigateNext } from '@mui/icons-material';
import {
  Breadcrumbs,
  Chip,
  CircularProgress,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import type { FC } from 'react';
import { useContext, useEffect, useState } from 'react';
import { generatePath, NavLink, useParams } from 'react-router-dom';
import { paths } from '../../../../../../routing/Router/paths';
import { auth } from '../../../../../../utils/auth';
import { CategoriesContext } from '../../../../categories.context';
import { DEFAULT_ROOT_CATEGORY } from '../../../../constants';
import { DataTestIdListPage } from '../../../../tests/constants';
import type { Category } from '../../../../types';
import { getFlattenedBrandCategories, navigateTo } from '../../../../utils';
import { MAX_SEGMENTS_BREADCRUMBS } from './constants';
import { useStyles } from './styles';

const isRootCategory = (category: Category | null): boolean =>
  category?.level === DEFAULT_ROOT_CATEGORY.level;

interface BreadCrumb {
  id: string;
  isActive?: boolean;
  pathname?: string;
  title: string;
}

export const BreadCrumbs: FC = () => {
  const { activeCategory, categories, isCategoriesLoading } = useContext(
    CategoriesContext,
  );

  const routeParams = useParams();
  const [breadCrumbs, setBreadCrumbs] = useState<BreadCrumb[]>([]);
  const isRootCategoryActive = isRootCategory(activeCategory);
  const classes = useStyles({
    isRootCategoryActive,
  });
  const theme = useTheme();

  const isAllowedToInteractWithRootCategory = auth.user?.isSuperAdmin();

  useEffect(() => {
    const {
      brandKey,
      format,
      lvl1 = categories[0]?.id,
      lvl2,
      lvl3,
      lvl4,
      lvl5,
    } = routeParams;
    const filteredBrandCategories = getFlattenedBrandCategories<Category[]>(
      categories,
      {
        filter: { key: 'id', filterList: [lvl1, lvl2, lvl3, lvl4, lvl5] },
      },
    );
    const newBreadCrumbs = filteredBrandCategories.map(
      ({ id, path, title }, index) => ({
        id,
        isActive: index + 1 === filteredBrandCategories.length,
        pathname: `${generatePath(paths.PAGE_LIST, {
          brandKey,
          format,
        })}${path}`,
        title: !index ? (
          <span className={classes.wrapperBreadCrumbRootCategory}>
            <Chip
              color="primary"
              label="Racine"
              size="small"
              {...(!isAllowedToInteractWithRootCategory && {
                deleteIcon: <Lock />,
                onDelete: () => {},
              })}
            />
            {title}
          </span>
        ) : (
          title
        ),
      }),
    );

    setBreadCrumbs(newBreadCrumbs as BreadCrumb[]);
    // eslint-disable-next-line
  }, [
    categories,
    routeParams,
    activeCategory,
    isAllowedToInteractWithRootCategory,
  ]);
  const canInteractWithRootCategory =
    isAllowedToInteractWithRootCategory &&
    (activeCategory?.children || []).length === 0;
  return (
    <div className={classes.wrapper}>
      <Breadcrumbs
        aria-label="breadcrumb"
        maxItems={MAX_SEGMENTS_BREADCRUMBS}
        separator={<NavigateNext fontSize="small" />}
      >
        {breadCrumbs.map((breadCrumb: BreadCrumb, index: number) => {
          const level = index + 1;

          const shouldShowNavLink =
            breadCrumb.pathname && level > 1 && !breadCrumb.isActive;

          if (shouldShowNavLink) {
            return (
              <NavLink
                className={
                  isRootCategoryActive
                    ? classes.breadCrumbRootLink
                    : classes.breadCrumbLink
                }
                data-testid={DataTestIdListPage.LIST_BREADCRUMBS_LINK}
                key={breadCrumb.id || index}
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                to={breadCrumb.pathname!}
                style={{
                  color: breadCrumb.isActive
                    ? theme.palette.primary.main
                    : theme.palette.grey[500],
                }}
              >
                {breadCrumb.title}
              </NavLink>
            );
          }

          return (
            <Typography
              className={classes.breadCrumbLink}
              data-testid={DataTestIdListPage.LIST_BREADCRUMBS_TEXT}
              key={breadCrumb.id || index}
              sx={{
                color: breadCrumb.isActive
                  ? theme.palette.primary.main
                  : theme.palette.grey[500],
              }}
            >
              {breadCrumb.title}
            </Typography>
          );
        })}

        {isCategoriesLoading && (
          <CircularProgress
            className={classes.loader}
            color="primary"
            size={20}
          />
        )}
      </Breadcrumbs>

      {!isCategoriesLoading &&
        (breadCrumbs.length !== DEFAULT_ROOT_CATEGORY.level ||
          canInteractWithRootCategory) && (
          <Tooltip title="Modifier le template" sx={{ ml: 1 }}>
            <IconButton
              color="primary"
              size="small"
              onClick={() => {
                const search = new URLSearchParams({
                  from: 'Breadcrumbs',
                }).toString();

                navigateTo({
                  pathname: paths.PAGE_EDIT,
                  routeParams,
                  opts: { search },
                });
              }}
            >
              <Edit sx={{ fontSize: 18 }} />
            </IconButton>
          </Tooltip>
        )}
    </div>
  );
};
