import { TextField } from '@mui/material';
import {
  EditAppBar,
  FieldValidator,
  NonEditableField,
  PageWithDrawer,
  SimpleSelect,
  getRequiredErrorMessage,
  objectsAreDifferents,
} from '@prismamedia/one-components';
import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  ArticleFormat,
  BrandKey,
  CreateQualifier,
  GetQualifier_qualifier,
  QualifierStatus,
} from '../../../__generated__/queries-web';
import { replaceParams } from '../../../routing/Router';
import { paths } from '../../../routing/Router/paths';
import { useGlobalBrandKey } from '../../../utils/globalState';
import { useGetQualifier } from './getQualifier.web.graphql';
import { useStyles } from './styles';

import {
  useCreateQualifier,
  useUpdateQualifier,
} from './updateQualifier.web.graphql';

const getEmptyItem = (brandKey: BrandKey): GetQualifier_qualifier => ({
  __typename: 'Qualifier',
  id: '',
  brandKey,
  format: ArticleFormat.Rich,
  title: '',
  status: QualifierStatus.Draft,
});

const saveMandatoryFields: FieldValidator<GetQualifier_qualifier>[] = [
  {
    label: 'Titre',
    validate: ({ title }) => !!title,
  },
  {
    label: 'Statut',
    validate: ({ status }) => !!status,
  },
];

export const QualifierEditPage: FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const [brandKey] = useGlobalBrandKey();
  const [item, setItem] = useState<GetQualifier_qualifier | undefined>(
    undefined,
  );
  const { id } = useParams();
  const isCreate = id === 'new';
  const { data, loading, error } = useGetQualifier(id, isCreate);
  const [saveLoading, setSaveLoading] = useState(false);
  const updateItem = useUpdateQualifier();
  const createItem = useCreateQualifier();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const emptyItem = useMemo(() => getEmptyItem(brandKey!), [brandKey]);
  const itemDiff = objectsAreDifferents(
    item,
    isCreate ? emptyItem : data?.qualifier,
  );
  const requiredFields = getRequiredErrorMessage<GetQualifier_qualifier>(
    item,
    saveMandatoryFields,
  );

  useEffect(() => {
    setItem(isCreate ? emptyItem : data?.qualifier || undefined);
  }, [isCreate, data, emptyItem, setItem]);

  const onSave = async () => {
    if (item) {
      setSaveLoading(true);
      const res = isCreate
        ? await createItem({
            title: item.title,
            status: item.status,
            brandKey: item.brandKey,
            format: item.format,
          })
        : await updateItem(item.id, {
            status: item.status,
            format: item.format,
          });
      setSaveLoading(false);
      if (res && isCreate) {
        history.replace(
          replaceParams(paths.QUALIFIER_EDIT, {
            id: (res.data as CreateQualifier).createQualifier.id,
          }),
        );
      }
    }
  };

  return (
    <>
      <EditAppBar
        title={
          isCreate ? item?.title || 'Nouveau qualifier' : data?.qualifier?.title
        }
        onNavigateBack={() => history.push(paths.QUALIFIER_LIST)}
        save={{
          disabled: !itemDiff && !isCreate,
          loading: saveLoading,
          onClick: onSave,
          requiredFields,
        }}
      />
      <PageWithDrawer
        loading={loading}
        error={
          error ||
          (!item && !data?.qualifier
            ? Error("Ce qualifier n'existe pas")
            : undefined)
        }
        rightDrawer={
          <NonEditableField
            className={classes.field}
            label="Marque"
            value={item?.brandKey}
          />
        }
      >
        {item && (
          <>
            <TextField
              className={classes.field}
              disabled={!isCreate}
              label="Titre"
              onChange={({ target: { value } }) => {
                setItem((prev) => (prev ? { ...prev, title: value } : prev));
              }}
              value={item.title}
              variant="standard"
            />
            <SimpleSelect
              label="Statut"
              className={classes.field}
              options={Object.values(QualifierStatus).map((qualifierStatus) => {
                return { value: qualifierStatus, label: qualifierStatus };
              })}
              value={item.status}
              onChange={(status) =>
                setItem((prev) =>
                  prev ? { ...prev, status: status as QualifierStatus } : prev,
                )
              }
            />
            <SimpleSelect
              label="Format"
              className={classes.field}
              options={Object.values(ArticleFormat).map((articleFormat) => {
                return { value: articleFormat, label: articleFormat };
              })}
              value={item.format}
              onChange={(format) =>
                setItem((prev) =>
                  prev ? { ...prev, format: format as ArticleFormat } : prev,
                )
              }
            />
          </>
        )}
      </PageWithDrawer>
    </>
  );
};
