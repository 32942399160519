import { ApolloQueryResult, useLazyQuery, useMutation } from '@apollo/client';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import {
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import {
  Loader,
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import { useState } from 'react';
import {
  BrandKey,
  GenerateSlideshowIA,
  GenerateSlideshowIAVariables,
  GetVertexAIRecipes,
  GetVertexAIRecipesVariables,
  GetVertexAIRecipes_getVertexAIRecipes,
} from '../../__generated__/queries-web';
import { client } from '../../apollo';
import { GENERATE_SLIDESHOW_IA } from '../../apollo/mutations/generateSlideshow.web.graphql';
import { GET_USER_OPEN_AI_PROCESSES } from '../../apollo/queries/getOpenAiProcess.web.graphql';
import { GET_VERTEX_AI_RECIPES } from '../../apollo/queries/getVertexAIRecipes.web.graphql';

const SlideshowGenerationDialog = ({
  isDialogOpen,
  setIsDialogOpen,
  searchResult,
  recipeNumber,
  slideshowSubject,
  brandKey,
  refetchIngredients,
  loading,
  setActiveTab,
}: {
  isDialogOpen: boolean;
  setIsDialogOpen: React.Dispatch<React.SetStateAction<boolean>>;
  searchResult: GetVertexAIRecipes_getVertexAIRecipes;
  recipeNumber: number;
  slideshowSubject: string;
  brandKey: BrandKey;
  refetchIngredients: (
    variables?: Partial<GetVertexAIRecipesVariables> | undefined,
  ) => Promise<ApolloQueryResult<GetVertexAIRecipes>>;
  loading: boolean;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [ingredientsChecked, setIngredientsChecked] = useState<string[]>([]);
  const [excludedIngredients, setExcludedIngredients] = useState<string[]>([]);

  const [generateSlideshowIA] = useMutation<
    GenerateSlideshowIA,
    GenerateSlideshowIAVariables
  >(GENERATE_SLIDESHOW_IA, {
    onCompleted: () => {
      setActiveTab(2);
      client.refetchQueries({
        include: [GET_USER_OPEN_AI_PROCESSES],
      });
    },
  });

  const handleToggle = (value: string) => () => {
    const currentIndex = ingredientsChecked.indexOf(value);
    const newChecked = [...ingredientsChecked];

    if (currentIndex === -1) {
      // eslint-disable-next-line fp/no-mutating-methods
      newChecked.push(value);
    } else {
      // eslint-disable-next-line fp/no-mutating-methods
      newChecked.splice(currentIndex, 1);
    }

    setIngredientsChecked(newChecked);
  };

  return (
    <Dialog
      fullWidth={false}
      maxWidth="md"
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
    >
      <DialogTitle>
        Souhaitez-vous exclure des ingrédients des résultats de la recherche ?
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          {searchResult.ingredients.map(({ name, count }) => (
            <Grid
              item
              xs={12}
              sm={6}
              md={4}
              key={name}
              onClick={handleToggle(name)}
              style={{ cursor: 'pointer' }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Checkbox
                  edge="start"
                  checked={ingredientsChecked.indexOf(name) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{
                    'aria-labelledby': `checkbox-label-${name}`,
                  }}
                />
                <Typography id={`checkbox-label-${name}`}>
                  {`${name} (${count})`}
                </Typography>
              </div>
            </Grid>
          ))}
        </Grid>
      </DialogContent>
      {excludedIngredients.length > 0 && (
        <Box
          sx={{
            pt: 2,
            px: 2,
          }}
        >
          <Typography variant="caption" color="text.secondary">
            Ingrédients exclus :{' '}
            {Array.from(new Set(excludedIngredients)).join(', ')}
          </Typography>
        </Box>
      )}
      <DialogActions
        sx={{
          py: 1,
        }}
      >
        <Button
          color="secondary"
          variant="contained"
          onClick={() => setIsDialogOpen(false)}
        >
          Annuler
        </Button>
        {(() => {
          if (loading) {
            return <Loader />;
          }
          if (ingredientsChecked.length > 0) {
            return (
              <Button
                color="primary"
                onClick={() => {
                  const newlyExcludedIngredients = [
                    ...excludedIngredients,
                    ...ingredientsChecked,
                  ];
                  setExcludedIngredients(newlyExcludedIngredients);
                  setIngredientsChecked([]);
                  refetchIngredients({
                    excludedIngredients: newlyExcludedIngredients,
                    recipeNumber,
                    brandKey,
                    slideshowSubject,
                  });
                }}
                variant="contained"
              >
                Relancer la recherche
              </Button>
            );
          }
          return (
            <Button
              color="primary"
              onClick={() => {
                generateSlideshowIA({
                  variables: {
                    recipeNumber,
                    slideshowSubject,
                    brandKey,
                    recipeIds: searchResult.recipesIds,
                  },
                });
              }}
              variant="contained"
            >
              Lancer la génération du diaporama
            </Button>
          );
        })()}
      </DialogActions>
    </Dialog>
  );
};

export const SlideshowGenerateForm = ({
  brandKey,
  setActiveTab,
}: {
  brandKey: BrandKey;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const [slideshowSubject, setSlideshowSubject] = useState<string | null>(null);
  const [recipeNumber, setRecipeNumber] = useState<number>(0);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const { pushNotification } = useNotification();

  const [
    getVertexAIRecipes,
    { loading, error, data, refetch: refetchIngredients },
  ] = useLazyQuery<GetVertexAIRecipes, GetVertexAIRecipesVariables>(
    GET_VERTEX_AI_RECIPES,
  );

  if (error) {
    pushNotification({
      type: NotificationTypeEnum.error,
      message: 'Erreur lors de la récupération des ingrédients',
    });
  }

  return (
    <form
      onSubmit={async (formEvent) => {
        formEvent.preventDefault();
        if (!slideshowSubject || !recipeNumber) {
          return pushNotification({
            type: NotificationTypeEnum.error,
            message: 'Le sujet et la longueur du diaporama sont obligatoires.',
          });
        }
        await getVertexAIRecipes({
          variables: {
            recipeNumber,
            slideshowSubject,
            brandKey,
          },
        });
        setIsDialogOpen(true);
      }}
    >
      {isDialogOpen && slideshowSubject && data?.getVertexAIRecipes && (
        <SlideshowGenerationDialog
          isDialogOpen={isDialogOpen}
          setIsDialogOpen={setIsDialogOpen}
          searchResult={data.getVertexAIRecipes}
          recipeNumber={recipeNumber}
          slideshowSubject={slideshowSubject}
          brandKey={brandKey}
          refetchIngredients={refetchIngredients}
          loading={loading}
          setActiveTab={setActiveTab}
        />
      )}
      <Card
        variant="outlined"
        sx={{
          padding: '40px',
          display: 'flex',
          flexDirection: 'column',
          gap: '32px',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: '8px',
          }}
        >
          <AutoFixHighIcon
            fontSize="small"
            sx={{ marginRight: '2px', color: 'text.secondary' }}
          />
          <Typography
            sx={{ fontSize: 14, paddingTop: '1.5px' }}
            color="text.secondary"
            gutterBottom
          >
            Paramètres du diaporama
          </Typography>
        </Box>
        <Box>
          <FormControl sx={{ minWidth: '45%' }}>
            <InputLabel id="diapoType">Type de diaporama</InputLabel>
            <Select
              key={'1'}
              label="Type de diaporama"
              labelId="diapoType"
              value={'recipe'}
              onChange={() => {}}
            >
              <MenuItem key={'1'} value={'recipe'}>
                Recette
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: 'flex',
            // flexDirection: 'column',
            justifyContent: 'space-between',
            gap: '16px',
          }}
        >
          <TextField
            sx={{
              width: '45%',
            }}
            id="outlined-error"
            type="number"
            label="Nombre de recettes"
            value={recipeNumber}
            onChange={(e) => setRecipeNumber(parseInt(e.target.value, 10))}
            required
          />
          <TextField
            sx={{
              width: '45%',
            }}
            id="outlined-error-helper-text"
            label="Sujet du diaporama"
            placeholder=""
            required
            value={slideshowSubject}
            onChange={(e) => setSlideshowSubject(e.target.value)}
          />
        </Box>
        <DialogActions>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
            }}
          >
            {loading ? (
              <Loader />
            ) : (
              <Button
                data-testid="confirmBtn"
                color="primary"
                onClick={() => null}
                variant="contained"
                type="submit"
              >
                {'Rechercher des recettes'}
              </Button>
            )}
          </Box>
        </DialogActions>
      </Card>
    </form>
  );
};
