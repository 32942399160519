import type { ApolloError } from '@apollo/client';
import {
  NotificationTypeEnum,
  useNotification,
} from '@prismamedia/one-components';
import type { Dispatch, FC, ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';
import { BrandKey, CategoryFormat } from '../../__generated__/queries-web';
import { usePageCategories } from './apollo/queries/categories.web.graphql';
import {
  DEFAULT_ROOT_CATEGORY,
  FETCH_CATEGORIES_ERROR_MESSAGE,
} from './constants';
import type { Category } from './types';
import {
  getActivePathCategoryId,
  getBrandCategoriesWithPath,
  getFlattenedBrandCategories,
  navigateTo,
} from './utils';

const INITIAL_ACTIVE_CATEGORY: Category | null = null;
const INITIAL_CATEGORIES: Category[] | null = [];

// CONTEXT
interface CategoriesContextProps {
  activeCategory: Category | null;
  activeCategoryRouteId: string | null;
  brandKey?: BrandKey;
  categories: Category[];
  categoriesError: ApolloError | null | undefined;
  isCategoriesLoading: boolean;
  refetchCategories: () => void;
  setActiveCategory: Dispatch<Category | null>;
  setCategories: Dispatch<Category[]>;
}
const CategoriesContext = createContext<CategoriesContextProps>({
  activeCategory: INITIAL_ACTIVE_CATEGORY,
  activeCategoryRouteId: null,
  categories: INITIAL_CATEGORIES,
  categoriesError: null,
  isCategoriesLoading: false,
  refetchCategories: () => null,
  setActiveCategory: () => null,
  setCategories: () => null,
});

// PROVIDER
interface CategoriesProviderProps {
  brandKey: BrandKey | undefined;
  children: ReactNode;
  format: CategoryFormat | undefined;
}
const CategoriesProvider: FC<CategoriesProviderProps> = ({
  brandKey,
  children,
  format,
}) => {
  const { pushNotification } = useNotification();
  const routeParams = useParams();
  const { error, data: dataCategories, loading, refetch } = usePageCategories({
    variables: {
      first: 1000,
      where: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        brandKey_in: [DEFAULT_ROOT_CATEGORY.brandKey, brandKey!],
        format,
        level_in: [
          DEFAULT_ROOT_CATEGORY.level,
          DEFAULT_ROOT_CATEGORY.level + 1,
        ],
      },
    },
    skip: !brandKey,
  });

  const activeCategoryRouteId = useMemo(
    () => getActivePathCategoryId(routeParams),
    [routeParams],
  );

  const [activeCategory, setActiveCategory] = useState<Category | null>(
    INITIAL_ACTIVE_CATEGORY,
  );
  const [categories, setCategories] = useState<Category[]>(INITIAL_CATEGORIES);

  const refetchCategories = useCallback(() => {
    refetch().catch((e) => {
      if (e?.message) {
        pushNotification?.({
          type: NotificationTypeEnum.error,
          message: `
            [${FETCH_CATEGORIES_ERROR_MESSAGE}]
            ${e.message}
          `,
        });
      }
    });
  }, [pushNotification, refetch]);

  useEffect(() => {
    const rootCategory = dataCategories?.categories.filter(
      (category) =>
        category.brandKey === DEFAULT_ROOT_CATEGORY.brandKey &&
        category.level === DEFAULT_ROOT_CATEGORY.level,
    );

    const categoriesWithPathname = getBrandCategoriesWithPath(
      rootCategory || [],
    );
    setCategories(categoriesWithPathname);
  }, [dataCategories]);

  useEffect(() => {
    if (error?.message) {
      pushNotification?.({
        type: NotificationTypeEnum.error,
        message: `
          [${FETCH_CATEGORIES_ERROR_MESSAGE}]
          ${error.message}
        `,
      });
    }
    // eslint-disable-next-line
  }, [error]);

  /**
   * We setting the active category by listening when categories / routeParams changes
   **/
  useEffect(() => {
    const newActiveCategory =
      getFlattenedBrandCategories(categories, {
        filter: { key: 'id', filterList: [activeCategoryRouteId] },
      })?.[0] || null;

    setActiveCategory(newActiveCategory as Category);
  }, [categories, activeCategoryRouteId]);

  /**
   * If user try to access to :brandKey/page/:categoryFormat/(list/edit/create) without id at the end of the path,
   * we reformating it by adding the lvl1 category id
   **/
  useEffect(() => {
    if (categories?.length && !routeParams.lvl1) {
      const newRouteParams = {
        ...routeParams,
        lvl1: categories[0].id,
        lvl2: categories[0].children[0]?.id,
      };

      navigateTo({
        opts: { method: 'REPLACE' },
        routeParams: newRouteParams,
      });
    }
    // eslint-disable-next-line
  }, [categories, routeParams.lvl1]);

  return (
    <CategoriesContext.Provider
      value={{
        activeCategory,
        activeCategoryRouteId,
        brandKey,
        categories,
        categoriesError: error,
        isCategoriesLoading: loading,
        refetchCategories,
        setActiveCategory,
        setCategories,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};

export { CategoriesContext, CategoriesProvider };
