import { useQuery } from '@apollo/client';
import { AddAPhoto, Code } from '@mui/icons-material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import { Box, CircularProgress, Typography } from '@mui/material';
import {
  EmbedDialog,
  IconLabelButton,
  ItemType,
  MediaType,
  MultimediaPlugin,
  NotificationTypeEnum,
  OneMedia,
  handleDrop,
  useDialog,
  useNotification,
} from '@prismamedia/one-components';
import { RawDraftContentState } from 'draft-js';
import {
  ChangeEvent,
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import ReactGA from 'react-ga4';
import { useParams } from 'react-router-dom';
import {
  ArticleFormat,
  BrandKey,
  GetPrompt,
  GetPromptVariables,
  PromptField,
} from '../../__generated__/queries-web';
import { useDailymotionUpdate } from '../../apollo/mutations/dailymotion.web.graphql';
import { useGetArticle } from '../../apollo/queries/articles.web.graphql';
import { GET_PROMPT } from '../../apollo/queries/prompts.web.graphql';
import { useSuggestArticleQuery } from '../../apollo/queries/suggestArticles.web.graphql';
import { history } from '../../history';
import { useGlobalThumbnail } from '../../utils/globalState';
import { embedImage } from '../../utils/upload';
import { isPage } from '../../utils/url';
import { AdvancedSearch } from '../AppBar/AdvancedSearch';
import { searchByPluginType } from '../Draft/plugins/shared/searchArticleForMedia';
import { MEDIA_TYPES } from './constants';
import {
  getAllowedMedias,
  getItemToIframely,
  getItemToRender,
  postImages,
  postThumbnail,
} from './utils';

const ProgressLoader = ({ progressValue }: { progressValue: number }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: 450,
        position: 'relative',
      }}
    >
      <CircularProgress
        size={100}
        variant="determinate"
        value={progressValue}
      />
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: 'text.secondary' }}
        >
          {progressValue}%
        </Typography>
      </Box>
    </Box>
  );
};

export const MediaUploaders: FC<{
  medias: OneMedia[];
  setMedias: Dispatch<SetStateAction<OneMedia[]>>;
  brandKey: BrandKey;
  setSelectedMediaIndex: (index: number) => void;
  allowedMedias: MEDIA_TYPES[];
  subjectId?: string;
  videoId?: string;
  setLoading: (loading: boolean) => void;
  articleTitle?: string;
  articleLead?: RawDraftContentState | null;
  setLoader: Dispatch<SetStateAction<JSX.Element | undefined>>;
  articleFormat?: ArticleFormat;
}> = ({
  medias,
  setMedias,
  brandKey,
  setSelectedMediaIndex,
  allowedMedias,
  subjectId,
  videoId,
  setLoading,
  articleTitle,
  articleLead,
  setLoader,
  articleFormat,
}) => {
  const { openDialog, closeDialog } = useDialog();
  const { pushNotification } = useNotification();
  const [updateThumbnail] = useDailymotionUpdate();
  const [, setThumbnail] = useGlobalThumbnail();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const multimediaPluginMediaTypes = getAllowedMedias(allowedMedias, {
    subjectId,
  });
  const [imageGenerationCount, setImageGenerationCount] = useState(0);
  const [progressValue, setProgressValue] = useState(0);
  const { data: promptInstructions } = useQuery<GetPrompt, GetPromptVariables>(
    GET_PROMPT,
    {
      variables: {
        brandKey: BrandKey.CAP,
        label: 'article-image',
        field: PromptField.Body,
      },
    },
  );

  const { id } = useParams();
  const { data: dataArticles } = useGetArticle(id);
  const { suggestQuery } = useSuggestArticleQuery(dataArticles);
  const [loadingImageGeneration, setLoadingImageGeneration] = useState(false);
  const openEmbedDialog = () =>
    openDialog(
      <EmbedDialog
        fetcher={embedImage}
        onMediaLoaded={(media) => {
          const lastMediaIndex = medias.length - 1;
          setMedias((prev) => [...prev, media]);
          setSelectedMediaIndex(lastMediaIndex + 1);
          closeDialog();
        }}
      />,
      { fullWidth: true },
    );
  const onUploadImages = async (files: File[]) => {
    setLoading(true);
    try {
      const newImages = await handleDrop({
        files: files.filter((file) => !file.type.includes('video')),
        mediaType: MediaType.Image,
        disableImgWidth: true,
        handleFiles: postThumbnail(
          brandKey,
          updateThumbnail,
          pushNotification,
          videoId,
          setThumbnail,
        ),
        pushNotification,
      });
      const lastMediaIndex = medias.length - 1;
      setMedias((prev) => [...prev, ...newImages]);
      setSelectedMediaIndex(lastMediaIndex + 1);
    } catch (error) {
      pushNotification({
        message: (error as Error).message || 'Média non reconnu',
        type: NotificationTypeEnum.error,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout | null = null;
    if (loadingImageGeneration) {
      timer = setInterval(() => {
        setProgressValue((prev) => {
          if (prev >= 95) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            clearInterval(timer!);
            setLoader(<ProgressLoader progressValue={95} />);
            return 95;
          }
          setLoader(<ProgressLoader progressValue={prev + 5} />);
          return prev + 5;
        });
      }, 1200);
    } else {
      setProgressValue(0);
      if (timer) {
        clearInterval(timer);
      }
    }

    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, [loadingImageGeneration, setLoader]);

  const generateImage = async () => {
    try {
      setLoading(true);
      setLoadingImageGeneration(true);
      setLoader(
        <ProgressLoader key={progressValue} progressValue={progressValue} />,
      );
      const prompt = `${promptInstructions?.prompt?.body}
             Voici le titre de l'article: ${articleTitle}
             Voici le chapô de l'article: ${articleLead?.blocks
               .map((block) => block.text)
               .join(' ')}`;

      const response = await fetch(
        'https://api.openai.com/v1/images/generations',
        {
          method: 'POST',
          headers: {
            Authorization: `Bearer sk-1oHjyqPVHcNH6s0bPpYVaTfogff53qNXx1aEh_l8WjT3BlbkFJTXftFulvCMKl5TcMqSQ3xoGePzwyP6ZPSYaSNM0jYA`,
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            model: 'dall-e-3',
            prompt,
            size: '1792x1024',
            response_format: 'b64_json',
          }),
        },
      );

      if (!response.ok) {
        throw new Error('Failed to generate image');
      }

      const data = await response.json();
      const b64Json = data.data[0].b64_json;
      // Decode base64 to binary data
      const binaryString = atob(b64Json);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);

      // eslint-disable-next-line fp/no-loops
      for (let i = 0; i < len; i++) {
        // eslint-disable-next-line immutable/no-mutation
        bytes[i] = binaryString.charCodeAt(i);
      }

      // Create a Blob from the binary data
      const blob = new Blob([bytes], { type: 'image/png' });

      const file = new File([blob], 'generated_image.png', {
        type: 'image/png',
      });

      // Insert image in one
      const newImages = await handleDrop({
        files: [file],
        mediaType: MediaType.Image,
        disableImgWidth: true,
        handleFiles: postImages(brandKey),
        pushNotification,
      });

      const lastMediaIndex = medias.length - 1;
      setMedias((prev) => [
        ...prev,
        { ...newImages[0], credit: 'Prisma Media by Dall-E' },
      ]);
      setSelectedMediaIndex(lastMediaIndex + 1);
    } catch (e) {
      pushNotification({
        message: "Erreur lors de la génération de l'image",
        type: NotificationTypeEnum.error,
      });
    } finally {
      setLoadingImageGeneration(false);
      setLoading(false);
      setLoader(undefined);
    }
  };

  return (
    <>
      {brandKey === BrandKey.CAP && articleFormat === ArticleFormat.Rich ? (
        <IconLabelButton
          label="Générer"
          disabled={loadingImageGeneration}
          onClick={async () => {
            if (!articleTitle || !articleLead) {
              return pushNotification({
                message:
                  "Veuillez renseigner le titre et le chapô de l'article",
                type: NotificationTypeEnum.error,
              });
            }
            if (!promptInstructions?.prompt?.body) {
              return pushNotification({
                message:
                  "Aucun prompt n'a été trouvé pour la génération d'image",
                type: NotificationTypeEnum.error,
              });
            }
            if (imageGenerationCount >= 5) {
              return pushNotification({
                message:
                  "Vous avez atteint la limite autorisée de génération d'images",
                type: NotificationTypeEnum.error,
              });
            }
            setImageGenerationCount(imageGenerationCount + 1);
            await generateImage();
          }}
        >
          <AutoFixHighIcon />
        </IconLabelButton>
      ) : null}
      {allowedMedias.includes(MEDIA_TYPES.EMBED) && (
        <IconLabelButton label="Lien" onClick={openEmbedDialog}>
          <Code />
        </IconLabelButton>
      )}

      {allowedMedias.includes(MEDIA_TYPES.THUMBNAIL) && (
        <>
          <IconLabelButton
            label="Thumbnail"
            onClick={() => inputRef.current?.click()}
          >
            <AddAPhoto />
          </IconLabelButton>

          <Box
            component="input"
            type="file"
            accept="image/*"
            ref={inputRef}
            multiple
            sx={{ display: 'none' }}
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              e.target.files && onUploadImages(Array.from(e.target.files))
            }
          />
        </>
      )}

      {!!multimediaPluginMediaTypes.length && (
        <MultimediaPlugin
          addMedia={(mediaOrMedias) => {
            const newMedias = Array.isArray(mediaOrMedias)
              ? mediaOrMedias
              : [mediaOrMedias];
            const lastMediaIndex = medias.length - 1;
            setMedias((prev) => [...prev, ...newMedias]);
            setSelectedMediaIndex(lastMediaIndex + 1);
          }}
          suggestArticlesFn={suggestQuery}
          allowedMedias={multimediaPluginMediaTypes}
          searchFn={(searchParams, opts, pluginType) =>
            searchByPluginType({
              searchParams,
              pluginType,
              opts,
              brandKey,
              searchSchedule: isPage(history.location.pathname),
            })
          }
          advancedSearch={
            brandKey &&
            dataArticles?.article?.format && {
              component: AdvancedSearch,
              props: {
                brandKey,
                articleFormat: dataArticles.article.format,
              },
            }
          }
          itemToIframely={async (item: ItemType<any>, pluginType: string) => {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return (await getItemToIframely(item, pluginType))!;
          }}
          itemToRender={(item: ItemType<any>, pluginType?: string) =>
            getItemToRender(brandKey, item, pluginType)
          }
          itemClickCallback={(item) => {
            if (item.isSuggested) {
              ReactGA.event({
                category: 'Article Edit',
                action: `Suggestion ${item.format}`,
                label: window.location.href,
              });
            }
          }}
        />
      )}
    </>
  );
};
