import type { AutocompleteItem } from '@prismamedia/one-components';
import {
  AppName,
  HomeAppBar,
  HomeAppBarDivider,
} from '@prismamedia/one-components';
import type { FC } from 'react';
import { useCallback } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import { OneDrawerMenu } from '../../../../../components/AppBar';
import { BrandSwitch } from '../../../../../components/BrandSwitch';
import { paths } from '../../../../../routing/Router/paths';
import { auth } from '../../../../../utils/auth';
import {
  useGlobalBrandKey,
  useGlobalFormat,
} from '../../../../../utils/globalState';
import { navigateTo } from '../../../utils';
import { FormatSwitch } from './components/FormatMenu';
import { SearchBarPage } from './components/SearchBar';

export const PageDefaultAppBar: FC = () => {
  const [brandKey, setBrandKey] = useGlobalBrandKey();
  const [format, setFormat] = useGlobalFormat();
  const routeParams = useParams();

  /**
   * We activate the view list of the selected category
   */
  const handleAutocompleteItemSelect = useCallback(
    (item: AutocompleteItem) => {
      const pathname = `${generatePath(
        item?.additionnals?.hasChildren ? paths.PAGE_LIST : paths.PAGE_EDIT,
        {
          brandKey: routeParams.brandKey,
          format: routeParams.format,
        },
      )}${item.additionnals?.path}`;

      navigateTo({ pathname });
    },
    [routeParams],
  );

  return (
    <HomeAppBar
      appDrawerMenu={
        <OneDrawerMenu app={AppName.Page} currentBrand={brandKey} />
      }
      brandMenu={
        <>
          <BrandSwitch brandKey={brandKey} setBrandKey={setBrandKey} />
          <HomeAppBarDivider />
          <FormatSwitch format={format} setFormat={setFormat} />
        </>
      }
      currentUser={auth.user}
      disconnectUser={auth.logout}
      searchBar={
        <SearchBarPage
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          brandKey={brandKey!}
          format={format}
          onAutocompleteItemSelect={handleAutocompleteItemSelect}
        />
      }
    />
  );
};
