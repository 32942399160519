import { AddCircle, Image } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { BrandKey } from '@prismamedia/one-brandkey';
import { FileDropZone, ImageGallery } from '@prismamedia/one-components';
import { sortBy } from 'lodash';
import { move } from 'ramda';
import { Dispatch, FC } from 'react';
import { MediaBlock, SlideshowData } from '../../../types/draft';
import { uploadAndEmbedImage } from '../../../utils/upload';
import { ImageInfos } from '../ImageInfos';
import { SlideshowEditAction, actions } from '../reducer';
import { useStyles } from './styles';
import { mapSlideshowToGalleryItem } from './utils';

export interface ImageItem {
  id: string;
  src: string;
  title?: string;
  slideshow?: SlideshowData;
  credit?: string;
}

interface GridProps {
  brandKey: BrandKey | null;
  slidesIndex: string[];
  selectedSlidesIndex: string[];
  slideMap: Record<string, MediaBlock>;
  dispatch: Dispatch<SlideshowEditAction>;
}

export const Grid: FC<GridProps> = ({
  slideMap,
  slidesIndex,
  selectedSlidesIndex,
  dispatch,
  brandKey,
}) => {
  const classes = useStyles();

  const onItemReorder = (items: { id: string; newIndex: number }[]) => {
    dispatch(
      actions.setSlidesIndex({
        slidesIndex: items.reduce(
          (prevValue, movedItem) =>
            move(
              prevValue.indexOf(movedItem.id),
              movedItem.newIndex,
              prevValue,
            ),
          slidesIndex,
        ),
      }),
    );
  };

  return (
    <FileDropZone
      className={classes.wrapper}
      title={`Déposez des images pour les importer`}
      onDrop={async (files) => {
        if (!brandKey) {
          console.error('No brandKey selected', brandKey);
          return;
        }
        if (files) {
          const sortedFiles = sortBy(files, ['name']);
          // eslint-disable-next-line fp/no-loops
          for (const file of sortedFiles) {
            const iframely = await uploadAndEmbedImage(file, brandKey);

            if (iframely) {
              dispatch(actions.addSlide({ iframely }));
            }
          }
        }
      }}
    >
      {slidesIndex.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
            color: 'GrayText',
          }}
        >
          <Image sx={{ fontSize: '8em' }} />
          <Typography sx={{ display: 'flex', alignItems: 'center' }}>
            Déposez des images ou utilisez sur le bouton{' '}
            <AddCircle sx={{ ml: 0.5 }} />
          </Typography>
        </Box>
      ) : (
        <ImageGallery
          count={slidesIndex.length}
          list={slidesIndex.map((key) =>
            mapSlideshowToGalleryItem(key, slideMap[key]),
          )}
          itemsPerRow={{
            default: 6,
            min: 3,
            max: 10,
          }}
          infoRenderer={(item: ImageItem, index) => (
            <ImageInfos item={item} index={index} />
          )}
          selectedItems={selectedSlidesIndex}
          setSelectedItems={(selectedItems) => {
            const selection =
              typeof selectedItems === 'function'
                ? selectedItems(selectedSlidesIndex)
                : selectedItems;
            dispatch(
              actions.setSelectedSlides({
                slideKeys: selection,
              }),
            );
          }}
          onItemReorder={onItemReorder}
        />
      )}
    </FileDropZone>
  );
};
