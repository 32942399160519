import { BrandKey } from '@prismamedia/one-brandkey';
import { GetAffilizzDesigns_searchAffilliationDesign } from '../../../../__generated__/queries-web';

export const loadScript = (src: string, success?: any, error?: any) => {
  const script = document.createElement('script');
  // eslint-disable-next-line immutable/no-mutation
  script.src = src;

  // eslint-disable-next-line immutable/no-mutation
  script.onload = success;
  // eslint-disable-next-line immutable/no-mutation
  script.onerror = error;

  document.body.appendChild(script);
};

export const getModelId = (
  brandKey: BrandKey,
  models: GetAffilizzDesigns_searchAffilliationDesign[],
) => {
  const result = models.find(
    (m) => m.name.toUpperCase() === brandKey.toUpperCase(),
  );
  if (result) {
    return result?.id;
  }

  return config.AFFILIZZ_MODEL_IDS['DEFAULT'];
};
