/* eslint-disable  */
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Search as SearchIcon, Storefront } from '@mui/icons-material';
import {
  PluginButtonProps,
  useNotification,
  IconLabelButton,
  InfiniteList,
  useDebounce,
  NotificationTypeEnum,
} from '@prismamedia/one-components';
import {
  Box,
  Button,
  // Checkbox,
  // FormControlLabel,
  // FormGroup,
} from '@mui/material';
import { insertDataBlock } from 'megadraft';

import { AffilizPluginProps } from '.';
import { DialogStepper } from './DialogStepper';
import { errorMessage, steps, stepText } from './config';
import { AffilizProductCard, AffilizzSearchHeader } from './AffilizzSearch';
import {
  AffilizzCardInfos,
  ProductComplementaryInfos,
  ProductInfos,
} from './types';
import {
  useGetAffilizzDesigns,
  useGetAffilizzProducts,
} from '../../../../apollo/queries/affilizz.web.graphql';
import {
  useCreateAffiliationCard,
  useCreateAffiliationCatalogProduct,
} from '../../../../apollo/mutations/affilizz.web.graphql';
import { grey } from '@mui/material/colors';
import { AffilizzDetails, AffilizzDetailsProps } from './AffilizzDetails';
import { AffilizzPreview } from './AffilizzPreview';
import { getModelId, loadScript } from './utils';
import {
  AffilizzProductSearchSor,
  AffilizzProductType,
  GetSearchAffiliationProduct_searchAffiliationProduct_content,
  GetSearchAffiliationProductVariables,
} from '../../../../__generated__/queries-web';

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'affilizz-rendering-component': AffilizRenderingComponentAttributes;
      'affilizz-catalog-component': AffilizCatalogComponentAttributes;
    }
    interface AffilizRenderingComponentAttributes {
      'publication-content-id': string;
      loading?: 'lazy';
    }
    interface AffilizCatalogComponentAttributes {
      type: 'CARD';
      locale: 'fr_FR';
      'media-id': string;
      'product-localized-id': string;
      loading?: 'lazy';
    }
  }
}

type PriceRange = {
  min: number;
  max: number | null;
  value: string;
};

const defaultProductDetails: ProductComplementaryInfos = {
  title: '',
  positivePoints: [''],
  negativePoints: [''],
  rating: '',
  catalog: false,
};

const defaultPriceRange: PriceRange = {
  min: 0,
  max: null,
  value: '',
};

export const AffilizMediaButton = ({
  brandKey,
  dispach,
  format,
}: AffilizPluginProps): FC<PluginButtonProps> => ({
  editorState,
  onChange,
}) => {
  const { pushNotification } = useNotification();
  const [currentStep, setStep] = useState(0);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [sortType, setSort] = useState<AffilizzProductSearchSor>(
    AffilizzProductSearchSor.NB_OFFERS,
  );
  const [priceRange, setPriceRange] = useState<PriceRange>(defaultPriceRange);

  const [selectedProduct, setProduct] = useState<ProductInfos | null>(null);
  const [productDetails, setProductDetails] = useState<
    ProductComplementaryInfos
  >(defaultProductDetails);
  const [
    affiliationCardInfos,
    setAffiliationCardInfos,
  ] = useState<AffilizzCardInfos | null>(null);

  const debouncedSearch = useDebounce(search, 500);

  const queryVariables: GetSearchAffiliationProductVariables = {
    keyword: debouncedSearch,
    minPrice: priceRange.min,
    ...(priceRange.max ? { maxPrice: priceRange.max } : {}),
    page: 0,
    size: 16,
    sort: !sortType ? AffilizzProductSearchSor.NB_OFFERS : sortType,
  };

  const { data, fetchMore, loading, error } = useGetAffilizzProducts(
    queryVariables,
    debouncedSearch.length < 3,
  );

  const {
    data: models,
    loading: isLoadingDesigns,
    error: designErrors,
  } = useGetAffilizzDesigns();

  const modelId = useMemo(() => {
    return getModelId(
      brandKey,
      (models && models.searchAffilliationDesign) || [],
    );
  }, [models]);

  const [createAffiliationCard] = useCreateAffiliationCard();
  const [
    createAffiliationCatalogProduct,
  ] = useCreateAffiliationCatalogProduct();

  useEffect(() => {
    if (loading || debouncedSearch.length < 3) {
      setProduct(null);
    }
  }, [loading, debouncedSearch]);

  const resetAll = () => {
    setSearch('');
    setStep(0);
    setProduct(null);
    setProductDetails({
      title: '',
      negativePoints: [''],
      positivePoints: [''],
      rating: '',
      catalog: false,
    });
    setPriceRange(defaultPriceRange);
    setAffiliationCardInfos(null);
  };

  const handleClose = () => {
    setIsOpen(false);
    resetAll();
  };

  const handleProductSearch = (searchQuery: string) => {
    setSearch(searchQuery);
  };

  const handlePriceChange = (
    min: number,
    max: number | null,
    value: string,
  ) => {
    setPriceRange({ min, max, value });
  };

  const handleProductSelect = (product: ProductInfos) => {
    setProduct(product);
  };

  const handleSort = (value: string) => {
    setSort(
      AffilizzProductSearchSor[value as keyof typeof AffilizzProductSearchSor],
    );
  };

  const handleDetailsChange: AffilizzDetailsProps['onChange'] = (details) => {
    setProductDetails(details);
  };

  const transformProductData = (
    data: GetSearchAffiliationProduct_searchAffiliationProduct_content,
  ): ProductInfos => {
    const { id, type, productLocalizedId: localizedProductId, ...rest } = data;

    return {
      meta: {
        id,
        localizedProductId,
        type,
      },
      ...rest,
    };
  };

  const handleFetchMore = () => {
    const pageNumber = data?.searchAffiliationProduct?.pageable?.pageNumber;

    if (pageNumber === null || pageNumber === undefined) {
      return Promise.resolve(true);
    }

    if (pageNumber === data?.searchAffiliationProduct.totalPages) {
      return Promise.resolve(true);
    }

    return fetchMore({
      variables: {
        page:
          (data?.searchAffiliationProduct?.pageable?.pageNumber as number) + 1,
      },
    });
  };

  const isValidProduct = (product: ProductInfos) => {
    return (
      product?.meta.localizedProductId &&
      product?.name &&
      product.name.trim() !== ''
    );
  };

  const getNoneEmptyPoints = (points?: string[]) => {
    return (points || []).filter((p) => !!p.trim());
  };

  const getFinalComments = (details: ProductComplementaryInfos) => {
    const { negativePoints, positivePoints } = details;

    const comments: Partial<ProductComplementaryInfos> = {};

    const finalNegativePoints = getNoneEmptyPoints(negativePoints);
    const finalPositivePoints = getNoneEmptyPoints(positivePoints);

    if (finalNegativePoints) {
      comments.negativePoints = finalNegativePoints;
    }

    if (finalPositivePoints) {
      comments.positivePoints = finalPositivePoints;
    }

    return comments;
  };

  const handleSubmitCard = () => {
    setStep(2);

    loadScript(config.AFFILIZZ_SCRIPT_URL, null, () => {
      pushNotification({
        type: NotificationTypeEnum.error,
        message: errorMessage.ERROR_LOAD_SCRIPT,
      });
    });

    if (
      !selectedProduct ||
      (selectedProduct && !isValidProduct(selectedProduct))
    ) {
      pushNotification({
        type: NotificationTypeEnum.error,
        message: errorMessage.ERROR_DISPLAY_PREVIEW,
      });
      return;
    }

    if (selectedProduct.meta.type === AffilizzProductType.CatalogProduct) {
      setAffiliationCardInfos({
        publicationId: '',
        contentId: selectedProduct.meta.localizedProductId,
      });

      return;
    }

    const {
      meta: { localizedProductId },
      name,
      title,
      rating,
    } = { ...selectedProduct, ...productDetails };

    const comments = getFinalComments(productDetails);

    if (productDetails.catalog) {
      createAffiliationCatalogProduct({
        variables: {
          localizedProductId,
          name: title,
        },
      }).catch((error) => {
        pushNotification({
          type: NotificationTypeEnum.error,
          message: errorMessage.ERROR_ADD_CATALOG_PRODUCT,
        });
      });
    }

    createAffiliationCard({
      variables: {
        localizedProductId,
        title: title.trim() || name,
        tag: !!rating ? `${rating} / 10` : undefined,
        ...comments,
      },
    })
      .then((result) => {
        const data = result?.data?.createAffiliationCard;
        const contents = data?.contents;

        if (contents && contents instanceof Array && contents.length > 0) {
          setAffiliationCardInfos({
            publicationId: data?.id,
            contentId: contents[0].id,
          });
        }
      })
      .catch((error) => {
        pushNotification({
          type: NotificationTypeEnum.error,
          message: errorMessage.ERROR_CREATE_PREVIEW,
        });
      });
  };

  const getRenderHtmlByProductType = (type: AffilizzProductType) => {
    const script = `<script async type="text\/javascript" src="https:\/\/app.affilizz.com\/affilizz.js"><\/script>`;

    if (type === AffilizzProductType.CatalogProduct) {
      return `
          <affilizz-catalog-component
            media-id="${config.AFFILIZZ_MEDIA_ID}"
            type="CARD"
            locale="fr_FR"
            design-id="${modelId}"
            product-localized-id="${affiliationCardInfos?.contentId}"
            loading="lazy">
          </affilizz-catalog-component>
          ${script}
        `;
    }

    return `
        <affilizz-rendering-component
          design-id="${modelId}"
          publication-content-id="${affiliationCardInfos?.contentId}"
          loading="lazy">
        </affilizz-rendering-component>
        ${script}
      `;
  };

  const handleAddCard = () => {
    const changes = insertDataBlock(editorState, {
      type: 'affilizz',
      html: getRenderHtmlByProductType(
        (selectedProduct as ProductInfos).meta.type,
      ),
      title: productDetails.title.trim() || selectedProduct?.name,
      publicationInfos: affiliationCardInfos?.publicationId,
      productName: selectedProduct?.name,
      description: '',
      positivePoints: productDetails.positivePoints,
      negativePoints: productDetails.negativePoints,
    });
    onChange(changes);
    handleClose();
  };

  // const handleAddToCatalogChange = () => {
  //   setProductDetails((prevState) => ({
  //     ...prevState,
  //     catalog: !prevState.catalog,
  //   }));
  // };

  const getFooter = (step: number, isCompleted: boolean) => {
    if (!search) {
      return null;
    }

    return (
      <Box sx={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
        {step === 0 && (
          <Button
            onClick={() => setStep((prevStep) => prevStep + 1)}
            sx={{ m: 'auto' }}
            disabled={!isCompleted}
            variant="contained"
          >
            Continuer
          </Button>
        )}
        {step === 1 && (
          <>
            {/* {selectedProduct?.meta.type === AffilizzProductType.Product && (
              <FormControlLabel
                sx={{ width: '100%', mb: 2 }}
                control={
                  <Checkbox
                    checked={productDetails.catalog}
                    onChange={handleAddToCatalogChange}
                  />
                }
                label={stepText.details.ADD_TO_CATALOG_TEXT}
              />
            )} */}
            <Button
              onClick={handleSubmitCard}
              sx={{ m: 'auto' }}
              disabled={!isCompleted}
              variant="contained"
            >
              Continuer
            </Button>
          </>
        )}
        {step === 2 && (
          <Button
            onClick={handleAddCard}
            sx={{ m: 'auto' }}
            disabled={!isCompleted}
            variant="contained"
          >
            Ajouter
          </Button>
        )}
      </Box>
    );
  };

  const isStepValid = (step: number) => {
    switch (step) {
      case 0:
        return !!selectedProduct;
      case 1:
        return !!productDetails;
      default:
        return !!affiliationCardInfos;
    }
  };

  const dialogHeader = () => {
    switch (currentStep) {
      case 0:
        return (
          <AffilizzSearchHeader
            total={data?.searchAffiliationProduct?.totalElements || 0}
            sortType={sortType?.toString() || ''}
            keyword={search}
            priceRange={priceRange.value}
            onSort={handleSort}
            onPriceChange={handlePriceChange}
            onProductSearch={handleProductSearch}
          />
        );
      case 1:
        return (
          <Box sx={{ mt: 4 }}>
            <AffilizProductCard
              product={selectedProduct as ProductInfos}
              onSelect={() => {}}
              isSelected={false}
            />
          </Box>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <DialogStepper
        open={isOpen}
        onClose={handleClose}
        currentStep={currentStep}
        steps={steps}
        header={dialogHeader()}
        footer={getFooter(currentStep, isStepValid(currentStep))}
      >
        {currentStep === 0 &&
          (!data ? (
            <SearchIcon
              sx={{
                color: grey[300],
                margin: 'auto',
                width: 192,
                height: 192,
              }}
            />
          ) : (
            <Box sx={{ width: '100%' }}>
              <InfiniteList
                fetchMore={handleFetchMore}
                error={error}
                list={data?.searchAffiliationProduct?.content}
                loading={loading}
                rowHeight={96}
                rowRenderer={(data: any) => (
                  <AffilizProductCard
                    sx={{ mr: 1 }}
                    isSelected={data.id === selectedProduct?.meta.id}
                    product={transformProductData(data)}
                    onSelect={handleProductSelect}
                  />
                )}
              />
            </Box>
          ))}
        {currentStep === 1 && (
          <Box sx={{ overflow: 'hidden auto', width: '100%' }}>
            <AffilizzDetails
              onChange={handleDetailsChange}
              details={{
                ...productDetails,
                negativePoints:
                  selectedProduct?.downsides || productDetails.negativePoints,
                positivePoints:
                  selectedProduct?.upsides || productDetails.positivePoints,
              }}
              product={selectedProduct}
            />
          </Box>
        )}
        {currentStep === 2 && (
          <Box sx={{ width: '100%' }}>
            <AffilizzPreview
              modelId={modelId}
              type={(selectedProduct as ProductInfos).meta.type}
              contentId={affiliationCardInfos?.contentId || ''}
            />
          </Box>
        )}
      </DialogStepper>

      <IconLabelButton
        data-testid="megadraft-media-button-plugin"
        label={'Affilizz'}
        onClick={() => setIsOpen(true)}
      >
        <Storefront />
      </IconLabelButton>
    </>
  );
};
