import { Add, Info } from '@mui/icons-material';
import { Divider, TextField, Typography } from '@mui/material';
import { brandTitleByKey } from '@prismamedia/one-brandkey';
import {
  EditAppBar,
  FieldValidator,
  NotificationTypeEnum,
  PageWithDrawer,
  getRequiredErrorMessage,
  useAdvice,
  useNotification,
} from '@prismamedia/one-components';
import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  ArticleFormat,
  ArticleStatus,
  BrandKey,
  GetArticle_article,
} from '../../../__generated__/queries-web';
import { CharCounter } from '../../../components/CharCounter';
import { DatePicker } from '../../../components/DatePicker';
import { auth } from '../../../utils/auth';
import { brandsOptions } from '../../../utils/brands';
import { formatDate } from '../../../utils/dateUtils';
import { getImageFromArticleBody } from '../../../utils/getImageFromArticleBody';
import { getFirstImage } from '../../../utils/media';
import { resizer } from '../../../utils/resizer';
import { MobilePushCard } from '../MobilePushCard';
import { PushHistoryWrapper } from '../PushHistory';
import { SegmentWrapper } from '../Segment';
import { DecoratedSegment } from '../Segment/segmentList';
import {
  UPLOAD_ERROR_MESSAGE,
  connectPushToSegment,
  segmentsForPush,
  upload,
  useCreatePushMutation,
} from '../utils';
import { ArticleSearchDialog } from './ArticleSearch';
import { MobilePushConfiguration } from './MobilePushConfiguration';
import { MobilePushMessagePopper, MobilePushTitlePopper } from './poppers';
import { useStyles } from './styles';
import { MobilePushFormState, Platform, SmartSegment } from './types';
import { validateDate, validateInputValue } from './validator';

interface MobilePushFormProps {
  article?: GetArticle_article | null;
  loading?: boolean;
  error?: Error;
}

const TITLE_MAXLENGTH = 50;
const MESSAGE_MAXLENGTH = 150;

const saveMandatoryFields: FieldValidator<MobilePushFormState>[] = [
  {
    label: 'Titre',
    validate: ({ title }) => !!title,
  },
  {
    label: 'Message',
    validate: ({ message }) => !!message,
  },
  {
    label: 'Smart Segment',
    validate: ({ smartSegmentsChecked }) => !!smartSegmentsChecked,
  },
];

export const MobilePushForm: FC<MobilePushFormProps> = ({
  article,
  loading,
  error,
}) => {
  const { brandKey } = useParams<{ brandKey: BrandKey }>();
  const history = useHistory();
  const classes = useStyles();
  const { pushNotification } = useNotification();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const isAndroidAllowed = !!brandsOptions[brandKey].pushPlatforms?.includes(
    'android',
  );
  const isIosAllowed = !!brandsOptions[brandKey].pushPlatforms?.includes('ios');
  const isEditorialAlertAllowed = !!brandsOptions[brandKey]
    .editorialAlertAllowed;
  const isSetInitialTitleAllowed =
    brandsOptions[brandKey].setInitialTitleAllowed;
  const setInitialMessage = useCallback(() => {
    if (isSetInitialTitleAllowed && article) {
      return !article.partnerTitle ? article.title : '';
    }
    return article ? article.title : '';
  }, [article, isSetInitialTitleAllowed]);
  const initialArticle = useMemo<MobilePushFormState>(
    () => ({
      title:
        isSetInitialTitleAllowed && article?.partnerTitle
          ? article.partnerTitle
          : '',
      message: setInitialMessage(),
      android: isAndroidAllowed,
      ios: isIosAllowed,
      editorialAlert: isEditorialAlertAllowed,
      datePicker: null,
      smartSegmentsChecked: false,
      smartSegmentNew: true,
      smartSegmentOneTime: true,
      smartSegmentEngaged: true,
      smartSegmentDormant: true,
    }),
    [
      article,
      isAndroidAllowed,
      isEditorialAlertAllowed,
      isIosAllowed,
      setInitialMessage,
      isSetInitialTitleAllowed,
    ],
  );
  const [values, setValues] = useState<MobilePushFormState>(initialArticle);
  const [selectedImage, setSelectedImage] = useState<string | undefined>();
  const [images, setImages] = useState<string[]>([]);
  const [segments, setSegments] = useState<DecoratedSegment[]>();
  const createPushMutation = useCreatePushMutation();
  const { openAdvice } = useAdvice();
  const titleFieldRef = useRef(null);
  const messageFieldRef = useRef(null);
  const [saveLoading, setSaveLoading] = useState(false);

  const requiredFields = getRequiredErrorMessage(values, saveMandatoryFields);

  const handleChange = (name: string) => (
    event: ChangeEvent<HTMLInputElement>,
  ): void => {
    const { value, checked } = event.target;
    switch (name) {
      case 'title':
        setValues({
          ...values,
          [name]: validateInputValue(value, TITLE_MAXLENGTH),
        });
        break;
      case 'message':
        setValues({
          ...values,
          [name]: validateInputValue(value, MESSAGE_MAXLENGTH),
        });
        break;
      case 'datePicker':
        validateDate(value, article && article.publishedAt, pushNotification)
          ? setValues({ ...values, datePicker: value })
          : setValues({ ...values, datePicker: null });
        break;
      case 'android':
      case 'ios':
      case 'editorialAlert':
        setValues({ ...values, [name]: checked });
        break;
      case 'smartSegmentNew':
      case 'smartSegmentOneTime':
      case 'smartSegmentEngaged':
      case 'smartSegmentDormant':
        setValues({ ...values, [name]: checked });
        break;
      default:
        setValues({ ...values, [name]: value });
        break;
    }
  };

  const addImage = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const uploadImages = async (event: ChangeEvent<HTMLInputElement>) => {
    const uploads = await upload(event, brandKey);
    if (uploads?.[0] === UPLOAD_ERROR_MESSAGE) {
      pushNotification({
        type: NotificationTypeEnum.error,
        message: uploads[0],
      });
    } else if (uploads) {
      setImages([...images, ...uploads]);
    }
  };

  const validateSegmentQuery = useCallback(
    (targeting: string) => {
      let isValid = true;
      try {
        JSON.parse(targeting);
      } catch (err) {
        isValid = false;
        pushNotification({
          type: NotificationTypeEnum.error,
          message:
            "Un des segments contient une erreur de syntaxe et n'a pas pu être enregistré.",
        });
      }
      return isValid ? targeting : null;
    },
    [pushNotification],
  );

  useEffect(() => {
    if (!article) {
      setValues(initialArticle);
      setImages([]);
      setSelectedImage(undefined);
      return;
    }
    let image;
    const { format, body, medias, title } = article;
    switch (format) {
      case ArticleFormat.Slideshow: {
        image = getImageFromArticleBody(body, false);
        break;
      }
      case ArticleFormat.Rich: {
        try {
          const parsedMedias = medias && JSON.parse(medias);
          const media = getFirstImage(parsedMedias);
          image = media && media?.iframely?.meta?.url;
        } catch (e) {
          console.error(e);
        }

        break;
      }
    }
    setValues((currValues) => ({ ...currValues, message: title }));
    if (image) {
      setSelectedImage(image);
      setImages([image]);
    }
  }, [article, initialArticle]);

  const createPushHandler = async () => {
    if (!article || !article.id) {
      pushNotification({
        type: NotificationTypeEnum.error,
        message: 'Veuillez reprendre in article',
      });
      return;
    }

    const payload = {
      data: {
        article: {
          connect: {
            id: article.id,
          },
        },
        title: values.title || '',
        body: values.message || '',
        media: selectedImage || '',
        scheduledAt: values.datePicker
          ? new Date(values.datePicker).toISOString()
          : new Date().toISOString(),
        userId: auth.user?.id || '',
        pushSegments: segments && {
          create: connectPushToSegment(segments),
        },
      },
    };

    if (!isSmartSegmentsChecked()) {
      pushNotification({
        type: NotificationTypeEnum.error,
        message: "Le choix d'un Smart Segment est obligatoire.",
      });
      return;
    }
    if (!values.title) {
      pushNotification({
        type: NotificationTypeEnum.error,
        message: 'Le champ titre est obligatoire',
      });
      return;
    }
    if (!values.message) {
      pushNotification({
        type: NotificationTypeEnum.error,
        message: 'Le champ message est obligatoire',
      });
      return;
    }

    setSaveLoading(true);

    try {
      if (values.android) {
        await createPushMutation({
          variables: {
            data: {
              ...payload.data,
              targetDevice: 'android',
              targeting: buildTargeting(Platform.ANDROID),
            },
          },
        });
      }
      if (values.ios) {
        await createPushMutation({
          variables: {
            data: {
              ...payload.data,
              targetDevice: 'ios',
              targeting: buildTargeting(Platform.IOS),
            },
          },
        });
      }
      if (values.editorialAlert) {
        await createPushMutation({
          variables: {
            data: {
              ...payload.data,
              targetDevice: 'editorialAlert',
              targeting: buildTargeting(Platform.EDITORIAL_ALERT),
            },
          },
        });
      }

      setValues(initialArticle);

      pushNotification({
        type: NotificationTypeEnum.success,
        message: 'Push enregistré',
      });
    } catch (e) {
      pushNotification({
        type: NotificationTypeEnum.error,
        message: (e as Error).message,
      });
    }

    setSaveLoading(false);
  };

  const buildTargeting = (platform: Platform) => {
    let targetingQuery;
    let targetingSegments;
    switch (platform) {
      case Platform.IOS:
        targetingQuery = segments ? segmentsForPush(segments)?.ios : null;
        targetingSegments = getSmartSegments();

        break;
      case Platform.ANDROID:
        targetingQuery = segments ? segmentsForPush(segments)?.android : null;
        targetingSegments = getSmartSegments();

        break;
      case Platform.EDITORIAL_ALERT:
        targetingQuery = segments
          ? segmentsForPush(segments)?.editorialAlert
          : null;
        targetingSegments = getSmartSegments();

        break;
      default:
        targetingQuery = null;
        targetingSegments = getSmartSegments();
    }

    const targeting = {
      query: targetingQuery?.query || null,
      segments: targetingSegments,
    };

    return validateSegmentQuery(JSON.stringify(targeting));
  };

  const isSmartSegmentsChecked = useCallback(() => {
    if (
      values.smartSegmentNew === false &&
      values.smartSegmentOneTime === false &&
      values.smartSegmentDormant === false &&
      values.smartSegmentEngaged === false
    ) {
      return false;
    }

    return true;
  }, [
    values.smartSegmentDormant,
    values.smartSegmentEngaged,
    values.smartSegmentNew,
    values.smartSegmentOneTime,
  ]);

  const getSmartSegments = () => {
    const smartSegments = [
      values.smartSegmentNew && SmartSegment.NEW,
      values.smartSegmentOneTime && SmartSegment.ONE_TIME,
      values.smartSegmentDormant && SmartSegment.DORMANT,
      values.smartSegmentEngaged && SmartSegment.ENGAGED,
    ].filter(Boolean);

    return smartSegments;
  };

  const handleInfoClick = useCallback(
    (anchorRef, content) => () => {
      openAdvice({
        anchorRef,
        content,
        PopperProps: {
          keepMounted: false,
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                boundariesElement: 'window',
                enabled: false,
                escapeWithReference: true,
              },
            },
          ],
          style: { width: '310px' },
        },
        zIndex: 1,
      });
    },
    [openAdvice],
  );

  React.useEffect(() => {
    values.smartSegmentsChecked !== isSmartSegmentsChecked() &&
      setValues({
        ...values,
        smartSegmentsChecked: isSmartSegmentsChecked(),
      });
  }, [values, isSmartSegmentsChecked]);

  const Drawer = () => (
    <>
      <Typography variant="subtitle2" className={classes.brandKey}>
        {brandTitleByKey[brandKey]}
      </Typography>
      <DatePicker
        label={"Date & heure d'envoi"}
        onChange={handleChange('datePicker')}
        name="datePicker"
        value={values.datePicker || ''}
      />
      <Typography variant="subtitle2" className={classes.info}>
        {article &&
          `Article ${
            article && article.status === ArticleStatus.Published
              ? 'publié'
              : 'programmé pour '
          } le ${
            article &&
            article.publishedAt &&
            formatDate(article.publishedAt, 'dd/MM/yyyy HH:mm')
          }`}
      </Typography>
      {(isAndroidAllowed || isIosAllowed || isEditorialAlertAllowed) &&
        article?.brandKey && (
          <SegmentWrapper
            onChange={setSegments}
            brandKey={article ? article.brandKey : brandKey}
            segments={segments}
          />
        )}
    </>
  );

  const disabled =
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    values.title!.length > TITLE_MAXLENGTH ||
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    values.message!.length > MESSAGE_MAXLENGTH ||
    values.smartSegmentsChecked === false;

  return (
    <>
      <EditAppBar
        onNavigateBack={history.goBack}
        title="Push"
        save={{
          actionLabel: 'Envoyer',
          disabled,
          onClick: createPushHandler,
          requiredFields,
          loading: saveLoading,
        }}
      />

      <PageWithDrawer loading={loading} error={error} rightDrawer={<Drawer />}>
        <ArticleSearchDialog brandKey={brandKey} />
        <div className={classes.formGroup}>
          <Info
            onClick={handleInfoClick(titleFieldRef, MobilePushTitlePopper)}
            ref={titleFieldRef}
          />
          <TextField
            fullWidth
            className={classes.separator}
            label="Titre"
            onChange={handleChange('title')}
            required
            value={values.title}
            variant="standard"
          />
        </div>
        <div className={classes.charCounter}>
          <CharCounter value={values.title} maxLength={TITLE_MAXLENGTH} />
        </div>
        <div className={classes.formGroup}>
          <Info
            onClick={handleInfoClick(messageFieldRef, MobilePushMessagePopper)}
            ref={messageFieldRef}
          />
          <TextField
            fullWidth
            className={classes.separator}
            id="standard-name"
            label="Message"
            onChange={handleChange('message')}
            required
            value={values.message}
            variant="standard"
          />
        </div>
        <div className={classes.charCounter}>
          <CharCounter value={values.message} maxLength={MESSAGE_MAXLENGTH} />
        </div>
        <Divider className={classes.separator} />
        <div className={classes.imageContainer}>
          {Array.isArray(images) &&
            images.map((image: string, idx: number) => (
              <img
                className={
                  image === selectedImage
                    ? [classes.image, classes.selectedImage].join(' ')
                    : classes.image
                }
                src={resizer.fit({
                  origin: image,
                  width: 150,
                  height: 150,
                })}
                key={idx}
                alt="alternative"
                onClick={() => setSelectedImage(image)}
              />
            ))}
        </div>
        <div className={classes.uploader} onClick={addImage}>
          <input
            type="file"
            ref={inputRef}
            multiple
            className={classes.inputElement}
            onChange={uploadImages}
          />
          <Add />
          <Typography variant="body2" component="h5">
            Ajouter une image
          </Typography>
        </div>
        <Divider />
        {selectedImage && values.message && (
          <MobilePushCard
            image={selectedImage}
            message={values.message}
            title={values.title}
          />
        )}
        <Divider />
        <MobilePushConfiguration
          isAndroiddAllowed={isAndroidAllowed}
          isIosAllowed={isIosAllowed}
          isEditorialAlertAllowed={isEditorialAlertAllowed}
          pushFormState={values}
          changeHandler={handleChange}
        />
        <Divider />
        {article && article.id && <PushHistoryWrapper articleId={article.id} />}
      </PageWithDrawer>
    </>
  );
};
