import { ImageSearchOutlined } from '@mui/icons-material';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { ImageLoader } from '@prismamedia/one-components';
import { generatePath, useHistory } from 'react-router-dom';
import { getCards_cards } from '../../../../__generated__/queries-web';
import { paths } from '../../../../routing/Router/paths';
import { getImageFromMedia } from '../../../../utils/media';
import { useStyles } from './styles';

interface MediaCardProps {
  card: getCards_cards;
}

export const MediaCard = ({ card }: MediaCardProps) => {
  const { mediaForDisplay } = getImageFromMedia(card.media);
  const history = useHistory();
  const classes = useStyles();

  const redirectToEditPage = () => {
    const { id, brandKey } = card;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return history.push(generatePath(paths.CARD_EDIT, { id: id!, brandKey }));
  };

  return (
    <Card className={classes.root} onClick={redirectToEditPage}>
      <CardActionArea>
        <ImageLoader
          className={classes.cardMedia}
          fitting="cover"
          image={<ImageSearchOutlined className={classes.imageFallback} />}
          loaderSize={35}
          src={mediaForDisplay}
          timeout={3000}
        />

        <CardContent className={classes.cardContent}>
          <Typography variant="h5" component="h2" noWrap>
            {card.title}
          </Typography>

          <Typography
            variant="body2"
            color="textSecondary"
            component="p"
            noWrap
          >
            {card.label}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
