import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { grey } from '@mui/material/colors';
import {
  IconLabelButton,
  MEDIA_TYPES_LABEL,
  NotificationTypeEnum,
  useDialog,
  useNotification,
} from '@prismamedia/one-components';
import { sortBy } from 'lodash';
import { ChangeEvent, Dispatch, FC, useRef, useState } from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { ArticleStatus, BrandKey } from '../../../__generated__/queries-web';
import { InternalLink } from '../../../components/InternalLinkModal/InternalLink';
import { linkTypes } from '../../../components/InternalLinkModal/LinkTypes';
import { InternalLinkMultimedia } from '../../../components/InternalLinkMultimedia';
import { paths } from '../../../routing/Router/paths';
import { uploadAndEmbedImage } from '../../../utils/upload';
import { SlideshowEditAction, actions } from '../reducer';
import { useStyles } from './styles';
import { buildDescription, getUploadActions } from './utils';
interface UploadButtonProps {
  brandKey: BrandKey | null;
  dispatch: Dispatch<SlideshowEditAction>;
  captions?: string[];
  articleStatus: ArticleStatus | null;
}

export const UploadButton: FC<UploadButtonProps> = ({
  brandKey,
  dispatch,
  captions,
  articleStatus,
}) => {
  const classes = useStyles();
  const dialog = useDialog();
  const [open, setOpen] = useState<boolean>(false);
  const [internalLinkModalIsOpen, setInternalLinkModalIsOpen] = useState<
    boolean
  >(false);
  const [multimediaInitialType, setMultimediaInitialType] = useState<
    MEDIA_TYPES_LABEL
  >(MEDIA_TYPES_LABEL.ARTICLES);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const history = useHistory();
  const params = useParams();
  const { pushNotification } = useNotification();

  const upload = async (event: ChangeEvent<HTMLInputElement>) => {
    if (!brandKey) {
      console.error('erreur no brandKey', brandKey);
      return;
    }
    const { files } = event.target;
    if (files) {
      const sortedFiles = sortBy(files, ['name']);
      // eslint-disable-next-line fp/no-loops
      for (const file of sortedFiles) {
        const iframely = await uploadAndEmbedImage(file, brandKey);

        if (iframely) {
          dispatch(actions.addSlide({ iframely }));
        }
      }
    }
  };

  const onLinkSelected = (link: InternalLink) => {
    setInternalLinkModalIsOpen(false);
    const linkType = linkTypes.find((linkT) => linkT.type === link.type);
    if (
      link.imageSrc &&
      link.imageSrc.media?.iframely?.meta &&
      linkType &&
      !captions?.includes(link.title)
    ) {
      dispatch(
        actions.addSlide({
          iframely: {
            ...link.imageSrc.media.iframely,
            meta: {
              ...link.imageSrc.media.iframely.meta,
              source:
                link.imageSrc.media.iframely.meta?.source ??
                link?.imageSrc?.media?.source,
              credit:
                link.imageSrc.media.iframely.meta?.credit ??
                link?.imageSrc?.media?.credit,
            },
          },
          caption: link.title,
          slideshow: {
            description: buildDescription(link, linkType),
            hasButton: !!link.url,
            buttonTitle: link.url ? linkType.buttonLabel : '',
            buttonUrl: link.url,
          },
        }),
      );
    }
  };

  return (
    <>
      <input
        type="file"
        required
        ref={inputRef}
        multiple
        className={classes.inputElement}
        onChange={upload}
      />
      <InternalLinkMultimedia
        brandKey={brandKey}
        setIsOpen={setInternalLinkModalIsOpen}
        open={internalLinkModalIsOpen}
        onLinkSelected={(link) => {
          onLinkSelected(link);
        }}
        multimediaInitialType={multimediaInitialType}
      />
      <SpeedDial
        ariaLabel="upload speedial"
        className={classes.button}
        icon={<SpeedDialIcon />}
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        open={open}
      >
        {getUploadActions(brandKey).map((action) => (
          <SpeedDialAction
            key={action.name}
            sx={{ height: 48, width: 48 }}
            icon={
              <IconLabelButton label={action.name}>
                {action.icon}
              </IconLabelButton>
            }
            tooltipTitle={action.name}
            onClick={() => {
              action.onClick({
                dispatch,
                inputRef,
                setInternalLinkModalIsOpen,
                setMultimediaInitialType,
                dialog,
              });
            }}
          />
        ))}
        {brandKey && [BrandKey.CAC, BrandKey.FAC].includes(brandKey) && (
          <SpeedDialAction
            key={'IA'}
            sx={{
              height: 48,
              width: 48,
              ...(articleStatus === ArticleStatus.Draft
                ? {}
                : {
                    backgroundColor: grey[400],
                  }),
            }}
            icon={
              <IconLabelButton label={'IA'}>
                {<AddPhotoAlternateIcon />}
              </IconLabelButton>
            }
            tooltipTitle={`Ajouter des recettes avec I.A. ${
              articleStatus !== ArticleStatus.Draft
                ? '(Disponible uniquement pour les diaporamas en brouillon)'
                : ''
            }`}
            onClick={() => {
              articleStatus === ArticleStatus.Draft
                ? brandKey &&
                  history.push(
                    generatePath(paths.SLIDESHOW_IA, {
                      activeTab: 'complete',
                      brandKey,
                      slideshowId: params.id,
                    }),
                  )
                : pushNotification({
                    message:
                      'Cette fonctionnalité est disponible uniquement pour les diaporamas en brouillon',
                    type: NotificationTypeEnum.error,
                  });
            }}
          />
        )}
      </SpeedDial>
    </>
  );
};
