import { InputLabel } from '@mui/material';
import React from 'react';
import {
  BrandKey,
  GetArticle_article_articleAuthors,
} from '../../../../__generated__/queries-web';
import { useAuthorsGetter } from '../../../../apollo/queries/authors.web.graphql';
import { Suggest } from '../../../../components/Suggest';
import { actions, ArticleEditAction } from '../../reducer';
import { AUTHORS_LABEL } from '../constants';

interface AuthorsInputsProps {
  authors: GetArticle_article_articleAuthors[];
  brandKey?: BrandKey;
  dispatch: React.Dispatch<ArticleEditAction>;
  requiredInput?: boolean;
}

export const AuthorsInput: React.FC<AuthorsInputsProps> = ({
  authors,
  brandKey,
  dispatch,
  requiredInput,
}) => {
  const getAuthors = useAuthorsGetter();

  const authorsCallback = async (name: string) => {
    const { authors: authorsList } = await getAuthors({
      first: 100,
      where: {
        name_contains: name,
        ...(brandKey && { brandKey }),
      },
    });

    return authorsList.map((author) => ({
      id: author.id,
      title: author.name,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      avatarUrl: author.avatarUrl!,
    }));
  };

  return (
    <>
      <InputLabel shrink required={requiredInput}>
        {AUTHORS_LABEL}
      </InputLabel>
      <Suggest
        selectedItems={authors.map(({ author }) => ({
          id: author.id,
          title: author.name,
          avatarUrl: author?.user?.avatarUrl || '',
        }))}
        suggestions={authorsCallback}
        multiple
        isPrefetched={false}
        onChange={(items) => {
          dispatch(
            actions.updateField({
              field: 'articleAuthors',
              value: items.map((item) => ({
                author: { id: item.id, name: item.title },
              })),
            }),
          );
        }}
        isAvatar
      />
    </>
  );
};
