import omit from 'lodash/fp/omit';
import {
  CategoryStatus,
  GetSearchEnabledTags_searchEnabledTags,
  GetSearchEnabledTags_searchEnabledTags_items,
  TagProviderName,
  TagType,
} from '../../__generated__/queries-web';
import { SuggestItemType } from '../../components/Suggest/types';

// Text comparaison
const getNormalizedText = (text: string): string =>
  text
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLowerCase();

export const isTextMatchWithQuery = (text: string, query: string): boolean => {
  const normalizedQuery = getNormalizedText(query);
  const normalizedText = getNormalizedText(text);

  return normalizedQuery.indexOf(normalizedText) !== -1;
};

// Events filter
const filterEvents = (search: string, options: any) => ({
  children = [],
  ...rest
}: any): any => {
  const items = children
    .map(filterEvents(search, options))
    .filter((event: any) => {
      if (
        options?.skip &&
        Object.keys(options.skip).every((e) => event[e] == options.skip[e])
      ) {
        return false;
      }

      return (
        isTextMatchWithQuery(search, event.title) || event.children?.length
      );
    });

  return { ...rest, ...(items.length && { children: items }) };
};

const flattenEvents = (events: any[] = []): any => {
  let newEvents: any[] = [];

  events.forEach((event) => {
    // eslint-disable-next-line fp/no-mutating-methods
    newEvents.push({
      ...omit(['children'], event),
      type: 'Event' as TagType,
    });

    if (event.children?.length) {
      newEvents = newEvents.concat(flattenEvents(event.children));
    }
  });

  return newEvents;
};

interface DerivedGetSearchTagsList_searchTags
  extends GetSearchEnabledTags_searchEnabledTags {
  items: (GetSearchEnabledTags_searchEnabledTags_items & {
    status: CategoryStatus;
    level: number;
  })[];
}
export const searchEvents = (events: SuggestItemType, search: string) => {
  const newItems = filterEvents(search, {
    skip: { status: [CategoryStatus.Archived] },
  })(events);

  const items = flattenEvents(newItems.children);
  const itemCount = items.length;

  const searchEventsResult: DerivedGetSearchTagsList_searchTags = {
    name: 'Event' as TagProviderName,
    itemCount,
    items,
    __typename: 'TagSearchResult',
  };

  return searchEventsResult;
};
