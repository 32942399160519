import { Box } from '@mui/material';
import { Loader } from '@prismamedia/one-components';
import React, { FC } from 'react';
import { AffilizzProductType } from '../../../../__generated__/queries-web';

interface AffilizzPreviewProps {
  modelId?: string;
  type: AffilizzProductType;
  contentId: string;
}

export const AffilizzPreview: FC<AffilizzPreviewProps> = ({
  modelId,
  type,
  contentId,
}) => {
  const getRenderingComponent = () => {
    if (type === AffilizzProductType.CatalogProduct) {
      return (
        <affilizz-catalog-component
          type="CARD"
          locale="fr_FR"
          design-id={modelId}
          product-localized-id={contentId}
          media-id={config.AFFILIZZ_MEDIA_ID}
          loading="lazy"
        />
      );
    }

    return (
      <affilizz-rendering-component
        design-id={modelId}
        publication-content-id={contentId}
        loading="lazy"
      />
    );
  };

  return (
    <Box
      sx={{
        display: 'flex',
        width: '100%',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        py: 2,
        px: 1,
      }}
    >
      {!contentId ? (
        <Loader size={64} message="Chargement de la prévisualisation..." />
      ) : (
        getRenderingComponent()
      )}
    </Box>
  );
};
