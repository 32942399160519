import { isAfter, isBefore } from 'date-fns';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { ArticleStatus } from '../../../__generated__/queries-web';
import { formatDate } from '../../../utils/dateUtils';
import { ThunkDispatch } from '../../../utils/thunkReducer';
import { actions, ArticleEditAction, ArticleState } from '../reducer';
import { ConfirmDialog } from './ConfirmDialog';
import {
  confirmPublishAfterMessage,
  confirmPublishBeforeMessage,
  confirmRepublishMessage,
  confirmScheduleAfterMessage,
  confirmScheduleBeforeMessage,
  confirmScheduleWithoutDateMessage,
} from './messages';
import { ConfirmationDialogAction } from './types';

interface StatusTransitionProps {
  currentStatus: ArticleStatus;
  dispatch: ThunkDispatch<ArticleEditAction>;
  newStatus: ArticleStatus | null;
  publishedAt: string | null;
  saveArticle: (forceArticle?: ArticleState) => void;
}

export const StatusTransition: FC<StatusTransitionProps> = ({
  currentStatus,
  dispatch,
  newStatus,
  publishedAt,
  saveArticle,
}) => {
  const [openConfirmDialog, setOpenConfirmDialog] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [confirmDialogActions, setConfirmDialogActions] = useState<
    ConfirmationDialogAction[]
  >([]);

  const cancelAction: ConfirmationDialogAction = useMemo(
    () => ({
      label: 'Annuler',
      callback: () => setOpenConfirmDialog(false),
    }),
    [],
  );

  const publishNowAction = useCallback(
    (label: string): ConfirmationDialogAction => ({
      label,
      callback: () => {
        dispatch((dispatchFn: any, getState: any) => {
          dispatchFn(
            actions.updatePublishDateAndStatus({
              publishDate: new Date().toISOString(),
              status: ArticleStatus.Published,
            }),
          );
          saveArticle(getState());
        });
        setOpenConfirmDialog(false);
      },
    }),
    [dispatch, saveArticle],
  );

  const scheduledAction = useCallback(
    (label: string, publishedAtDate: Date): ConfirmationDialogAction => ({
      label: `${label} pour le ${formatDate(
        publishedAtDate,
        'd MMMM yyyy à HH:mm',
      )}`,
      callback: () => {
        dispatch((dispatchFn: any, getState: any) => {
          dispatchFn(
            actions.updatePublishDateAndStatus({
              publishDate: publishedAtDate.toISOString(),
              status: ArticleStatus.Scheduled,
            }),
          );
          saveArticle(getState());
        });
        setOpenConfirmDialog(false);
      },
    }),
    [dispatch, saveArticle],
  );

  const handlePublishScheduled = useMemo(
    () => (
      beforeMessage: (d: Date) => string,
      afterMessage: (d: Date) => string,
    ) => {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const publishedAtDate = new Date(publishedAt!);
      const today = new Date();
      if (isBefore(publishedAtDate, today)) {
        setOpenConfirmDialog(true);
        setMessage(beforeMessage(publishedAtDate));
        setConfirmDialogActions([
          cancelAction,
          publishNowAction('Publier maintenant'),
          scheduledAction('Antidater', publishedAtDate),
        ]);
        return;
      }
      if (isAfter(publishedAtDate, today)) {
        setOpenConfirmDialog(true);
        setMessage(afterMessage(publishedAtDate));
        setConfirmDialogActions([
          cancelAction,
          publishNowAction('Publier maintenant'),
          scheduledAction('Programmer', publishedAtDate),
        ]);
      }
    },
    [cancelAction, publishNowAction, scheduledAction, publishedAt],
  );

  useEffect(() => {
    switch (newStatus) {
      case ArticleStatus.Published:
        // Republish case
        if (currentStatus === ArticleStatus.Published) {
          setOpenConfirmDialog(true);
          setMessage(confirmRepublishMessage);
          setConfirmDialogActions([cancelAction, publishNowAction('Valider')]);
        }
        if (!publishedAt) {
          dispatch((dispatchFn: any, getState: any) => {
            dispatchFn(
              actions.updatePublishDateAndStatus({
                publishDate: new Date().toISOString(),
                status: ArticleStatus.Published,
              }),
            );
            saveArticle(getState());
          });
          return;
        }
        handlePublishScheduled(
          confirmPublishBeforeMessage,
          confirmPublishAfterMessage,
        );
        break;
      case ArticleStatus.Scheduled:
        if (!publishedAt) {
          setOpenConfirmDialog(true);
          setMessage(confirmScheduleWithoutDateMessage);
          setConfirmDialogActions([
            cancelAction,
            publishNowAction('Publier maintenant'),
          ]);
          return;
        }
        handlePublishScheduled(
          confirmScheduleBeforeMessage,
          confirmScheduleAfterMessage,
        );
        break;
      case ArticleStatus.Frozen:
        dispatch((dispatchFn: any, getState: any) => {
          dispatchFn(
            actions.updatePublishDateAndStatus({
              publishDate: getState().publishedAt,
              status: ArticleStatus.Frozen,
            }),
          );
          saveArticle(getState());
        });
        break;
      case ArticleStatus.Ready:
        dispatch((dispatchFn: any, getState: any) => {
          dispatchFn(
            actions.updatePublishDateAndStatus({
              publishDate: getState().publishedAt,
              status: ArticleStatus.Ready,
            }),
          );
          saveArticle(getState());
        });
        break;
      case ArticleStatus.Draft:
        dispatch((dispatchFn: any, getState: any) => {
          dispatchFn(
            actions.updatePublishDateAndStatus({
              publishDate: getState().publishedAt,
              status: ArticleStatus.Draft,
            }),
          );
          saveArticle(getState());
        });
        break;
      case ArticleStatus.Deleted:
        dispatch((dispatchFn: any, getState: any) => {
          dispatchFn(
            actions.updatePublishDateAndStatus({
              publishDate: getState().publishedAt,
              status: ArticleStatus.Deleted,
            }),
          );
          saveArticle(getState());
        });
        break;
      case ArticleStatus.Private:
        dispatch((dispatchFn: any, getState: any) => {
          dispatchFn(
            actions.updatePublishDateAndStatus({
              publishDate: getState().publishedAt,
              status: ArticleStatus.Private,
            }),
          );
          saveArticle(getState());
        });
        break;
    }
  }, [
    cancelAction,
    currentStatus,
    dispatch,
    handlePublishScheduled,
    newStatus,
    publishNowAction,
    publishedAt,
    saveArticle,
    scheduledAction,
  ]);
  return (
    <ConfirmDialog
      dialogActions={confirmDialogActions}
      message={message}
      open={openConfirmDialog}
    />
  );
};
