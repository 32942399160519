import { AutocompleteItem, ChipsInput } from '@prismamedia/one-components';
import { FC } from 'react';
import {
  ArticleFormat,
  BrandKey,
  GetSearchEnabledTags_searchEnabledTags_items,
  TagProviderName,
} from '../../../../__generated__/queries-web';
import { useSearchEnabledTagsGetter } from '../../../../apollo/queries/getEnabledTags.web.graphql';

interface TagsInputProps {
  value?: AutocompleteItem[];
  className?: string;
  onChange?: (items: AutocompleteItem[]) => void;
  brandKey: BrandKey;
  articleFormat: ArticleFormat;
}

export const TagsInput: FC<TagsInputProps> = ({
  value,
  className,
  onChange,
  brandKey,
  articleFormat,
}) => {
  const searchTags = useSearchEnabledTagsGetter();
  const getSecondaryLabel = (
    tag: GetSearchEnabledTags_searchEnabledTags_items,
  ) => {
    if (articleFormat === ArticleFormat.Rich) {
      return `${tag.type} - ${tag.articleCount || 0} article${
        (tag.articleCount || 0) > 1 ? 's' : ''
      }`;
    }
    return tag.type;
  };

  const videoProviders =
    articleFormat === ArticleFormat.Video
      ? [TagProviderName.VideoTag]
      : undefined;

  const fetchList = (search: string) =>
    searchTags({ brandKey, search, providers: videoProviders }).then((tags) =>
      tags?.map((tag) => ({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        id: tag.id!,
        label: tag.title,
        secondaryLabel: getSecondaryLabel(tag),
        // We must check the strict equality because a tag can be active with isEnabled === null
        isInactive: tag.type === 'Tag' && tag.isEnabled === false,
      })),
    );

  return (
    <ChipsInput
      label="Tags"
      value={value}
      onChange={onChange}
      className={className}
      autocomplete={{ fetchList, mandatoryInput: true }}
    />
  );
};
