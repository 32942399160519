export const callOpenAI = async (content: string) => {
  const response = await fetch('https://api.openai.com/v1/chat/completions', {
    method: 'POST',
    headers: {
      Authorization: `Bearer sk-proj-f3ylxt4E4l0cj4SHn53PT3BlbkFJazJYwKIH07QsZrvqBbrL`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      model: 'gpt-4o',
      messages: [{ role: 'user', content }],
    }),
  });
  if (!response.ok) {
    const errorData = await response.json();
    throw new Error(
      `Error: ${response.status} ${response.statusText}\n${JSON.stringify(
        errorData,
      )}`,
    );
  }

  const responseData = (await response.json()) as {
    choices: {
      message: {
        content: string;
      };
    }[];
  };
  return responseData.choices[0].message.content.replace(/"/g, '');
};
