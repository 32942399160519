import { FC, ReactNode, Suspense, SuspenseProps, useEffect } from 'react';

interface ISuspendProps<T extends PromiseLike<any> = PromiseLike<any>> {
  task: T;
  children: (data: PromiseOf<T>) => ReactNode;
}

enum TaskStatus {
  PENDING,
  SUCCESS,
  ERROR,
}

const queuedTasks = new Map<
  PromiseLike<any>,
  {
    read: () => any;
  }
>();

const suspend = <T extends PromiseLike<any>>(task: T) => {
  let status = TaskStatus.PENDING;
  let response: PromiseOf<T>;

  const suspender = task.then(
    (res) => {
      status = TaskStatus.SUCCESS;
      response = res;
    },
    (err) => {
      status = TaskStatus.ERROR;
      response = err;
    },
  );

  const read = () => {
    switch (status) {
      case TaskStatus.PENDING:
        throw suspender;
      case TaskStatus.ERROR:
        throw response;
      default:
        return response;
    }
  };

  return { read };
};

const Suspend: FC<ISuspendProps> = ({ task, children }) => {
  if (!queuedTasks.has(task)) {
    queuedTasks.set(task, suspend(task));
  }

  useEffect(
    () => () => {
      queuedTasks.delete(task);
    },
    [task],
  );

  const result = queuedTasks.get(task)?.read();
  return <>{children(result)}</>;
};

export function SuspendTask<T extends PromiseLike<any>>({
  task,
  children,
  ...rest
}: ISuspendProps<T> & Omit<SuspenseProps, 'children'>) {
  return (
    <Suspense {...rest}>
      <Suspend task={task}>{children}</Suspend>
    </Suspense>
  );
}
