import {
  loadImage,
  NotificationTypeEnum,
  PushNotificationFn,
} from '@prismamedia/one-components';
import { compareDesc } from 'date-fns';

export const validateInputValue = (value: string, maxLength: number) => {
  if (value.length >= maxLength) {
    return value.slice(0, maxLength);
  }
  return value;
};

export const validateDate = (
  value: string,
  date: string | null | undefined,
  pushNotification: PushNotificationFn,
) => {
  if (!date) {
    pushNotification({
      type: NotificationTypeEnum.error,
      message: "L'article n'a pas de date de publication",
    });
    return false;
  }
  const isDatePrior = compareDesc(new Date(value), new Date(date));

  if (isDatePrior === 1) {
    pushNotification({
      type: NotificationTypeEnum.error,
      message:
        "Veuillez choisir une date postérieure à la date de publication de l'article",
    });
    return false;
  }
  return true;
};

export const handleImageWidth = async (file: File) => {
  const image = await loadImage(window.URL.createObjectURL(file));
  return image.width > 4096 || image.width < 1280;
};
