import {
  NotificationTypeEnum,
  useNotification,
  useOnMount,
} from '@prismamedia/one-components';
import { isString, sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMountedState } from 'react-use';
import type { GetArticle_article_articleTags } from '../../../../../../../__generated__/queries-web';
import { TagType } from '../../../../../../../__generated__/queries-web';
import { usePeoplesGetter } from '../../../../../apollo/queries/peoples.people.graphql';
import type { CategoryFormSectionPeopleContent } from '../types';
import { useEditSection } from '../useEditSection';
import type { PeopleProps } from './';

let timeoutId: ReturnType<typeof setTimeout>;

interface Handlers {
  handleContentChange: { (items: GetArticle_article_articleTags[]): void };
}

interface UseEditPeopleProps {
  content: CategoryFormSectionPeopleContent[];
  handlers: Handlers;
  isLoading: boolean;
}

export const useEditPeople = (
  sectionProps: PeopleProps,
): UseEditPeopleProps => {
  const { handlers: sectionHandlers } = useEditSection(sectionProps.id);
  const isMounted = useMountedState();
  const { pushNotification } = useNotification();
  const searchPeoples = usePeoplesGetter();

  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const sectionContent = useMemo(() => sectionProps.content!, [
    sectionProps.content,
  ]);

  const handlers = useMemo(
    (): Handlers => ({
      handleContentChange: (newItems) => {
        const newContent = newItems.map(({ order, tag }) => ({
          ...tag,
          id: tag.relationId, // Because a tag.id !== people id
          order,
        }));

        sectionHandlers.handleSectionChange({
          fieldName: 'content',
          value: newContent,
        });
      },
    }),
    [sectionHandlers],
  );

  const fetchPeopleTags = useCallback(async () => {
    const peoplesIdList = sectionContent
      .filter((item) => !!item.id && isString(item.id))
      .map(({ id }) => id);

    setIsLoading(!!peoplesIdList.length);

    const peoplesPromise = searchPeoples(
      {
        where: { id_in: peoplesIdList },
      },
      !peoplesIdList.length,
    );

    peoplesPromise
      .then((peoplesResponse) => {
        if (peoplesResponse?.data?.people?.length) {
          const { people } = peoplesResponse.data;

          // Build people tags
          const mergedPeopleTags = people.map((peopleTag, index) => ({
            __typename: 'Tag',
            relationId: peopleTag.id,
            title: peopleTag.fullName,
            type: TagType.Person,
            ...sectionContent
              .map((content) => ({
                id: content.id,
                isActive: content.isActive || false,
                order: content.order || index,
              }))
              .find((content) => content.id === peopleTag.id),
          }));

          const sortedByOrderMergedPeopleTags = sortBy(
            mergedPeopleTags,
            (peopleTag) => peopleTag.order,
          );

          if (isMounted()) {
            sectionHandlers.handleSectionChange({
              fieldName: 'content',
              opts: { setFormPristine: true },
              value: sortedByOrderMergedPeopleTags,
            });
          }
        }
      })
      .catch((error) => {
        pushNotification({
          type: NotificationTypeEnum.error,
          message: `[Unable to fetch peoples details for section "${
            sectionProps.title
          }"]
              ${(error as Error).message}
            `,
        });
      })
      .finally(() => {
        if (isMounted()) {
          timeoutId = setTimeout(() => {
            setIsLoading(false);
          }, 500);
        }
      });
  }, [
    isMounted,
    pushNotification,
    searchPeoples,
    sectionContent,
    sectionHandlers,
    sectionProps.title,
  ]);

  useOnMount(() => {
    if (!sectionContent.every((people) => !!people.__typename)) {
      fetchPeopleTags();
    }
  });

  useEffect(
    () => () => {
      timeoutId && clearTimeout(timeoutId);
    },
    [],
  );

  return { content: sectionContent, handlers, isLoading };
};
