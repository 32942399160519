import { HomeOutlined } from '@mui/icons-material';
import {
  AppDrawerMenu,
  AppName,
  AutocompleteItem,
  HomeAppBar,
  isDefined,
  SearchBar,
} from '@prismamedia/one-components';
import { format } from 'date-fns';
import React, { FC, useEffect } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import {
  ArticleFormat,
  ArticleSearchOrderByInput,
  ArticleStatus,
  BrandKey,
  CategoryFormat,
} from '../../__generated__/queries-web';
import { useSearchArticlesGetter } from '../../apollo/queries/searchArticles.web.graphql';
import { usePageCategories } from '../../pages/Pages/apollo/queries/categories.web.graphql';
import { DEFAULT_ROOT_CATEGORY } from '../../pages/Pages/constants';
import { paths } from '../../routing/Router/paths';
import { auth } from '../../utils/auth';
import { AdvancedSearchParams } from '../../utils/formatArticleSearchInput';
import {
  useGlobalSearchParams,
  useGlobalStatus,
} from '../../utils/globalState';
import { BrandSwitch } from '../BrandSwitch';
import { AdvancedSearch } from './AdvancedSearch';
import { BoneLink, menuLinks } from './confLinks';

interface AppBarProps {
  app: AppName;
  tabs?: { label: string; status: ArticleStatus }[];
  setSearchParams?: (params: AdvancedSearchParams) => void;
  searchParams?: AdvancedSearchParams;
  articleFormat?: ArticleFormat;
  brandKey?: BrandKey;
  status: ArticleStatus;
  setBrandKey?: (brandKey: BrandKey | undefined) => void;
  setStatus?: (status: ArticleStatus) => void;
}

interface OneDrawerMenuProps {
  currentBrand?: BrandKey;
  app: AppName;
  isOpen?: boolean;
  onLogOff?: () => any;
}

const getLinkUrl = (link: BoneLink, brand?: BrandKey) => {
  const { href, hrefWithoutBrand } = link;
  return href && brand
    ? href.replace('{BRAND}', brand)
    : hrefWithoutBrand || href;
};

const shouldDisableURL = (link: BoneLink, brand?: BrandKey) => {
  const { href, hrefWithoutBrand, onClick } = link;

  if (onClick) return false;
  if (!href && !hrefWithoutBrand) return true;

  const shouldHaveBrandKey = Boolean(
    href && href.indexOf('{BRAND}') > -1 && !hrefWithoutBrand,
  );
  return Boolean(shouldHaveBrandKey && !brand);
};

const baseUrl = `${window.location.protocol}//${window.location.host}`;

const matchURL = (target?: string) =>
  target && document.location.href.indexOf(target) > -1;

export const OneDrawerMenu = ({ currentBrand, app }: OneDrawerMenuProps) => {
  const [, setStatus] = useGlobalStatus();
  const [, setSearchParams] = useGlobalSearchParams();
  const [open, setOpen] = React.useState<boolean>(true);

  const { data } = usePageCategories({
    variables: {
      first: 1000,
      where: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        brandKey_in: [currentBrand!],
        format: CategoryFormat.Category,
        level_in: [
          DEFAULT_ROOT_CATEGORY.level,
          DEFAULT_ROOT_CATEGORY.level + 1,
        ],
      },
    },
    skip: !currentBrand,
  });

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setStatus(ArticleStatus.Published);
        setSearchParams({});
      });
    }
    // eslint-disable-next-line
  }, [open]);

  return (
    <AppDrawerMenu
      appName={app}
      disconnectUser={auth.logout}
      topSection={(data?.categories?.length && data.categories[0].parent?.id
        ? [
            ...menuLinks,
            {
              label: 'Home page',
              href: `#/{BRAND}/page/category/edit/${data.categories[0].parent.id}/${data.categories[0].id}`,
              icon: <HomeOutlined fontSize="medium" />,
            },
          ]
        : menuLinks
      ).map((link) => ({
        ...link,
        href: getLinkUrl(link, currentBrand),
        disabled: shouldDisableURL(link, currentBrand),
        selected: !!matchURL(getLinkUrl(link, currentBrand)),
        onClick: () => {
          if (isDefined(link.openBlank)) {
            setOpen(link.openBlank);
          }
        },
      }))}
      overriddenAppUrls={{
        [AppName.Authors]: `${baseUrl}/#/authors/list`,
        [AppName.Newsletter]: config.FRONT_ONE_NEWSLETTER,
        [AppName.People]: config.FRONT_ONE_PEOPLE,
        [AppName.Photo]: config.FRONT_ONE_PHOTO,
        [AppName.Recipe]: config.FRONT_ONE_RECIPE,
        [AppName.Story]: config.FRONT_ONE_TOPIC,
        [AppName.Video]: `${baseUrl}/#/video/list`,
      }}
    />
  );
};

export const AppBarOne: FC<AppBarProps> = ({
  app,
  tabs,
  setSearchParams,
  searchParams,
  articleFormat,
  brandKey,
  status,
  setBrandKey,
  setStatus,
}) => {
  const history = useHistory();
  const searchUsers = useSearchArticlesGetter();

  const fetchAutocompleteList = async (
    search: string,
  ): Promise<AutocompleteItem[] | undefined> => {
    const { data, errors } = await searchUsers({
      first: 10,
      skip: 0,
      orderBy: [ArticleSearchOrderByInput.publishedAt_DESC],
      where: {
        title_contains: search,
        format: articleFormat,
        status: ArticleStatus.Published,
        brandKey,
      },
    });

    if (errors?.length) {
      throw errors[0];
    }

    return data?.searchArticles?.map(
      ({ id, title, publishedAt, brandKey: articleBrand }) => ({
        id,
        label: title || 'NO_TITLE',
        secondaryLabel: publishedAt
          ? format(new Date(publishedAt), 'd MMM')
          : undefined,
        additionnals: { brandKey: articleBrand },
      }),
    );
  };

  return (
    <HomeAppBar
      currentUser={auth.user}
      disconnectUser={auth.logout}
      appDrawerMenu={<OneDrawerMenu app={app} currentBrand={brandKey} />}
      brandMenu={<BrandSwitch brandKey={brandKey} setBrandKey={setBrandKey} />}
      searchBar={
        searchParams && setSearchParams ? (
          <SearchBar
            advancedSearch={
              brandKey
                ? {
                    component: AdvancedSearch,
                    props: { brandKey, articleFormat },
                  }
                : undefined
            }
            autocomplete={{
              fetchList: fetchAutocompleteList,
              onSelect: ({ id, additionnals }) => {
                const articleLink =
                  articleFormat === ArticleFormat.Slideshow
                    ? generatePath(paths.SLIDESHOW_EDIT, {
                        id: id || '',
                      })
                    : generatePath(paths.ARTICLE_EDIT, {
                        brandKey: additionnals?.brandKey,
                        articleType:
                          articleFormat === ArticleFormat.Rich
                            ? 'article'
                            : 'video',
                        id: id || undefined,
                      });
                articleLink && history.push(articleLink);
              },
            }}
            placeholder={`Rechercher dans One ${app}`}
            searchParams={searchParams}
            setSearchParams={setSearchParams}
          />
        ) : undefined
      }
      tabs={tabs?.map(({ status: tabStatus, label }) => ({
        id: tabStatus,
        label,
        selected: status === tabStatus,
      }))}
      onTabSelect={(tab) => {
        setStatus?.(tab.id as ArticleStatus);
      }}
    />
  );
};
