import {
  BuildCircle,
  FileCopyOutlined,
  History,
  ImportContacts,
  MobileScreenShare,
  Search,
  Spellcheck,
  Visibility,
  Whatshot,
} from '@mui/icons-material';
import { Backdrop, CircularProgress, Menu, MenuItem } from '@mui/material';
import {
  BackTo,
  EditAppBar,
  NotificationTypeEnum,
  PageWithDrawer,
  useConcurrentialAccess,
  useDialog,
  useNotification,
} from '@prismamedia/one-components';
import { EditorState, convertToRaw } from 'draft-js';
import { isEmpty } from 'lodash';
import { editorStateFromRaw } from 'megadraft';
import {
  FC,
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { generatePath, useHistory, useParams } from 'react-router-dom';
import { useBeforeUnload, useKey } from 'react-use';
import {
  ArticleFormat,
  ArticleStatus,
  BrandKey,
  GetArticle_article,
  SimilarArticleInputType,
} from '../../__generated__/queries-web';
import { useUpdateArticleLockerId } from '../../apollo/mutations/article.web.graphql';
import { useGetArticleHistoryCount } from '../../apollo/queries/articleHistoryCount.web.graphql';
import { useGetBrandKey } from '../../apollo/sharedQueries/getBrandKeys.web.graphql';
import { ArticlesRelatedMediaDialog } from '../../components/ArticlesRelatedMediaDialog';
import { ProlexisDialog } from '../../components/ProlexisDialog';
import { InputText } from '../../components/ProlexisDialog/types';
import { SaveButton } from '../../components/SaveButton';
import { StatusSelector } from '../../components/StatusSelector';
import { Tags } from '../../components/Tags';
import { paths } from '../../routing/Router/paths';
import { auth } from '../../utils/auth';
import { brandsOptions, getTitleFromBrandKey } from '../../utils/brands';
import { useThunkReducer } from '../../utils/thunkReducer';
import { formatPreviewUrl } from '../../utils/url';
import { useDailymotionThumbnail } from '../../utils/useDailymotionThumbnail';
import { useGetPushCount } from '../MobilePush/MobilePushList/getPushByBrankey.web.graphql';
import { ArticleHistory } from '../Pages/components/AppBar/Edit/ArticleHistory';
import { ArticleEditor } from './ArticleEditor';
import { CategoriesInput } from './ArticleEditor/CategoriesInput';
import { SourceInput } from './ArticleEditor/SourceInput';
import { ArticleToolBar } from './ArticleToolBar';
import { DrawerContent } from './DrawerContent';
import { Qualifiers } from './DrawerContent/Qualifiers';
import { DuplicatePrintDialog } from './DuplicatePrintDialog';
import { StatusTransition } from './StatusTransition';
import { ARTICLE_ENHANCEMENT_MAIN_TITLE } from './constants';
import { useGetAssigment } from './queries/getAssigment.topic.graphql';
import {
  ArticleEditAction,
  ArticleState,
  actions,
  initState,
  reducer,
} from './reducer';
import { requiredVideoFields } from './saveMandatoryFields';
import { useStyles } from './styles';
import { useSaveArticle } from './useSaveArticle';
import { formatSubTitle } from './utils/formatSubtitle';
import { renderInputFromTemplate } from './utils/renderFromTemplate';
import { getTagsConfiguration } from './utils/tagsConfiguration';

interface ArticleEditProps {
  data: GetArticle_article;
  subjectId?: string;
  subjectTitle?: string;
  error?: Error;
  loading?: boolean;
}

interface ArticleEditParams {
  brandKey: BrandKey;
  articleType: string;
  id: string;
  search: string;
}

export const ArticleEdit: FC<ArticleEditProps> = ({
  data,
  subjectId,
  subjectTitle,
  error,
  loading,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const { articleType, id } = useParams<ArticleEditParams>();
  const { openDialog, closeDialog } = useDialog();
  const { pushNotification } = useNotification();
  const {
    data: articleHistoryCountData,
    loading: historyLoading,
  } = useGetArticleHistoryCount(id);
  const [state, dispatch] = useThunkReducer<
    ArticleState,
    ArticleEditAction,
    GetArticle_article
  >(reducer, data, initState);

  const { displayNewModule } = brandsOptions[state.brandKey];

  const renderInput = useMemo(
    () => renderInputFromTemplate(state.format, state.brandKey || BrandKey.CAP),
    [state.format, state.brandKey],
  );

  const tagsConfiguration = useMemo(
    () =>
      getTagsConfiguration({
        articleEvents: state.articleEvents,
        articleGuides:
          displayNewModule && renderInput('guides')
            ? state.articleGuides
            : undefined,
        articleTags: state.articleTags,
        brandKey: state.brandKey,
      }),
    [
      state.articleTags,
      state.articleEvents,
      state.brandKey,
      state.articleGuides,
      renderInput,
      displayNewModule,
    ],
  );

  const liveAndPushRef = useRef(null);
  const drawerCenterRef = useRef(null);
  const drawerButtonRef = useRef(null);
  const wrapperRef = useRef(null);

  const updateLockerId = useUpdateArticleLockerId();

  useConcurrentialAccess({
    lockStatus: data.articleExtension || {},
    unlockTimeout: state.articleChanged ? 1800000 : 600000,
    currentUser: auth.user,
    onLock: (firstLock) =>
      updateLockerId(data.id, auth?.user?.id || null, !firstLock),
    onNavigateBack: () =>
      history.push(isVideoPage ? paths.ARTICLE_VIDEO : paths.ARTICLE_LIST),
    disableLockForV0: true,
  });

  const machineTagData = data.articleMachineTags?.find((_machineTagdata) =>
    _machineTagdata.machineTag.tag.startsWith('topicassignment:id'),
  );
  const subject = useGetAssigment(machineTagData?.machineTag.id || '').data
    ?.assignment?.subject;
  if (subject) {
    // eslint-disable-next-line no-param-reassign
    subjectId = subject.id;
    // eslint-disable-next-line no-param-reassign
    subjectTitle = subject.title;
  }

  useEffect(() => {
    dispatch(actions.initArticleData({ articleData: data }));
  }, [data, dispatch]);

  const { saveArticle, loading: saveLoading } = useSaveArticle(state, dispatch);

  useBeforeUnload(
    state.articleChanged,
    'Vous avez des changements non sauvegardés, êtes-vous sûr de vouloir quitter ?',
  );

  useKey(
    (event: KeyboardEvent) => {
      if (event.key === 's' && event.metaKey) {
        event.preventDefault();
        if (!saveLoading) {
          return true;
        }
      }
      return false;
    },
    () => saveArticle(),
  );

  const subTitleText = formatSubTitle(
    state.status,
    state.publishedAt,
    state.editedAt,
  );
  const pushCount = useGetPushCount(state.id);

  const lead = isEmpty(state.lead) ? null : state.lead;
  const body = isEmpty(state.body) ? null : state.body;
  const isVideoPage = state.format === ArticleFormat.Video;
  const isNewVideoArticle =
    state.format === ArticleFormat.Video &&
    state.status === ArticleStatus.Draft;
  const publicUrl = formatPreviewUrl(state.contextualizedUrl, state.brandKey);
  const displayDuplicateAction =
    state.format !== ArticleFormat.Video &&
    state.status !== ArticleStatus.Draft;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const { data: brandKeys } = useGetBrandKey();

  useDailymotionThumbnail(state.medias || [], isVideoPage);

  const handleClick = (brandKey: string) => {
    setAnchorEl(null);
    const newLocation = generatePath(paths.ARTICLE_DUPLICATE, {
      id,
      brandKey,
    });
    window.open(`${window.location.origin}#${newLocation}`);
  };

  const handleTagsChange = useCallback(
    (articleTags, field) => {
      dispatch(
        actions.setArticleTags({
          field,
          value: articleTags,
        }),
      );
    },
    [dispatch],
  );

  const closeProlexis = (texts: InputText[] | false) => {
    if (texts !== false) {
      texts.forEach(({ name, value }) => {
        if (name === 'title') {
          dispatch(
            actions.updateField({
              field: name,
              value,
            }),
          );
        }
        try {
          if (value) {
            const val = value as EditorState;
            const content = val?.getCurrentContent();
            if (content) {
              const rawContent = convertToRaw(content);
              dispatch(
                actions.updateField({
                  field: name,
                  value: rawContent,
                }),
              );
            }
          }
        } catch (e) {
          closeDialog();
        }
      });
    }
    closeDialog();
  };

  const historyAction =
    state.articleHistories?.length > 0
      ? [
          {
            id: 'history',
            label: 'Historique',
            icon: <History />,
            onClick: () => {
              openDialog(
                <ArticleHistory
                  histories={state.articleHistories}
                  historyCount={articleHistoryCountData?.articleHistoryCount}
                  loading={historyLoading}
                  parentArticle={state.parentArticle}
                />,
                {
                  maxWidth: 'lg',
                },
              );
            },
          },
        ]
      : [];

  const prolexisAction = [
    {
      id: 'correction',
      label: 'Correction',
      icon: <Spellcheck />,
      onClick: () => {
        openDialog(
          <ProlexisDialog
            texts={[
              {
                label: 'Corps',
                name: 'body',
                type: 'DRAFT',
                value: editorStateFromRaw(state.body || ''),
              },
              {
                label: 'Titre',
                name: 'title',
                type: 'TEXT',
                value: state.title || '',
              },
              {
                label: 'Chapô',
                name: 'lead',
                type: 'DRAFT',
                value: editorStateFromRaw(state.lead || ''),
              },
            ]}
            handleClose={closeProlexis}
            brandKey={state.brandKey}
          />,
          {
            maxWidth: 'lg',
          },
        );
      },
    },
  ];

  const locationAction = [
    {
      id: 'location',
      label: 'Localisation',
      icon: <Search />,
      onClick: () => {
        const idMedia = state.medias?.[0]?.iframely?.meta?.public_id;

        if (!idMedia) {
          pushNotification({
            type: NotificationTypeEnum.error,
            message: "L'identifiant du media n'existe pas",
          });
          return;
        }

        openDialog(
          <ArticlesRelatedMediaDialog
            idMedia={idMedia}
            title={state.title}
            articleFormat={state.format}
            handleClose={closeDialog}
          />,
          { maxWidth: 'lg' },
        );
      },
    },
  ];

  const videosActions = [...locationAction, ...historyAction];

  const showButtonSeeFront =
    state.status === 'Scheduled' || state.status === 'Published';

  const richActions = [
    ...prolexisAction,
    ...(brandsOptions[state.brandKey].pushPlatforms?.length ||
    brandsOptions[state.brandKey].editorialAlertAllowed
      ? [
          {
            id: 'push',
            label: 'Push',
            icon: <MobileScreenShare ref={liveAndPushRef} />,
            onClick: () =>
              history.push(`/${state.brandKey}/mobilepush/add?id=${id}`),
            badge: pushCount,
          },
        ]
      : []),
    ...(publicUrl && showButtonSeeFront
      ? [
          {
            id: 'preview',
            label: 'Visualiser',
            icon: <Visibility />,
            onClick: () => {
              window.open(publicUrl);
            },
          },
        ]
      : []),
    ...(displayDuplicateAction
      ? [
          {
            id: 'duplicate',
            label: 'Duplication web',
            icon: <FileCopyOutlined />,
            onClick: (e: MouseEvent<HTMLButtonElement>) =>
              setAnchorEl(e.currentTarget),
          },
        ]
      : []),
    ...(displayDuplicateAction && displayNewModule
      ? [
          {
            id: 'duplicatePrint',
            label: 'Duplication print',
            icon: <ImportContacts />,
            onClick: () =>
              openDialog(<DuplicatePrintDialog articleState={state} />, {
                fullWidth: true,
                maxWidth: 'md',
              }),
          },
        ]
      : []),
    {
      id: 'live',
      label: 'Live',
      icon: <Whatshot ref={liveAndPushRef} />,
      onClick: () =>
        history.push(`/${state.brandKey}/${articleType}/live/${id}`),
      badge: data?.live?.postCount,
    },
    ...(state.status === 'Published'
      ? [
          {
            id: 'diagnostic',
            label: 'Diagnostic',
            icon: <BuildCircle />,
            onClick: () => {
              window.open(
                `${config.FRONT_DIAGNOSTIC}/pipeline/create?manifest=one%2Farticle&id=${id}&_target=all&_format=notify`,
              );
            },
          },
        ]
      : []),
    ...historyAction,
  ];

  const barActions =
    state.format === ArticleFormat.Video ? videosActions : richActions;

  return (
    <div className={classes.wrapper} ref={wrapperRef}>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={saveLoading}
      >
        <CircularProgress color="inherit" size={100} />
      </Backdrop>
      <EditAppBar
        onNavigateBack={() => {
          history.push(isVideoPage ? paths.ARTICLE_VIDEO : paths.ARTICLE_LIST);
          updateLockerId(data.id, null).catch(() => {});
        }}
        title={state.title || undefined}
        subTitle={subTitleText}
        actions={barActions}
        save={
          isVideoPage
            ? {
                actionLabel: isNewVideoArticle ? 'Créer la vidéo' : undefined,
                loading: saveLoading,
                onClick: async () => {
                  try {
                    await saveArticle();
                  } catch (e) {}
                },
                requiredFields: isNewVideoArticle
                  ? requiredVideoFields(state)
                  : undefined,
              }
            : undefined
        }
        concurrentialAccess={{
          lockStatus: data.articleExtension,
          disableLockForV0: true,
        }}
        additionalElement={
          <>
            {!isVideoPage && (
              <SaveButton loading={saveLoading} onClick={saveArticle} />
            )}
            {!isNewVideoArticle && (
              <StatusSelector
                articleState={state}
                loading={saveLoading}
                currentStatus={state.status}
                changeStatus={(newStatus) => {
                  if (state.format === 'Video' && newStatus === 'Deleted') {
                    const idMedia =
                      state.medias?.[0]?.iframely?.meta?.public_id;

                    if (!idMedia) {
                      pushNotification({
                        type: NotificationTypeEnum.error,
                        message: "L'identifiant du media n'existe pas",
                      });
                      return;
                    }

                    openDialog(
                      <ArticlesRelatedMediaDialog
                        idMedia={idMedia}
                        title={state.title}
                        articleFormat={state.format}
                        handleDelete={() => {
                          dispatch(actions.changeStatus({ newStatus }));
                          closeDialog();
                        }}
                        handleClose={closeDialog}
                      />,
                      { maxWidth: 'lg' },
                    );
                  } else {
                    dispatch(actions.changeStatus({ newStatus }));
                  }
                }}
              />
            )}
          </>
        }
        toolbar={
          <ArticleToolBar
            brandLabel={
              displayNewModule
                ? getTitleFromBrandKey(state.brandKey) ?? undefined
                : undefined
            }
            title={state.title}
            lead={lead}
            body={body}
          />
        }
      />

      <PageWithDrawer
        error={error}
        loading={loading}
        paddingTop={48}
        fullWidthRight={displayNewModule && state.format === ArticleFormat.Rich}
        ref={drawerCenterRef}
        refs={{ rightButton: drawerButtonRef }}
        rightDrawer={
          <DrawerContent
            subjectId={subjectId}
            subjectTitle={subjectTitle}
            lead={lead}
            id={state.id}
            body={body}
            title={state.title}
            publishedAt={state.publishedAt}
            articleCategories={state.articleCategories}
            articleTags={state.articleTags}
            articleAuthors={state.articleAuthors}
            source={state.source}
            articleEvents={state.articleEvents}
            status={state.status}
            brandKey={state.brandKey}
            slug={state.slug}
            renderInput={renderInput}
            articleQualifiers={state.articleQualifiers}
            articleGuides={state.articleGuides}
            articleChannels={state.articleChannels}
            dispatch={dispatch}
            format={state.format}
            articleMachineTags={state.articleMachineTags}
          />
        }
      >
        <ArticleEditor
          id={id || data.id}
          articleEditWrapperRef={wrapperRef}
          body={body}
          brandKey={state.brandKey}
          dispatch={dispatch}
          format={state.format}
          lead={lead}
          medias={state.medias}
          partnerTitle={state.partnerTitle}
          renderInput={renderInput}
          secondaryTitle={state.secondaryTitle}
          subjectId={subjectId}
          title={state.title}
          platformId={state.platformId}
          status={state.status}
          publicUrl={publicUrl}
          articleAuthors={state.articleAuthors}
          slug={state.slug}
          articleUrls={state.articleUrls}
          SourceComponent={
            displayNewModule &&
            renderInput('source') && (
              <SourceInput
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                brandKey={state.brandKey!}
                source={state.source}
                dispatch={dispatch}
              />
            )
          }
          CategoriesComponent={
            displayNewModule &&
            (renderInput('firstCategory') || renderInput('secondCategory')) && (
              <CategoriesInput
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                brandKey={state.brandKey!}
                categories={state.articleCategories}
                dispatch={dispatch}
                multiple={
                  renderInput('firstCategory') && renderInput('secondCategory')
                }
              />
            )
          }
        />

        <Tags
          article={
            {
              body: state.body,
              lead: state.lead,
              id: state.id,
              title: state.title,
              format: state.format,
            } as SimilarArticleInputType
          }
          brandKey={state.brandKey}
          configuration={tagsConfiguration}
          onTagsChange={handleTagsChange}
          title={ARTICLE_ENHANCEMENT_MAIN_TITLE}
          QualifierComponent={
            displayNewModule && (
              <Qualifiers
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                brandKey={state.brandKey!}
                articleQualifiers={state.articleQualifiers}
                articleFormat={state.format}
                onChange={(updatedQualifiers) => {
                  dispatch(
                    actions.updateField({
                      field: 'articleQualifiers',
                      value: updatedQualifiers,
                    }),
                  );
                }}
                label={'Qualifiers'}
              />
            )
          }
        />

        <BackTo
          className={classes.backToButton}
          elementToScrollRef={drawerCenterRef}
        />
      </PageWithDrawer>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {brandKeys &&
          brandKeys.categories &&
          brandKeys.categories.map(({ brandKey }) => (
            <MenuItem key={brandKey} onClick={() => handleClick(brandKey)}>
              {brandKey}
            </MenuItem>
          ))}
      </Menu>

      <StatusTransition
        currentStatus={state.status}
        newStatus={state.newStatus}
        dispatch={dispatch}
        saveArticle={saveArticle}
        publishedAt={state.publishedAt}
      />
    </div>
  );
};
