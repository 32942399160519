import { SubdirectoryArrowRight } from '@mui/icons-material';
import { Box, Typography } from '@mui/material';
import { isUndefined } from 'lodash';
import type { FC } from 'react';
import { useStyles } from './styles';
import { SuggestItemType } from './types';

interface SuggestItemProps {
  ancestorLevel?: number;
  item: SuggestItemType;
  onClick: (item: SuggestItemType) => void;
}

export const SuggestItem: FC<SuggestItemProps> = ({
  ancestorLevel,
  item,
  onClick,
}) => {
  const isAncestor = item?.level === ancestorLevel;
  const hasAncestor = !isUndefined(ancestorLevel && item.level) && !isAncestor;
  const nbAncestors = hasAncestor
    ? // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Math.max(item.level! - ancestorLevel! || 0, 0)
    : 0;
  const classes = useStyles({ isAncestor });
  return (
    <div
      className={classes.suggestion}
      data-testid="suggestItem"
      key={item.id}
      onMouseDown={() => onClick(item)}
    >
      {hasAncestor &&
        Array.from(Array(nbAncestors).keys()).map((_, index: number) => (
          <Box
            key={index}
            style={{
              marginLeft: !index ? -5 : item.level ? (item.level - 2) * 7 : 0,
            }}
          >
            {nbAncestors === index + 1 && <SubdirectoryArrowRight />}
          </Box>
        ))}
      <Typography className={classes.suggestionlabel} variant="body2">
        {item.title}
      </Typography>
    </div>
  );
};
