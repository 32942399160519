import {
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Grid, Typography, Stack, Box } from '@mui/material';
import {
  AutoAwesome,
  Cancel,
  Chat,
  CheckCircleOutline,
  Refresh,
  Title,
  Warning,
} from '@mui/icons-material';
import { Loader } from '@prismamedia/one-components';
import { useStyles } from './styles';
import { TextSuggestionMenu } from './TextSuggestionMenu';
import { BrandKey } from '../../__generated__/queries-web';
import { useOpenAiPrompts } from '../../utils/useOpenAiPrompts';
import { callOpenAI } from '../../utils/openAI';

interface TextSuggestionProps {
  anchorRef: HTMLDivElement | null;
  brandKey: BrandKey;
  className?: string;
  handleClose: () => void;
  partnerTitle: string | null;
  title: string;
  onConfirm: (suggestion: string) => void;
}

type MenuKeys = 'new' | 'edit' | 'confirm';

export interface MenuItem {
  icon: () => ReactNode;
  label: string;
  onClick: () => void;
  subMenuItems?: MenuItem[];
}

export type Menu = Record<MenuKeys, MenuItem[]>;

export const TextSuggestion: FC<TextSuggestionProps> = ({
  anchorRef,
  brandKey,
  handleClose,
  partnerTitle,
  title,
  onConfirm,
}) => {
  const classes = useStyles();
  const [isDisplay, setIsDisplay] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [menuKey, setMenuKey] = useState<'new' | 'edit' | 'confirm'>(
    partnerTitle ? 'edit' : 'new',
  );
  const [suggestion, setSuggestion] = useState<string>('');
  const [menuRef, setMenuRef] = useState(anchorRef);
  const promptRef = useRef(null);
  const { generatePrompt, paraphrasePrompt } = useOpenAiPrompts(brandKey);

  const handleClickBait = async (content: string) => {
    setIsLoading(true);
    const openAiResponse = callOpenAI(content);
    setIsLoading(false);
    return openAiResponse;
  };

  const onGenerate = useCallback(async (content: string) => {
    setIsDisplay(true);
    setMenuKey('confirm');
    const result = await handleClickBait(content);
    setSuggestion(result);
  }, []);

  const menu: Menu = useMemo(
    () => ({
      new: [
        {
          icon: () => <Title className={classes.icon} />,
          label: 'Générer',
          onClick: () => onGenerate(`${generatePrompt} ${title}`),
        },
      ],
      edit: [
        {
          icon: () => <Title />,
          label: 'Générer',
          onClick: () => onGenerate(`${generatePrompt} ${title}`),
        },
        {
          icon: () => <Chat />,
          label: 'Paraphraser',
          onClick: async () => {
            setIsDisplay(true);
            setMenuKey('confirm');
            const result = await handleClickBait(
              `${paraphrasePrompt} ${title}`,
            );
            setSuggestion(result);
          },
        },
      ],
      confirm: [
        {
          icon: () => <CheckCircleOutline />,
          label: 'Valider',
          onClick: () => {
            setIsDisplay(false);
            onConfirm(suggestion);
            handleClose();
          },
        },
        {
          icon: () => <Refresh />,
          label: 'Réessayer',
          onClick: async () => {
            const result = await handleClickBait(
              `${paraphrasePrompt} ${suggestion}`,
            );
            setSuggestion(result);
          },
        },
        {
          icon: () => <Cancel />,
          label: 'Annuler',
          onClick: () => {
            handleClose();
            setIsDisplay(false);
          },
        },
      ],
    }),
    [
      generatePrompt,
      paraphrasePrompt,
      title,
      suggestion,
      classes.icon,
      handleClose,
      onConfirm,
      onGenerate,
    ],
  );

  useEffect(() => {
    isDisplay ? setMenuRef(promptRef.current) : setMenuRef(anchorRef);
  }, [isDisplay, anchorRef]);

  return (
    <>
      {isDisplay && (
        <Grid container className={classes.wrapper} ref={promptRef}>
          <Grid item xs={12} pt={1}>
            <Stack direction="row" alignItems="center" px={1} py={0.5}>
              <AutoAwesome className={classes.icon} />
              {isLoading ? (
                <Box
                  sx={{
                    display: 'flex',
                    flex: 1,
                    justifyContent: 'flex-end',
                    paddingRight: '8px',
                  }}
                >
                  <Loader color="warning" thickness={5} size={20} />
                </Box>
              ) : (
                <Typography
                  color="inherit"
                  variant="subtitle1"
                  sx={{
                    fontWeight: 'bold',
                  }}
                  ml={1.5}
                >
                  {suggestion}
                </Typography>
              )}
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Stack
              direction="row"
              alignItems="center"
              className={classes.helper}
              px={1.5}
              py={0.75}
            >
              <Warning className={classes.smallIcon} />
              <Typography color="inherit" variant="caption" ml={1}>
                Les textes générés par l'IA peuvent être inexacts ou trompeurs.
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      )}
      {!isLoading && (
        <TextSuggestionMenu
          anchorRef={menuRef}
          handleClose={handleClose}
          menuItems={menu}
          menuKey={menuKey}
        />
      )}
    </>
  );
};
