import { format } from 'date-fns';
import fr from 'date-fns/locale/fr';

export const formatDate = (
  date: number | Date | string | undefined,
  dateFormat: string,
) => {
  if (!date) return undefined;
  if (typeof date === 'string') {
    // eslint-disable-next-line no-param-reassign
    date = new Date(date);
  }
  return format(date, dateFormat, { locale: fr });
};
