import { YoutubeSearchedFor } from '@mui/icons-material';
import {
  IconLabelButton,
  PluginButtonProps,
} from '@prismamedia/one-components';
import { EditorState, Modifier } from 'draft-js';
import { insertNewUnstyledBlock } from 'draftjs-utils';
import { FC, useState } from 'react';
import { useParams } from 'react-router-dom';
import { BrandKey } from '../../../../__generated__/queries-web';
import { useGlobalBrandKey } from '../../../../utils/globalState';
import { standardizeArticleUrl } from '../../../../utils/url';
import { InternalLinkModal } from '../../../InternalLinkModal';
import { InternalLink } from '../../../InternalLinkModal/InternalLink';

interface DisplayLinkInput {
  id: string;
  title: string | null;
  url: string;
  brandKey: BrandKey | undefined;
}

export const buildDraftLink = (
  editorState: EditorState,
  { id, title, url }: DisplayLinkInput,
  callBack?: (editorState: EditorState) => void,
) => {
  let newEditorState;

  const entityID = editorState
    .getCurrentContent()
    .createEntity('INTERNAL_LINK', 'MUTABLE', {
      children: title,
      id,
      url: standardizeArticleUrl(url),
    });

  const linkContentBlock = Modifier.insertText(
    editorState.getCurrentContent(),
    editorState.getSelection(),
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    title!,
    undefined,
    entityID.getLastCreatedEntityKey(),
  );

  newEditorState = EditorState.push(
    editorState,
    linkContentBlock,
    'insert-fragment',
  );

  // Insert new empty block below
  newEditorState = insertNewUnstyledBlock(newEditorState);

  if (!callBack) {
    return newEditorState;
  }

  callBack(newEditorState);
};

export const InternalLinkButton: FC<PluginButtonProps> = ({
  editorState,
  onChange,
}) => {
  const params: any = useParams();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [brandKey] = useGlobalBrandKey();

  const onClick = ({ id, title, url }: InternalLink) => {
    buildDraftLink(editorState, { id, title, url, brandKey }, onChange);
  };

  return (
    <>
      <InternalLinkModal
        open={isOpen}
        fullScreen={false}
        brandKey={params.brandKey}
        setIsOpen={setIsOpen}
        onLinkSelected={onClick}
      />
      <IconLabelButton label="Interne" onClick={() => setIsOpen(true)}>
        <YoutubeSearchedFor />
      </IconLabelButton>
    </>
  );
};
