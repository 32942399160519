import { Box, capitalize, Chip, Typography } from '@mui/material';
import { isNull } from 'lodash';
import React, { FC, ReactNode } from 'react';
import {
  BrandKey,
  CategoryStatus,
  GetArticle_article_articleGuides_category,
  GetArticle_article_articleTags_tag,
  GetSuggestedTagsList_suggestTags_items,
  SimilarArticleInputType,
  TagProviderName,
} from '../../../__generated__/queries-web';
import { isEventTag } from '../../../types/guards';
import { brandsOptions } from '../../../utils/brands';
import { pluralizeText } from '../../../utils/pluralizeText';
import { TAGS_AUTOCOMPLETE_LIST_HEADER } from '../constants';

// MEMOIZED SHARED COMPONENTS
interface AutocompleteListHeaderProps {
  label?: string;
  listLength: number;
  secondaryLabel?: ReactNode | string;
  showListLength?: boolean;
}
export const AutocompleteListHeader: FC<AutocompleteListHeaderProps> = React.memo(
  ({ label, listLength, secondaryLabel, showListLength = true }) => (
    <span style={{ alignItems: 'center', display: 'flex' }}>
      {label || TAGS_AUTOCOMPLETE_LIST_HEADER}

      {showListLength && (
        <Typography
          variant="body2"
          color="primary"
          style={{ marginLeft: '4px' }}
        >
          ({listLength})
        </Typography>
      )}

      {secondaryLabel && (
        <span style={{ marginLeft: 'auto' }}>{secondaryLabel}</span>
      )}
    </span>
  ),
);

// SHARED FUNCTIONS
export const getProvidersByBrandKey = (brandKey: BrandKey): TagProviderName[] =>
  brandsOptions[brandKey].tagProviders || [];

export const getSuggestedTagsArticleParams = (
  article?: SimilarArticleInputType,
) => ({
  title: article?.title || '',
  id: article?.id || '',
  format: article?.format,
  lead: JSON.stringify(article?.lead || ''),
  body: JSON.stringify(article?.body || ''),
});

interface TagsAutoCompleteListOpts {
  withActivationControl?: boolean;
  withArticleCountSort?: boolean;
  withSecondaryLabel?: boolean;
}

export const getTagsAutoCompleteList = (
  list: GetSuggestedTagsList_suggestTags_items[],
  selectedList: (
    | GetArticle_article_articleTags_tag
    | GetArticle_article_articleGuides_category
  )[],
  opts: TagsAutoCompleteListOpts = {},
) => {
  return list.map((tag) => {
    const isAlreadySelected =
      selectedList.findIndex(
        (item) =>
          (!isNull(item.id) && item.id === tag.id) || item.title === tag.title,
      ) > -1;

    const secondaryLabel = getSecondaryLabel(tag, opts);

    return {
      additionnals: tag,
      selected: isAlreadySelected,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      id: tag.id!,
      label: capitalize(tag.title),
      isInactive: tag.type === 'Tag' && tag.isEnabled === false,
      secondaryLabel,
    };
  });
};

const getSecondaryLabel = (
  { articleCount, type, status, isEnabled }: any,
  opts?: TagsAutoCompleteListOpts,
): string | ReactNode => (
  <div
    style={{
      alignItems: 'center',
      display: 'flex',
    }}
  >
    {opts?.withSecondaryLabel ? (
      isEventTag({ type }) ? (
        status === CategoryStatus.Draft ? (
          <Chip
            size="small"
            label="Brouillon"
            variant="outlined"
            style={{ marginLeft: '5px' }}
          />
        ) : null
      ) : (
        <Box
          sx={
            // We must check the strict equality because a tag can be active with isEnabled === null
            type === 'Tag' && isEnabled === false
              ? {
                  fontStyle: 'italic',
                  color: 'gray',
                }
              : {}
          }
        >
          {type} - {pluralizeText(articleCount || 0, 'article', true)}
        </Box>
      )
    ) : null}
  </div>
);
