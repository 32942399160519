import { FC } from 'react';
import { useStyles } from './styles';

interface CharCounterProps {
  value: string | null;
  maxLength: number;
}
export const CharCounter: FC<CharCounterProps> = ({
  value = '',
  maxLength,
}) => {
  const classes = useStyles();
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const isMax = value!.length >= maxLength;

  return (
    <p
      className={isMax ? classes.maxSize : undefined}
    >{`${value?.length}/${maxLength} caractères`}</p>
  );
};
