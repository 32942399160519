import { AppName, LoginPage } from '@prismamedia/one-components';
import { FC, useEffect } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { Crud } from '../../crud';
import { AttributeEditPage } from '../../crud/attributes/attributeEdit';
import { AttributesListPage } from '../../crud/attributes/attributesList';
import { QualifierEditPage } from '../../crud/qualifier/qualifierEdit';
import { QualifierListPage } from '../../crud/qualifier/qualifierList';
import { SegmentEditPage } from '../../crud/segment/segmentEdit';
import { SegmentListPage } from '../../crud/segment/segmentList';
import { UserEditPage } from '../../crud/user/userEdit';
import { UserListPage } from '../../crud/user/userList';
import { UserGroupEditPage } from '../../crud/userGroup/userGroupEdit';
import { UserGroupListPage } from '../../crud/userGroup/userGroupList';
import { UserRoleEditPage } from '../../crud/userRole/userRoleEdit';
import { UserRoleListPage } from '../../crud/userRole/userRoleList';
import {
  ArticleWrapper,
  DuplicateArticleWrapper,
} from '../../pages/ArticleEdit';
import { EditDuplicatedArticle } from '../../pages/ArticleEdit/DuplicateArticle';
import { ArticleIA } from '../../pages/ArticleGoodDealIA';
import { ArticleList, ArticleVideoList } from '../../pages/ArticleList';
import { Authors } from '../../pages/Authors';
import { CardEdit } from '../../pages/Card/CardEdit';
import { CardList } from '../../pages/Card/CardList';
import { DashboardPage } from '../../pages/Dashboard';
import { LiveEdit } from '../../pages/LiveEdit';
import { MobilePushWrapper } from '../../pages/MobilePush';
import { MobilePushList } from '../../pages/MobilePush/MobilePushList';
import { EditOrphanPage } from '../../pages/OrphanPage/Edit/EditOrphanPage';
import { NewOrphanPage } from '../../pages/OrphanPage/Edit/NewOrphanPage';
import { OrphanList } from '../../pages/OrphanPage/List';
import { Pages } from '../../pages/Pages';
import { ErrorPage } from '../../pages/shared/ErrorPage';
import {
  DuplicateSlideshowWrapper,
  EditSlideshowWrapper,
  NewSlideshowWrapper,
} from '../../pages/SlideshowEdit';
import { SlideshowGenerate } from '../../pages/SlideshowGenerate';
import { SlideshowList } from '../../pages/SlideshowList';
import { auth } from '../../utils/auth';
import { changeFavicon } from '../../utils/changeFavicon';
import { isPage, isSlideshow } from '../../utils/url';
import { PrivateRoute } from '../PrivateRoute';
import { paths } from './paths';
import { useLocationChange } from './useLocationChange';

export const replaceParams = (path: string, params: Record<string, string>) =>
  Object.entries(params)
    .reduce((prev, [key, value]) => prev.replace(`:${key}`, value), path)
    .replace(/\(.*\)/, '');

interface RouterProps {
  title: string;
}

export const Router: FC<RouterProps> = ({ title }) => {
  useLocationChange();
  const { pathname } = useLocation();

  useEffect(() => {
    const titlePrefix = 'ONE-';

    if (isSlideshow(pathname)) {
      // eslint-disable-next-line immutable/no-mutation
      document.title = `${titlePrefix}${AppName.Diapo}`;
      changeFavicon(AppName.Diapo);
    } else if (pathname.includes('mobilepush')) {
      // eslint-disable-next-line immutable/no-mutation
      document.title = `${titlePrefix}-Push`;
      changeFavicon();
    } else if (isPage(pathname)) {
      // eslint-disable-next-line immutable/no-mutation
      document.title = `${titlePrefix}${AppName.Page}`;
      changeFavicon(AppName.Page);
    } else {
      // eslint-disable-next-line immutable/no-mutation
      document.title = `${titlePrefix}${AppName.Web}`;
      changeFavicon();
    }
  }, [title, pathname]);

  return (
    <Switch>
      <Route
        exact
        path={paths.LOGIN}
        render={(props) => (
          <LoginPage
            {...props}
            homePath={paths.ARTICLE_LIST}
            loginPath={paths.LOGIN}
            auth={auth}
          />
        )}
      />
      <PrivateRoute
        exact
        path={paths.SLIDESHOW_LIST}
        component={SlideshowList}
      />
      <PrivateRoute
        exact
        path={paths.SLIDESHOW_EDIT}
        component={EditSlideshowWrapper}
      />
      <PrivateRoute
        exact
        path={paths.SLIDESHOW_NEW}
        component={NewSlideshowWrapper}
      />
      <PrivateRoute
        exact
        path={paths.SLIDESHOW_IA}
        component={SlideshowGenerate}
      />
      <PrivateRoute
        exact
        path={paths.SLIDESHOW_DUPLICATE}
        component={DuplicateSlideshowWrapper}
      />
      <PrivateRoute
        exact
        path={paths.ARTICLE_EDIT}
        component={ArticleWrapper}
      />
      <PrivateRoute exact path={paths.ARTICLE_IA} component={ArticleIA} />
      <PrivateRoute
        exact
        path={paths.DUPLICATE_FROM_STORY}
        component={EditDuplicatedArticle}
      />
      <PrivateRoute
        exact
        path={paths.ARTICLE_DUPLICATE}
        component={DuplicateArticleWrapper}
      />
      <PrivateRoute exact path={paths.ARTICLE_LIST} component={ArticleList} />
      <PrivateRoute
        exact
        path={paths.ARTICLE_VIDEO}
        component={ArticleVideoList}
      />
      <PrivateRoute
        exact
        path={paths.MOBILE_PUSH}
        component={MobilePushWrapper}
      />
      <PrivateRoute exact path={paths.CRUD} component={Crud} />
      <PrivateRoute exact path={paths.USER_LIST} component={UserListPage} />
      <PrivateRoute exact path={paths.USER_EDIT} component={UserEditPage} />
      <PrivateRoute
        exact
        path={paths.USER_ROLE_LIST}
        component={UserRoleListPage}
      />
      <PrivateRoute
        exact
        path={paths.USER_ROLE_EDIT}
        component={UserRoleEditPage}
      />
      <PrivateRoute
        exact
        path={paths.USER_GROUP_LIST}
        component={UserGroupListPage}
      />
      <PrivateRoute
        exact
        path={paths.USER_GROUP_EDIT}
        component={UserGroupEditPage}
      />
      <PrivateRoute
        exact
        path={paths.SEGMENT_LIST}
        component={SegmentListPage}
      />
      <PrivateRoute
        exact
        path={paths.SEGMENT_EDIT}
        component={SegmentEditPage}
      />
      <PrivateRoute
        exact
        path={paths.QUALIFIER_LIST}
        component={QualifierListPage}
      />
      <PrivateRoute
        exact
        path={paths.QUALIFIER_EDIT}
        component={QualifierEditPage}
      />
      <PrivateRoute
        exact
        path={paths.MOBILE_PUSH_LIST}
        component={MobilePushList}
      />
      <PrivateRoute exact path={paths.ARTICLE_LIVE} component={LiveEdit} />
      <PrivateRoute exact path={paths.AUTHORS} component={Authors} />
      <PrivateRoute exact path={paths.AUTHORS_EDIT} component={Authors} />
      <PrivateRoute exact path={paths.ERROR} component={ErrorPage} />
      <PrivateRoute exact path={paths.ORPHAN_LIST} component={OrphanList} />
      <PrivateRoute exact path={paths.ORPHAN_NEW} component={NewOrphanPage} />
      <PrivateRoute exact path={paths.ORPHAN_EDIT} component={EditOrphanPage} />

      {/**
       * CARDS
       */}
      <PrivateRoute exact path={paths.CARD_LIST} component={CardList} />
      <PrivateRoute exact path={paths.CARD_EDIT} component={CardEdit} />

      {/**
       * PAGES
       */}
      <PrivateRoute path={paths.PAGE_CREATE} component={Pages} />
      <PrivateRoute path={paths.PAGE_EDIT} component={Pages} />
      <PrivateRoute path={paths.PAGE_LIST} component={Pages} />

      <PrivateRoute
        exact
        path={paths.ATTRIBUTES_LIST}
        component={AttributesListPage}
      />
      <PrivateRoute
        exact
        path={paths.ATTRIBUTE_NEW}
        component={AttributeEditPage}
      />
      <PrivateRoute exact path={paths.DASHBOARD} component={DashboardPage} />
      <PrivateRoute component={() => <Redirect to={paths.ARTICLE_LIST} />} />
    </Switch>
  );
};
