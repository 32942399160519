import { isDailyMotionVideo, OneMedia } from '@prismamedia/one-components';
import { useEffect } from 'react';
import { useGlobalThumbnail } from './globalState';

export const useDailymotionThumbnail = (
  medias: OneMedia[],
  isVideo: boolean,
) => {
  const [thumbnail] = useGlobalThumbnail();

  useEffect(() => {
    if (isVideo && thumbnail) {
      medias.map((media) => {
        if (isDailyMotionVideo(media)) {
          // eslint-disable-next-line immutable/no-mutation
          media.iframely.links.thumbnail[0].href = thumbnail;
        }
      });
    }
  }, [isVideo, medias, thumbnail]);
};
