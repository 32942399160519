import { Box, Grid, Typography } from '@mui/material';
import {
  AutocompleteItem,
  AutocompleteMode,
  SearchBar,
  SearchBarVariant,
  SearchParams,
} from '@prismamedia/one-components';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { TagsProps } from '../../';
import {
  BrandKey,
  GetArticle_article_articleTags_tag,
  GetSuggestedTagsList_suggestTags,
  GetSuggestedTagsList_suggestTags_items,
} from '../../../../__generated__/queries-web';
import { useSearchEnabledTagsGetter } from '../../../../apollo/queries/getEnabledTags.web.graphql';
import { useSearchSuggestedTagsGetter } from '../../../../apollo/queries/getSuggestedTagsList.web.graphql';
import { TagItem } from '../../TagItem';
import { useEditableTag } from '../../useEditTag';
import {
  AutocompleteListHeader,
  getSuggestedTagsArticleParams,
  getTagsAutoCompleteList,
} from '../../utils';
import { useStyles as useSharedStyles } from '../styles';
import {
  ALLOWED_COLLECTION_PROVIDERS,
  ALLOWED_COLLECTION_TAG_TYPES,
  ARTICLE_ENHANCEMENT_COLLECTION_TITLE,
  COLLECTION_SEARCH_BAR_PLACEHOLDER,
  NO_COLLECTION_SELECTED_TITLE,
  TAGS_COLLECTION_SECTION_DATA_TESTID,
} from './constants';

interface TVCollectionsProps
  extends Pick<TagsProps, 'article' | 'onTagsChange'> {
  brandKey: BrandKey;
  field: string;
  suggestedTagList: GetSuggestedTagsList_suggestTags[];
  tags: GetArticle_article_articleTags_tag[];
}

const TVCollections: FC<TVCollectionsProps> = ({
  article,
  brandKey,
  field,
  onTagsChange,
  tags: selectedTags,
}) => {
  const isFirstRender = useRef(true);
  const searchTags = useSearchEnabledTagsGetter();
  const searchSuggestedTags = useSearchSuggestedTagsGetter();

  const {
    newTags,
    handlers: { handleAddTag, handleDeleteTag },
  } = useEditableTag<GetArticle_article_articleTags_tag>(
    selectedTags,
    brandKey,
  );

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search: '',
  });
  const [filteredSuggestedTagList, setFilteredSuggestedTagList] = useState<
    GetSuggestedTagsList_suggestTags_items[]
  >([]);

  const selectedTVCollectionList = selectedTags.filter(
    ({ type }) => ALLOWED_COLLECTION_TAG_TYPES.indexOf(type) > -1,
  );

  const sharedClasses = useSharedStyles({
    selectedTagListLength: selectedTVCollectionList.length,
  });

  const getFilteredTVCollectionList = useCallback(
    async (search: string): Promise<AutocompleteItem[] | undefined> => {
      setSearchParams({ search });

      if (!search.length) {
        // Get suggested TV collection tags
        return searchSuggestedTags({
          brandKey,
          article: getSuggestedTagsArticleParams(article),
          providers: ALLOWED_COLLECTION_PROVIDERS,
        }).then((data) => {
          const tagList = data?.items || [];
          setFilteredSuggestedTagList(tagList);
          return getTagsAutoCompleteList(tagList, selectedTVCollectionList);
        });
      }

      // Get TV collection tags based on field value
      return searchTags({
        providers: ALLOWED_COLLECTION_PROVIDERS,
        brandKey,
        search,
      }).then((sortedTagList = []) =>
        getTagsAutoCompleteList(sortedTagList, selectedTVCollectionList),
      );
    },
    [
      article,
      brandKey,
      searchTags,
      searchSuggestedTags,
      selectedTVCollectionList,
    ],
  );

  useEffect(() => {
    if (isFirstRender.current) {
      // eslint-disable-next-line immutable/no-mutation
      isFirstRender.current = false;
      return;
    }

    newTags && onTagsChange(newTags, field);
  }, [field, newTags, onTagsChange]);

  return (
    <Grid
      alignItems="flex-end"
      className={sharedClasses.wrapper}
      container
      data-testid={TAGS_COLLECTION_SECTION_DATA_TESTID}
      justifyContent="space-between"
    >
      <Grid item xs={4}>
        <Typography className={sharedClasses.subTitle} component="h3">
          {ARTICLE_ENHANCEMENT_COLLECTION_TITLE}{' '}
          <span>({selectedTVCollectionList.length})</span>
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <SearchBar
          autocomplete={{
            fetchList: getFilteredTVCollectionList,
            listHeader: !searchParams?.search?.length ? (
              <AutocompleteListHeader
                listLength={filteredSuggestedTagList.length}
              />
            ) : undefined,
            mode: AutocompleteMode.MULTIPLE,
            onSelect: ({ additionnals }) => {
              additionnals && handleAddTag(additionnals);
            },
          }}
          fetchListMinValueLength={0}
          placeholder={COLLECTION_SEARCH_BAR_PLACEHOLDER}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          variant={SearchBarVariant.SECONDARY}
        />
      </Grid>
      <Box className={sharedClasses.innerWrapper} component="section">
        {selectedTVCollectionList.map((program) => (
          <TagItem
            {...program}
            key={program.title}
            onDelete={handleDeleteTag}
          />
        ))}

        {!selectedTVCollectionList.length && (
          <Typography component="p">{NO_COLLECTION_SELECTED_TITLE}</Typography>
        )}
      </Box>
    </Grid>
  );
};

export { TVCollections };
