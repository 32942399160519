import { Clear, Headset, Search } from '@mui/icons-material';
import {
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Switch,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  AutocompleteItem,
  IconPosition,
  IframelyMedia,
  isPodcast,
  MEDIA_TYPES_LABEL,
  MultimediaList,
  MultimediaListProps,
  OneMedia,
  SearchBar,
  SearchBarVariant,
  SearchParams,
  ViewMode,
} from '@prismamedia/one-components';
import { flatMap } from 'lodash';
import {
  ChangeEvent,
  FC,
  KeyboardEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { embedImage } from '../../../utils/upload';
import {
  AUTOCOMPLETE_RESULT_PLACEHOLDER,
  PLAYLIST_LABEL_FORM,
  SEARCHBAR_PLACEHOLDER,
  TRACKING_LABEL_FORM,
} from './constants';
import { useStyles } from './styles';
import type {
  PodcastEpisodeType,
  PodcastItemSerialized,
  PodcastType,
} from './types';
import {
  abortRequestController,
  getEpisodesFromPodcast,
  getPodcastImageFromPodcastFeed,
  getPodcasts,
  PLAYER_URL,
  seriesRequestController,
  submitPodcast,
} from './utils';

const RSS_PATTERN = 'https://rss.art19.com/';
const ART19_PATTERN = 'https://art19.com/shows/';

interface PodcastProps<Item = PodcastItemSerialized>
  extends MultimediaListProps<Item> {
  medias: IframelyMedia[];
  setItems: (items: Item[]) => void;
  setMedias: (medias: IframelyMedia[]) => void;
}

export const PodcastSearch: FC<PodcastProps> = ({
  isDisabled,
  isLoading,
  itemToRender,
  items,
  medias,
  onItemClick,
  pluginType,
  selectedItems,
  setItems,
  setMedias,
  value = '',
}) => {
  const topInnerWrapperRef = useRef<HTMLDivElement>(null);

  const [searchParams, setSearchParams] = useState<SearchParams>({
    search: value,
  });
  const [searchText, setSearchText] = useState('');
  const [isPlaylist, setIsPlaylist] = useState<boolean>(false);
  const [isBusy, setIsBusy] = useState<boolean>(false);
  const [isPodcastEpisodesLoading, setIsPodcastEpisodesLoading] = useState<
    boolean
  >(false);
  const [podcastId, setPodcastId] = useState<string>('');
  const [podcasts, setPodcasts] = useState<PodcastType[]>([]);
  const [trackingValue, setTrackingValue] = useState<string>('');

  const searchValueLength = useMemo(() => searchParams.search?.length || 0, [
    searchParams.search?.length,
  ]);
  const shouldDisplayList = useMemo(
    () => Boolean(searchValueLength > 2 && podcastId),
    [podcastId, searchValueLength],
  );
  const podcastsEpisodesSelected = useMemo(
    () =>
      selectedItems.filter(
        (selectedItem) =>
          selectedItem.pluginType === MEDIA_TYPES_LABEL.PODCASTS &&
          selectedItem.podcastId === podcastId,
      ),
    [podcastId, selectedItems],
  );

  const classes = useStyles({
    topInnerWrapperHeight: topInnerWrapperRef?.current?.clientHeight,
  });

  const resetSearch = useCallback(
    (resetPodcastId?: boolean) => {
      setItems([]);

      if (resetPodcastId) {
        setPodcastId('');
      }
    },
    [setItems],
  );

  const fetchAutocompleteList = useCallback(
    async (search: string): Promise<AutocompleteItem[] | undefined> => {
      setSearchParams({ search });

      if (search.length < 3) {
        resetSearch(true);
        return;
      }

      try {
        const pods = await getPodcasts(search);
        setPodcasts(pods);

        return pods?.map(({ text, ...other }) => ({
          label: text,
          ...other,
        }));
      } catch (error) {}
    },
    [resetSearch],
  );

  const getItemToIframely = async ({
    feed,
    guid,
    image,
    playlistUrl,
    title,
  }: PodcastItemSerialized) => {
    const link = await submitPodcast(`${feed}&start=${guid}` || '', title, '0');
    const iframeLyCompliantLink = PLAYER_URL + link;
    const iframely = await embedImage(
      isPlaylist ? playlistUrl : iframeLyCompliantLink,
    );

    // Inserts thumbnail if it doesn't exists in iframely
    if (image && !iframely.links.thumbnail && !iframely.links.image) {
      // eslint-disable-next-line immutable/no-mutation
      iframely.links.thumbnail = [
        {
          href: image,
          rel: ['thumbnail'],
          type: 'image/jpeg',
        },
      ];
    }

    return iframely;
  };

  const fetchPodcastEpisodes = useCallback(
    async (podcast: PodcastType) => {
      const podcastImage = getPodcastImageFromPodcastFeed(podcast.feed);
      const podcastEpisodes = getEpisodesFromPodcast(podcast.id, searchText);

      Promise.all([podcastImage, podcastEpisodes])
        .then(([feed, episodes]: [Record<any, any>, PodcastEpisodeType[]]) => {
          const newItems = episodes.map((item) => ({
            ...item,
            feed: podcast?.feed,
            image: feed?.image?.url,
            playlistUrl: podcast.feed?.replace(RSS_PATTERN, ART19_PATTERN),
            pluginType,
            podcastId: podcast.id,
            text: podcast?.text,
            title: item?.text,
          }));

          setItems(newItems);
        })
        .finally(() => {
          setIsPodcastEpisodesLoading(false);
        });
    },
    [pluginType, setItems, searchText],
  );

  const fetchAllPodcastEpisodes = async () => {
    if (podcastId?.length) {
      setIsPodcastEpisodesLoading(true);
      resetSearch();
      const [podcast] = podcasts.filter(({ id }) => id === podcastId);
      await fetchPodcastEpisodes(podcast);
    }
  };

  /**
   * When we selecting a podcast from the autocomplete list,
   * we triggering a new search to found the associated podcasts episodes list
   */
  useEffect(() => {
    fetchAllPodcastEpisodes();
    // eslint-disable-next-line
  }, [podcastId]);

  // Multiple episodes can't be selected when playlist mode is active
  useEffect(() => {
    if (podcastsEpisodesSelected.length > 1) {
      setIsPlaylist(false);
    }
  }, [podcastsEpisodesSelected]);

  useEffect(() => {
    if (!shouldDisplayList) {
      setSearchText('');
    }
  }, [shouldDisplayList]);

  // We ensure that podcasts medias has embedded with right url depending on isPlaylist status
  useEffect(() => {
    const getNewItemToIframely = async () => {
      const podcastsEpisodesSelectedIds = flatMap(
        podcastsEpisodesSelected,
        ({ id }) => id,
      );

      const newMedias: IframelyMedia[] = await Promise.all(
        medias.map(async (media: IframelyMedia, index: number) => {
          const shouldRecomputeIframely =
            isPodcast({ iframely: media } as OneMedia) &&
            podcastsEpisodesSelectedIds.includes(selectedItems[index].id);

          if (shouldRecomputeIframely) {
            setIsBusy(true);

            const iframely = await getItemToIframely(selectedItems[index]);

            setIsBusy(false);

            return Promise.resolve(iframely);
          }
          return Promise.resolve(media);
        }),
      );

      setMedias(newMedias);
    };

    getNewItemToIframely();

    // eslint-disable-next-line
  }, [isPlaylist]);

  const handleChangeSearchText = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };

  const handleClickSearchButton = async () => {
    await fetchAllPodcastEpisodes();
  };

  const handleKeyDownSearchText = async (
    event: KeyboardEvent<HTMLInputElement>,
  ) => {
    if (event.key === 'Enter') {
      await fetchAllPodcastEpisodes();
    }
  };

  return (
    <div className={classes.dialogContent}>
      <div className={classes.topInnerWrapper} ref={topInnerWrapperRef}>
        <SearchBar
          autocomplete={{
            fetchList: fetchAutocompleteList,
            onSelect: ({ id }) => {
              setPodcastId(id);
            },
            ...(searchValueLength < 3 && {
              placeholder: AUTOCOMPLETE_RESULT_PLACEHOLDER,
            }),
          }}
          autoFocus={true}
          className={classes.input}
          iconPosition={IconPosition.RIGHT}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          onBlur={() => {
            abortRequestController(seriesRequestController);
          }}
          placeholder={SEARCHBAR_PLACEHOLDER}
          variant={SearchBarVariant.SECONDARY}
        />

        <FormGroup className={classes.subForm} row>
          <TextField
            fullWidth
            onChange={(event) => {
              setTrackingValue(event.target.value);
            }}
            placeholder={TRACKING_LABEL_FORM}
            value={trackingValue}
            variant="standard"
          />

          <FormControlLabel
            control={
              <Switch
                checked={isPlaylist}
                color="primary"
                disabled={
                  podcastsEpisodesSelected.length > 1 ||
                  (shouldDisplayList && items.length < 2)
                }
                onChange={(event) => {
                  setIsPlaylist(event.target.checked);
                }}
              />
            }
            label={PLAYLIST_LABEL_FORM}
          />
        </FormGroup>

        {shouldDisplayList && (
          <OutlinedInput
            value={searchText}
            onChange={handleChangeSearchText}
            onKeyDown={handleKeyDownSearchText}
            type="text"
            placeholder="Rechercher un épisode"
            sx={{
              marginTop: '20px',
              width: '100%',
              height: '50px',
              backgroundColor: 'white',
              '& .MuiOutlinedInput-notchedOutline': {
                borderColor: '#e0e0e0 !important',
              },
            }}
            endAdornment={
              <>
                <InputAdornment position="end">
                  <Tooltip
                    sx={{
                      marginRight: '6px',
                    }}
                    title="Effacer la recherche"
                  >
                    <IconButton onClick={() => setSearchText('')} edge="end">
                      <Clear />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Rechercher">
                    <IconButton onClick={handleClickSearchButton}>
                      <Search />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              </>
            }
          />
        )}
      </div>

      {shouldDisplayList ? (
        <MultimediaList
          className={classes.wrapperList}
          isBusy={isBusy}
          isDisabled={isDisabled}
          isLoading={isLoading || isPodcastEpisodesLoading}
          itemToRender={itemToRender}
          items={items}
          onItemClick={(rowItem: PodcastItemSerialized) => {
            onItemClick(rowItem, pluginType, () => getItemToIframely(rowItem));
          }}
          pluginType={pluginType}
          selectedItems={selectedItems}
          viewMode={ViewMode.LIST}
        />
      ) : (
        <div className={classes.wrapperListVoid}>
          <Headset />
        </div>
      )}
    </div>
  );
};
