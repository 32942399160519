import {
  IframelyMedia,
  ItemSerialized,
  MEDIA_TYPES_LABEL,
  MultimediaPlugin,
  PluginButtonProps,
  mediaFromIframely,
} from '@prismamedia/one-components';
import { EditorState } from 'draft-js';
import { insertDataBlock } from 'megadraft';
import { useCallback } from 'react';
import { useParams } from 'react-router-dom';

import { MetadataNameEnumType } from '../../../../__generated__/queries-photo';
import { useGetArticle } from '../../../../apollo/queries/articles.web.graphql';
import { useSuggestArticleQuery } from '../../../../apollo/queries/suggestArticles.web.graphql';
import { getMetadata } from '../../../../apollo/utils/getMetadata';
import { history } from '../../../../history';
import { useGetAssigment } from '../../../../pages/ArticleEdit/queries/getAssigment.topic.graphql';
import { useGetRawArticle } from '../../../../pages/ArticleEdit/queries/getSubjectQuery.topic.graphql';
import { assert } from '../../../../utils/assert';
import { AdvancedSearchParams } from '../../../../utils/formatArticleSearchInput';
import {
  MediaFormat,
  getFirstMedia,
  parseMedias,
} from '../../../../utils/media';
import { embedImage, uploadAndEmbedImageUrl } from '../../../../utils/upload';
import { isPage, standardizeArticleUrl } from '../../../../utils/url';
import { AdvancedSearch } from '../../../AppBar/AdvancedSearch';
import { baseRecipesURLs } from '../../../InternalLinkModal/useInternalLinksGetter';
import { PhotoCollapsesTypes } from '../../../MediaInput/FotoSearch/types';
import { getItemToIframely, getItemToRender } from '../../../MediaInput/utils';
import { buildDraftLink } from '../internalLink/InternalLinkButton';
import { searchByPluginType } from '../shared/searchArticleForMedia';
import { getAllowedMedias } from './utils';

export const MultimediaPluginButton: React.FC<PluginButtonProps> = ({
  editorState,
  onChange,
}) => {
  const { brandKey, id, rawArticleId, versionId } = useParams();
  const { data } = useGetArticle(id);
  const { data: rawArticleData } = useGetRawArticle(rawArticleId, versionId);
  const { suggestQuery } = useSuggestArticleQuery(data);

  const machineTagData = data?.article?.articleMachineTags?.find(
    (_machineTagdata) => {
      return _machineTagdata.machineTag.tag.startsWith('topicassignment:id');
    },
  );
  const { data: assignmentData } = useGetAssigment(
    machineTagData?.machineTag.id,
  );
  const subjectId = rawArticleId
    ? rawArticleData?.rawArticle?.assignment?.subject.id
    : assignmentData?.assignment?.subject.id;

  const onSearch = useCallback(
    async (searchParams: AdvancedSearchParams, opts, pluginType: string) =>
      await searchByPluginType({
        searchParams,
        pluginType,
        opts,
        brandKey,
        searchSchedule: isPage(history.location.pathname),
      }),
    [brandKey],
  );

  const onPhotosSave = useCallback(
    async (item: any) => {
      const isOnePhotos = [
        PhotoCollapsesTypes.ONE_PHOTOS,
        PhotoCollapsesTypes.ONE_STORY,
      ].includes(item.from);

      const iframely = isOnePhotos
        ? await uploadAndEmbedImageUrl(
            item.downloadUrl,
            brandKey,
            getMetadata(MetadataNameEnumType.Credit, item) || undefined,
          )
        : await uploadAndEmbedImageUrl(
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            (item.medium || item.large || item.small)!,
            brandKey,
            undefined,
          );

      if (iframely) {
        if (isOnePhotos) {
          // eslint-disable-next-line immutable/no-mutation
          iframely.meta.source = getMetadata(MetadataNameEnumType.Source, item);
          // eslint-disable-next-line immutable/no-mutation
          iframely.meta.credit = getMetadata(MetadataNameEnumType.Credit, item);
        } else {
          const source = item.provider.toUpperCase();
          // eslint-disable-next-line immutable/no-mutation
          iframely.meta.source = source;
          // eslint-disable-next-line immutable/no-mutation
          iframely.meta.credit = source;
        }
      }

      const newBlock = {
        type: 'media',
        ...mediaFromIframely(iframely),
      };

      return newBlock;
    },
    [brandKey],
  );

  const onSave = useCallback(
    (items: ItemSerialized[], medias: IframelyMedia[]) => {
      let newEditorState = editorState;

      items.map(async ({ pluginType, ...item }, index: number) => {
        switch (pluginType) {
          case MEDIA_TYPES_LABEL.PODCASTS: {
            const iframely = medias[index];
            const newBlock = { type: 'media', ...mediaFromIframely(iframely) };
            newEditorState = insertDataBlock(newEditorState, newBlock);
            break;
          }

          case MEDIA_TYPES_LABEL.RECIPES: {
            const url =
              baseRecipesURLs[brandKey] +
                assert(
                  item,
                  ({ recipeUrls }: typeof item) => recipeUrls[0].url?.path,
                ) || '';
            const newBlock = { id: item.id, title: item.title, url, brandKey };

            newEditorState = buildDraftLink(
              newEditorState,
              newBlock,
            ) as EditorState;
            break;
          }

          case MEDIA_TYPES_LABEL.VIDEOS: {
            const media = getFirstMedia(
              parseMedias(item.medias),
              MediaFormat.Video,
            );

            if (media) {
              const iframely = await embedImage(media.iframely.meta.canonical);
              const newBlock = {
                type: 'media',
                ...mediaFromIframely(iframely),
              };

              newEditorState = insertDataBlock(newEditorState, newBlock);
            }
            break;
          }

          case MEDIA_TYPES_LABEL.IMAGES: {
            const newBlock = await onPhotosSave(item);
            newEditorState = insertDataBlock(newEditorState, newBlock);
            break;
          }

          default: {
            const url = standardizeArticleUrl(item?.contextualizedUrl) || '';
            const newBlock = { id: item.id, title: item.title, url, brandKey };

            newEditorState = buildDraftLink(
              newEditorState,
              newBlock,
            ) as EditorState;
            break;
          }
        }

        onChange(newEditorState);
      });
    },
    [brandKey, editorState, onChange, onPhotosSave],
  );

  return (
    <MultimediaPlugin
      allowedMedias={getAllowedMedias(brandKey, {
        subjectId,
      })}
      customStyles={{ display: 'flex' }}
      itemToIframely={async (item, pluginType) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (await getItemToIframely(item, pluginType))!
      }
      itemToRender={(item, pluginType) =>
        getItemToRender(brandKey, item, pluginType)
      }
      onSave={onSave}
      searchFn={onSearch}
      advancedSearch={
        brandKey && {
          component: AdvancedSearch,
          props: { brandKey },
        }
      }
      suggestArticlesFn={suggestQuery}
    />
  );
};
