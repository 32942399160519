import { Theme } from '@mui/material';

type StyleArgs = ((...args: any) => any | Record<string, any>)[];

export function combineStyles(...styles: StyleArgs) {
  return function CombineStyles(theme: Theme) {
    const outStyles = styles.map((arg) => {
      if (typeof arg === 'function') {
        return arg(theme);
      }
      return arg;
    });

    // eslint-disable-next-line fp/no-mutating-assign
    return outStyles.reduce((acc, val) => Object.assign(acc, val));
  };
}
