import {
  LiveFragment,
  LivePostFragment,
} from '../../__generated__/queries-web';

export interface CurrentFieldData {
  title: string | null;
  body: string | null;
  creatorId?: string;
}

interface PostLiveEditState {
  currentData: CurrentFieldData;
  live: LiveFragment | null;
  posts: Partial<LivePostFragment>[];
  isSaveEnabled: boolean;
}

enum ActionTypeEnum {
  deleteLivePost = 'DELETE_LIVE_POST',
  updateLivePost = 'UPDATE_LIVE_POST',
  updateLivePostById = 'UPDATE_LIVE_POST_BY_ID',
  addLivePost = 'ADD_LIVE_POST',
  loadData = 'LOAD_DATA',
}

interface DeleteLivePost {
  type: ActionTypeEnum.deleteLivePost;
  id: string;
}

interface UpdateLivePost {
  type: ActionTypeEnum.updateLivePost;
  values: Partial<CurrentFieldData>;
  isSaveEnabled?: boolean;
}

interface UpdateLivePostById {
  type: ActionTypeEnum.updateLivePostById;
  values: Partial<CurrentFieldData>;
  id: string;
}

interface AddLivePost {
  type: ActionTypeEnum.addLivePost;
  posts: LivePostFragment[];
}

interface LoadData {
  type: ActionTypeEnum.loadData;
  posts?: Partial<LivePostFragment>[];
  live?: any;
}

export type ActionType =
  | AddLivePost
  | DeleteLivePost
  | UpdateLivePost
  | UpdateLivePostById
  | LoadData;

export const actions = {
  updateLivePost: (
    values: Partial<CurrentFieldData>,
    isSaveEnabled?: boolean,
  ): UpdateLivePost => ({
    type: ActionTypeEnum.updateLivePost,
    values,
    isSaveEnabled,
  }),
  updateLivePostById: (
    values: Partial<CurrentFieldData>,
    id: string,
  ): UpdateLivePostById => ({
    type: ActionTypeEnum.updateLivePostById,
    values,
    id,
  }),
  loadData: (data: Partial<LiveFragment>): LoadData => ({
    type: ActionTypeEnum.loadData,
    posts: data.posts,
    live: data,
  }),
  deleteLivePost: (id: string): DeleteLivePost => ({
    type: ActionTypeEnum.deleteLivePost,
    id,
  }),
  addLivePost: (posts: LivePostFragment[]): AddLivePost => ({
    type: ActionTypeEnum.addLivePost,
    posts,
  }),
};

export const initialState: PostLiveEditState = {
  currentData: {
    body: null,
    title: '',
  },
  live: null,
  posts: [],
  isSaveEnabled: false,
};

export function reducer(
  state: PostLiveEditState,
  action: ActionType,
): PostLiveEditState {
  const result: PostLiveEditState = { ...state };
  let targetedIndex;
  switch (action.type) {
    case ActionTypeEnum.updateLivePost:
      // eslint-disable-next-line immutable/no-mutation
      result.currentData = { ...result.currentData, ...action.values };
      if (action.isSaveEnabled !== undefined) {
        // eslint-disable-next-line immutable/no-mutation
        result.isSaveEnabled = action.isSaveEnabled;
      }
      break;
    case ActionTypeEnum.updateLivePostById:
      targetedIndex = state.posts.findIndex((post) => post.id === action.id);

      if (targetedIndex > -1) {
        // eslint-disable-next-line immutable/no-mutation
        result.posts[targetedIndex] = {
          ...result.posts[targetedIndex],
          ...action.values,
        };
      }
      break;
    case ActionTypeEnum.deleteLivePost:
      // eslint-disable-next-line immutable/no-mutation
      result.posts = state.posts.filter((post) => post.id !== action.id);
      break;
    case ActionTypeEnum.loadData:
      if (action.posts) {
        // eslint-disable-next-line immutable/no-mutation
        result.posts = [...action.posts];
      }
      if (action.live) {
        // eslint-disable-next-line immutable/no-mutation
        result.live = { ...action.live };
      }
      break;
    case ActionTypeEnum.addLivePost:
      // eslint-disable-next-line immutable/no-mutation
      result.posts = [...action.posts, ...result.posts];
      // eslint-disable-next-line immutable/no-mutation
      result.currentData.body = null;
      // eslint-disable-next-line immutable/no-mutation
      result.currentData.title = '';
      break;
    default:
      break;
  }
  return result;
}
