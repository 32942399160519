import { Description, Restaurant, Videocam } from '@mui/icons-material';
import {
  MEDIA_TYPES_LABEL,
  MultimediaPlugin,
} from '@prismamedia/one-components';
import { FC, useCallback, useMemo, useState } from 'react';
import { BrandKey } from '../../__generated__/queries-web';
import { AdvancedSearchParams } from '../../utils/formatArticleSearchInput';
import {
  InternalLink,
  InternalLinkType,
} from '../InternalLinkModal/InternalLink';
import { useInternalLinksGetter } from '../InternalLinkModal/useInternalLinksGetter';
import { getItemToIframely, getItemToRender } from '../MediaInput/utils';

interface InternalLinkMultimediaProps {
  open: boolean;
  setIsOpen: (open: boolean) => void;
  brandKey: BrandKey | null;
  onLinkSelected: (link: InternalLink) => void;
  multimediaInitialType: MEDIA_TYPES_LABEL;
}

export const InternalLinkMultimedia: FC<InternalLinkMultimediaProps> = ({
  open,
  setIsOpen,
  brandKey,
  onLinkSelected,
  multimediaInitialType,
}) => {
  const getInternalLinks = useInternalLinksGetter();
  const [items, setItems] = useState<InternalLink[]>([]);
  const isRecipe = brandKey === BrandKey.FAC || brandKey === BrandKey.CAC;
  const allowedMedias = useMemo(
    () => [
      {
        type: MEDIA_TYPES_LABEL.ARTICLES,
        Icon: Description,
        label: 'Article',
      },
      {
        type: MEDIA_TYPES_LABEL.VIDEOS,
        Icon: Videocam,
        label: 'Vidéo',
      },
      ...(isRecipe
        ? [
            {
              type: MEDIA_TYPES_LABEL.RECIPES,
              Icon: Restaurant,
              label: 'Recette',
            },
          ]
        : []),
    ],
    [isRecipe],
  );

  const onSearch = useCallback(
    async (searchParams: AdvancedSearchParams, pluginType: string) => {
      const { search } = searchParams;

      return getInternalLinks(
        pluginType === MEDIA_TYPES_LABEL.ARTICLES
          ? InternalLinkType.ARTICLE
          : pluginType === MEDIA_TYPES_LABEL.RECIPES
          ? InternalLinkType.RECIPE
          : InternalLinkType.VIDEO,
        search || '',
        brandKey as BrandKey,
      );
    },
    [brandKey, getInternalLinks],
  );

  if (!open) {
    return null;
  }

  return (
    <MultimediaPlugin
      initialTypeSelected={multimediaInitialType}
      allowedMedias={allowedMedias}
      defaultActions={false}
      itemToIframely={async (item: any) =>
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        (await getItemToIframely(item, MEDIA_TYPES_LABEL.ARTICLES))!
      }
      itemToRender={(item: any, pluginType?: string) =>
        getItemToRender(
          brandKey as BrandKey,
          item,
          pluginType || MEDIA_TYPES_LABEL.ARTICLES,
        )
      }
      onClick={async (item: any) => {
        if (!items.includes(item)) {
          setItems((prev) => [...prev, item]);
        } else {
          const index = items.indexOf(item);
          if (index > -1) {
            // eslint-disable-next-line fp/no-mutating-methods
            items.splice(index, 1);
            setItems([...items]);
          }
        }
      }}
      onClose={() => {
        setIsOpen(false);
        setItems([]);
      }}
      onSave={() => {
        items.map(onLinkSelected);
        setItems([]);
      }}
      searchFn={async (search, _, pluginType) => onSearch(search, pluginType)}
    />
  );
};
