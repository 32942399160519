import { Delete, DragIndicator } from '@mui/icons-material';
import { Box, IconButton, Typography, capitalize } from '@mui/material';
import { Fragment, forwardRef, useMemo } from 'react';
import { useMountedState } from 'react-use';
import type { AdditionalSortableItemProps } from '../../../../../../../../components/DragAndDrop/SortableItem';
import { Card, CardSize } from '../../../../../../components/Card';
import type { CategoryFormSectionBoardContent } from '../../types';
import { useStyles } from './styles';
import { getBoardContentMedia, getBoardContentTypeLabel } from './utils';

export const ContentCard = forwardRef<
  HTMLButtonElement,
  AdditionalSortableItemProps &
    CategoryFormSectionBoardContent & {
      onDeleteCard: (contentId: string) => void;
    }
>(
  (
    {
      attributes,
      onDeleteCard,
      isDragging,
      isSortable,
      listeners,
      order,
      type,
      ...content
    },
    ref,
  ) => {
    const isMounted = useMountedState();
    const sortableProps = { attributes, isDragging, listeners, isSortable };
    const classes = useStyles();

    const contentType = useMemo(
      () => capitalize(getBoardContentTypeLabel(type)),
      [type],
    );

    const contentMedia = useMemo(() => getBoardContentMedia(type, content), [
      type,
      content,
    ]);

    return !isMounted() ? (
      <Fragment />
    ) : (
      <div className={classes.wrapper}>
        {sortableProps.isSortable && (
          <Typography variant="caption">{order + 1}</Typography>
        )}

        <Box component="section" id={content.id} ref={ref}>
          <Card
            createdAt={content.createdAt}
            format={content.format}
            id={content.id}
            media={contentMedia}
            size={CardSize.MEDIUM}
            title={content.title}
          >
            <Box className={classes.cardRightSide}>
              <IconButton onClick={() => onDeleteCard(content.id)}>
                <Delete />
              </IconButton>

              {/* Board content type label */}
              <Typography variant="caption">{contentType}</Typography>

              {/* Drag button */}
              <IconButton
                className={classes.dragIconButton}
                disableRipple
                size="small"
                {...sortableProps.attributes}
                {...sortableProps.listeners}
              >
                <DragIndicator fontSize="small" />
              </IconButton>
            </Box>
          </Card>
        </Box>
      </div>
    );
  },
);

// eslint-disable-next-line immutable/no-mutation
ContentCard.displayName = 'ContentCard';
