import {
  ContentBlock,
  ContentState,
  EditorState,
  convertToRaw,
  genKey,
} from 'draft-js';
import { Map } from 'immutable';
import {
  DEFAULT_CELL_CONTENT_STATE,
  TABLE_CONTENT_STATE_DATA_TYPE,
} from '../constants';
import type { TablePluginData, TableShape, TableShapeCol } from '../types';
import type { TableDialogState } from './useEditDialogTable';
import { TableDialogActionsTypes } from './useEditDialogTable';

const removeBlockFromBlockMap = (
  editorState: EditorState,
  blockKey: string,
) => {
  const contentState = editorState.getCurrentContent();
  const blockMap = contentState.getBlockMap();
  const newBlockMap = blockMap.remove(blockKey);
  const newContentState = contentState.merge({
    blockMap: newBlockMap,
  }) as ContentState;
  const newEditorState = EditorState.push(
    editorState,
    newContentState,
    'remove-range',
  );

  return newEditorState;
};

const addBlockFromBlockMap = (
  editorState: EditorState,
  blockKey: string,
  action: TableDialogActionsTypes,
) => {
  const contentState = editorState.getCurrentContent();
  const block = contentState.getBlockForKey(blockKey);
  const blockDataPosition = block.getData().get('position');

  const newBlockPosition = [
    blockDataPosition[0] + (action === TableDialogActionsTypes.ADD_ROW ? 1 : 0),
    blockDataPosition[1] + (action === TableDialogActionsTypes.ADD_COL ? 1 : 0),
  ];

  const newBlock = new ContentBlock({
    key: genKey(),
    type: TABLE_CONTENT_STATE_DATA_TYPE,
    text: '',
    data: Map({
      cellContentState: DEFAULT_CELL_CONTENT_STATE,
      position: newBlockPosition,
    }),
  });
  const newBlockMap = contentState
    .getBlockMap()
    .set(newBlock.get('key'), newBlock);
  const newContentState = ContentState.createFromBlockArray(
    newBlockMap.toArray(),
  ) as ContentState;
  const newEditorState = EditorState.push(
    editorState,
    newContentState,
    'move-block',
  );

  return newEditorState;
};

const buildShape = (tableEditorContentState: ContentState) => {
  const { blocks } = convertToRaw(tableEditorContentState);
  const shape: TableShape = [];

  // eslint-disable-next-line fp/no-loops
  for (let i = 0; i < blocks.length; i++) {
    const block = blocks[i];

    if (block?.data && block.type === TABLE_CONTENT_STATE_DATA_TYPE) {
      const blockKey = block.key;
      const blockPosition = (block.data as any).position;

      // eslint-disable-next-line immutable/no-mutation
      shape[blockPosition[0]] = shape[blockPosition[0]] || [];
      // eslint-disable-next-line immutable/no-mutation
      shape[blockPosition[0]][blockPosition[1]] = { key: blockKey };
    }
  }

  return shape;
};

const getEditorStateWithNewStructure = (
  dialogTablePluginData: TablePluginData<EditorState>,
  tableDialogState: TableDialogState,
  action: TableDialogActionsTypes,
) => {
  let newTablePluginState = dialogTablePluginData.tableContentState;

  /**
   * The computed position is based on the last row and last col indexes
   * We adding any cell :
   *   - From right for the col
   *   - From bottom for the row
   * NEXT EVOLUTION: Getting a dynamic position (With click user interaction) from where any col/row can be injected
   */
  if (
    action === TableDialogActionsTypes.DELETE_COL ||
    action === TableDialogActionsTypes.DELETE_ROW
  ) {
    const {
      cols: { selection: colsSelection },
      rows: { selection: rowsSelection },
    } = tableDialogState;

    const selection =
      action === TableDialogActionsTypes.DELETE_COL
        ? colsSelection
        : rowsSelection;

    // eslint-disable-next-line fp/no-loops
    for (let i = 0; i < selection.length; i++) {
      const blockKey = selection[i].key;

      newTablePluginState = removeBlockFromBlockMap(
        newTablePluginState,
        blockKey,
      );
    }
  } else if (
    action === TableDialogActionsTypes.ADD_COL ||
    action === TableDialogActionsTypes.ADD_ROW
  ) {
    const {
      cols: { selection: colsSelection },
      rows: { selection: rowsSelection },
    } = tableDialogState;

    const selection =
      action === TableDialogActionsTypes.ADD_COL
        ? colsSelection
        : rowsSelection;

    // eslint-disable-next-line fp/no-loops
    for (let i = 0; i < selection.length; i++) {
      const blockKey = selection[i].key;
      newTablePluginState = addBlockFromBlockMap(
        newTablePluginState,
        blockKey,
        action,
      );
    }
  }

  return newTablePluginState;
};

const getColsSelection = (shape: TableShape): TableShapeCol[] =>
  shape.length
    ? shape.reduce((acc, curr) => [...acc, curr[curr.length - 1]], [])
    : [];

const getRowsSelection = (shape: TableShape): TableShapeCol[] =>
  shape.length ? shape[shape.length - 1] : [];

const getColsRowsQuantity = (
  action: TableDialogActionsTypes,
  tableDialogState: TableDialogState,
) => {
  switch (action) {
    case TableDialogActionsTypes.ADD_COL:
      return tableDialogState.cols.quantity + 1;

    case TableDialogActionsTypes.ADD_ROW:
      return tableDialogState.rows.quantity + 1;

    case TableDialogActionsTypes.DELETE_COL:
      return tableDialogState.cols.quantity - 1;

    case TableDialogActionsTypes.DELETE_ROW:
      return tableDialogState.rows.quantity - 1;

    default:
      return 0;
  }
};

export {
  buildShape,
  getColsRowsQuantity,
  getColsSelection,
  getEditorStateWithNewStructure,
  getRowsSelection,
};
