import { Box, Grid, Typography } from '@mui/material';
import {
  AutocompleteItem,
  AutocompleteMode,
  SearchBar,
  SearchBarVariant,
  SearchParams,
} from '@prismamedia/one-components';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { TagsProps } from '../../';
import {
  BrandKey,
  CategoryFormat,
  GetArticle_article_articleEvents_category,
} from '../../../../__generated__/queries-web';
import { searchEvents as getEventsFromBrandCategories } from '../../../../apollo/queries/getSearchEventsList';
import { useBrandCategoriesGetter } from '../../../../pages/SlideshowEdit/CategorySelector/getBrandCategories.web.graphql';
import { SuggestItemType } from '../../../Suggest/types';
import { TagItem } from '../../TagItem';
import { useEditableTag } from '../../useEditTag';
import { AutocompleteListHeader, getTagsAutoCompleteList } from '../../utils';
import { useStyles as useSharedStyles } from '../styles';
import {
  ARTICLE_ENHANCEMENT_EVENTS_TITLE,
  EVENT_AUTOCOMPLETE_LIST_HEADER_LABEL,
  EVENT_SEARCH_BAR_PLACEHOLDER,
  NO_ARTICLE_EVENT_SELECTED_TITLE,
} from './constants';

interface EventsProps extends Pick<TagsProps, 'onTagsChange'> {
  brandKey: BrandKey;
  field: string;
  tags: GetArticle_article_articleEvents_category[];
}

export const Events: FC<EventsProps> = ({
  brandKey,
  field,
  onTagsChange,
  tags: selectedTagList,
}) => {
  const isFirstRender = useRef(true);
  const {
    newTags,
    handlers: { handleAddTag, handleDeleteTag },
  } = useEditableTag<GetArticle_article_articleEvents_category>(
    selectedTagList,
    brandKey,
  );

  const searchBrandCategoriesEvents = useBrandCategoriesGetter();
  const sharedClasses = useSharedStyles({
    selectedTagListLength: selectedTagList.length,
  });

  const [eventsTree, setEventsTree] = useState<SuggestItemType>();
  const [filteredEventListLength, setFilteredEventListLength] = useState<
    number | undefined
  >();
  const [searchParams, setSearchParams] = useState<SearchParams>({
    search: '',
  });

  const getFilteredEventList = useCallback(
    (search: string): Promise<AutocompleteItem[] | undefined> => {
      setSearchParams({ search });

      return new Promise((resolve) => {
        if (eventsTree) {
          const eventList = getEventsFromBrandCategories(eventsTree, search)
            .items;

          const filteredEventList = getTagsAutoCompleteList(
            eventList,
            selectedTagList,
            { withSecondaryLabel: true },
          );

          setFilteredEventListLength(filteredEventList.length);

          return resolve(filteredEventList);
        }

        return resolve(undefined);
      });
    },
    [eventsTree, selectedTagList],
  );

  useEffect(() => {
    const fetchEventList = async () => {
      const {
        data: { categories },
      } = await searchBrandCategoriesEvents({
        brandKey: brandKey || BrandKey.CAP,
        format: CategoryFormat.Event,
      });

      if (categories?.length) {
        setEventsTree(categories[0]);
      }
    };

    fetchEventList();
  }, [brandKey, searchBrandCategoriesEvents]);

  useEffect(() => {
    if (isFirstRender.current) {
      // eslint-disable-next-line immutable/no-mutation
      isFirstRender.current = false;
      return;
    }

    newTags && onTagsChange(newTags, field);
  }, [field, newTags, onTagsChange]);

  return (
    <Grid
      alignItems="flex-end"
      className={sharedClasses.wrapper}
      container
      justifyContent="space-between"
    >
      <Grid item xs={4}>
        <Typography className={sharedClasses.subTitle} component="h3">
          {ARTICLE_ENHANCEMENT_EVENTS_TITLE}{' '}
          <span>({selectedTagList.length})</span>
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <SearchBar
          autocomplete={{
            fetchList: getFilteredEventList,
            ...(!searchParams.search?.length && {
              listHeader: (
                <AutocompleteListHeader
                  label={EVENT_AUTOCOMPLETE_LIST_HEADER_LABEL}
                  listLength={filteredEventListLength || 0}
                />
              ),
            }),
            mode: AutocompleteMode.MULTIPLE,
            onSelect: ({ additionnals }) => {
              additionnals && handleAddTag(additionnals);
            },
          }}
          fetchListMinValueLength={0}
          placeholder={EVENT_SEARCH_BAR_PLACEHOLDER}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          variant={SearchBarVariant.SECONDARY}
        />
      </Grid>
      <Box className={sharedClasses.innerWrapper} component="section">
        {selectedTagList.map((event) => (
          <TagItem
            {...event}
            key={event.id || event.title}
            onDelete={handleDeleteTag}
            type="Event"
          />
        ))}

        {!selectedTagList.length && (
          <Typography component="p">
            {NO_ARTICLE_EVENT_SELECTED_TITLE}
          </Typography>
        )}
      </Box>
    </Grid>
  );
};
