import { AutocompleteItem } from '@prismamedia/one-components';
import { isTextMatchWithQuery } from '../../../../../../../apollo/queries/getSearchEventsList';
import type { Category } from '../../../../../types';
import { AutoCompleteItemLabel } from './AutoCompleteItemLabel';

type GetBrandCategoriesAutoCompleteList = (
  activeCategory: Category | null,
  categories: Category[],
  search: string,
  accumulatorData?: {
    parentData?: { idList: string[]; titleList: string[] };
    result: AutocompleteItem[];
  },
) => AutocompleteItem[];
export const getBrandCategoriesAutoCompleteList: GetBrandCategoriesAutoCompleteList = (
  activeCategory,
  categories,
  search,
  { parentData, result } = { result: [] },
): AutocompleteItem[] => {
  categories.forEach((category) => {
    const idList = [...(parentData?.idList || []), category.id];
    const titleList = [...(parentData?.titleList || []), category.title];

    /** We only push items based on the following rule :
     * - The searchbar text has to match with the API result
     *  (In futur, this filter'll be done from the query filter field [OR])
     */
    const canAddItem = !search || isTextMatchWithQuery(search, category.title);

    if (canAddItem) {
      const hasChildren = !!category.children?.length;

      // eslint-disable-next-line fp/no-mutating-methods
      result.push({
        additionnals: {
          hasChildren,
          idList,
          labelBis: category.title,
          path: category.path || '',
        },
        id: category.id,
        label: (
          <AutoCompleteItemLabel
            hasChildren={hasChildren}
            search={search}
            titleList={titleList}
          />
        ),
        selected: idList.at(-1) === activeCategory?.id,
      });
    }

    if (category.children?.length) {
      getBrandCategoriesAutoCompleteList(
        activeCategory,
        category.children as Category[],
        search,
        { parentData: { idList, titleList }, result },
      );
    }
  });

  return result;
};
