import { AddCircleOutline, CancelRounded } from '@mui/icons-material';
import {
  Box,
  FormControl,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  SxProps,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { grey } from '@mui/material/colors';
import { FC } from 'react';
import { AffilizzProductType } from '../../../../__generated__/queries-web';
import { ProductComplementaryInfos, ProductInfos } from './types';

export interface AffilizzDetailsProps {
  onChange: (details: ProductComplementaryInfos) => void;
  product: ProductInfos | null;
  details: ProductComplementaryInfos;
}

type CommentType = 'positivePoints' | 'negativePoints';

export const AffilizzDetails: FC<AffilizzDetailsProps> = ({
  onChange,
  product,
  details,
}) => {
  const theme = useTheme();

  if (!product) {
    return null;
  }

  const handleAddPoint = (type: CommentType) => {
    const len = details[type].length;

    if (details[type][len - 1].trim() === '') {
      return;
    }

    onChange({
      ...details,
      [type]: [...details[type], ''],
    });
  };

  const handleDeletePoint = (type: CommentType, index: number) => {
    if (details[type].length === 1) {
      return;
    }

    const result = details[type].filter((_, i) => i !== index);

    onChange({
      ...details,
      [type]: result,
    });
  };

  const handleCommentChange = (
    type: CommentType,
    value: string,
    index: number,
  ) => {
    // eslint-disable-next-line immutable/no-mutation
    details[type][index] = value;

    onChange({
      ...details,
      [type]: details[type],
    });
  };

  const handleRatingChange = (value: string) => {
    if (value !== '' && !/^([0-9]{1}|10)$/.test(value)) {
      return;
    }

    onChange({
      ...details,
      rating: value,
    });
  };

  const handleTitleChange = (value: string) => {
    onChange({ ...details, title: value });
  };

  const ratingEndAdornementStyles: SxProps = {
    position: 'absolute',
    transform: 'translateY(-50%)',
    top: '50%',
    right: 14,
    font: 'inhertit',
    color: grey[700],
  };

  const inputProps = {
    disabled: product.meta.type === AffilizzProductType.CatalogProduct,
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Box>
        <Stack direction="row" sx={{ mt: 4 }}>
          <FormControl sx={{ width: '100%', mr: 2 }} variant="outlined">
            <InputLabel htmlFor="outlined-adornment-search">Titre</InputLabel>
            <OutlinedInput
              value={details.title || product.name}
              onChange={(e) => handleTitleChange(e.target.value)}
              id="outlined-adornment-search"
              type="text"
              label="Titre"
              inputProps={inputProps}
            />
          </FormControl>
          {product.meta.type === AffilizzProductType.Product && (
            <FormControl
              sx={{ width: '96px', flexShrink: 1 }}
              variant="outlined"
            >
              <Typography component="span" sx={ratingEndAdornementStyles}>
                / 10
              </Typography>
              <TextField
                value={details.rating}
                onChange={(e) => handleRatingChange(e.target.value)}
                id="affilizz-rating"
                type="text"
                label="Note"
                autoComplete="off"
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  ...inputProps,
                  pattern: '/^([0-9]{1}|10)$',
                }}
              />
            </FormControl>
          )}
        </Stack>
        <Box sx={{ mt: 4 }}>
          <Typography variant="body2">Arguments (facultatifs)</Typography>
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.success.light,
                fontWeight: '500',
                mb: 1,
              }}
            >
              Positif
            </Typography>
            {details.positivePoints.map((positivePoint, i) => (
              <Stack key={`positivePoint-${i}`} direction="row" sx={{ mb: 4 }}>
                <FormControl sx={{ width: '89%' }} variant="outlined">
                  <InputLabel htmlFor={`positivePoint-${i}`}>
                    Commentaire
                  </InputLabel>
                  <OutlinedInput
                    value={positivePoint}
                    onKeyUp={(e) =>
                      e.key === 'Enter' && handleAddPoint('positivePoints')
                    }
                    onChange={(e) =>
                      handleCommentChange('positivePoints', e.target.value, i)
                    }
                    id={`positivePoint-${i}`}
                    type="text"
                    label="Point positif"
                    inputProps={inputProps}
                  />
                </FormControl>
                <IconButton onClick={() => handleAddPoint('positivePoints')}>
                  <AddCircleOutline />
                </IconButton>
                {details.positivePoints.length > 1 && (
                  <IconButton
                    onClick={() => handleDeletePoint('positivePoints', i)}
                  >
                    <CancelRounded />
                  </IconButton>
                )}
              </Stack>
            ))}
          </Box>
          <Box sx={{ mt: 2 }}>
            <Typography
              variant="body2"
              sx={{ color: theme.palette.error.main, fontWeight: '500', mb: 1 }}
            >
              Négatif
            </Typography>
            {details.negativePoints.map((negativePoint, i) => (
              <Stack key={`negativePoint-${i}`} direction="row" sx={{ mb: 4 }}>
                <FormControl sx={{ width: '89%' }} variant="outlined">
                  <InputLabel htmlFor={`negativePoint-${i}`}>
                    Commentaire
                  </InputLabel>
                  <OutlinedInput
                    onKeyUp={(e) =>
                      e.key === 'Enter' && handleAddPoint('negativePoints')
                    }
                    onChange={(e) =>
                      handleCommentChange('negativePoints', e.target.value, i)
                    }
                    value={negativePoint}
                    id={`negativePoint-${i}`}
                    type="text"
                    label="Point positif"
                    inputProps={inputProps}
                  />
                </FormControl>
                <IconButton onClick={() => handleAddPoint('negativePoints')}>
                  <AddCircleOutline />
                </IconButton>
                {details.negativePoints.length > 1 && (
                  <IconButton
                    onClick={() => handleDeletePoint('negativePoints', i)}
                  >
                    <CancelRounded />
                  </IconButton>
                )}
              </Stack>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
