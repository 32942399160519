import {
  ItemSerialized,
  NotificationTypeEnum,
  useNotification,
  useOnMount,
} from '@prismamedia/one-components';
import { sortBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMountedState } from 'react-use';
import { useArticlesGetter } from '../../../../../../../apollo/queries/articles.web.graphql';
import { useRecipesGetter } from '../../../../../../../apollo/queries/recipes.recipe.graphql';
import type { DragAndDropItem } from '../../../../../../../components/DragAndDrop';
import {
  CategoryFormSectionBoardContent,
  CategoryFormSectionContentType,
} from '../types';
import { useEditSection } from '../useEditSection';
import type { BoardProps } from './';
import { getBoardContentType } from './ContentCard/utils';

let timeoutId: ReturnType<typeof setTimeout>;

interface Handlers {
  handleAddContent: { (items: ItemSerialized[]): void };
  handleDeleteContent: { (contentId: string): void };
  handleReorderContent: { (contents: DragAndDropItem[]): void };
}

interface UseEditBoardProps {
  content: CategoryFormSectionBoardContent[];
  handlers: Handlers;
  isLoading: boolean;
}

export const useEditBoard = (sectionProps: BoardProps): UseEditBoardProps => {
  const { handlers: sectionHandlers } = useEditSection(sectionProps.id);

  const isMounted = useMountedState();
  const { pushNotification } = useNotification();
  const searchArticles = useArticlesGetter();
  const searchRecipes = useRecipesGetter();

  const [isLoading, setIsLoading] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const sectionContent = useMemo(() => sectionProps.content!, [
    sectionProps.content,
  ]);

  const handlers = useMemo(
    (): Handlers => ({
      handleAddContent: (items) => {
        const newContent: CategoryFormSectionBoardContent[] = [];

        items.forEach((item, index) => {
          const isItemAlreadyExists = newContent.some(
            ({ id }) => id === item.id,
          );

          if (!isItemAlreadyExists) {
            // eslint-disable-next-line fp/no-mutating-methods
            newContent.push({
              ...item,
              createdAt: new Date().toISOString(), // Creation date of the section card
              order: index + 1,
              type: getBoardContentType(item.pluginType),
            });

            return;
          }

          pushNotification({
            type: NotificationTypeEnum.error,
            message: `The content: "${
              items.at(0)?.title
            }" has been already added.`,
          });
        });

        sectionHandlers.handleSectionChange({
          fieldName: 'content',
          value: [...newContent, ...sectionContent].map((item, index) => ({
            ...item,
            order: index,
          })),
        });
      },
      handleDeleteContent: (contentId: string) => {
        const filterContent = sectionContent.filter(
          ({ id }) => id !== contentId,
        );
        const newContent = filterContent.map((item, index) => ({
          ...item,
          order: index,
        }));
        sectionHandlers.handleSectionChange({
          fieldName: 'content',
          value: newContent,
        });
      },
      handleReorderContent: (ordoredContents) => {
        const newContent = ordoredContents.map((item, index) => ({
          ...item,
          order: index,
        }));

        sectionHandlers.handleSectionChange({
          fieldName: 'content',
          value: newContent,
        });
      },
    }),
    [pushNotification, sectionContent, sectionHandlers],
  );

  const fetchContent = useCallback(async () => {
    const articlesIdList = sectionContent
      .filter(
        (item) =>
          item.type === CategoryFormSectionContentType.ARTICLE ||
          item.type === CategoryFormSectionContentType.SLIDESHOW,
      )
      .map(({ id }) => id);

    const recipesIdList = sectionContent
      .filter((item) => item.type === CategoryFormSectionContentType.RECIPE)
      .map(({ id }) => id);

    setIsLoading(Boolean(articlesIdList.length || recipesIdList.length));

    const articlesPromise = await searchArticles(
      {
        first: articlesIdList.length,
        where: { id_in: articlesIdList },
      },
      !articlesIdList.length,
    );

    const recipesPromise = await searchRecipes(
      {
        first: recipesIdList.length,
        where: { id_in: recipesIdList },
      },
      !recipesIdList.length,
    );

    Promise.all([articlesPromise, recipesPromise])
      .then(([articlesResponse, recipesResponse]) => {
        const mergedContent: CategoryFormSectionBoardContent[] = [];

        if (articlesResponse?.data?.articles?.length) {
          const { articles } = articlesResponse.data;

          articles.forEach((article) => {
            // eslint-disable-next-line fp/no-mutating-methods
            mergedContent.push({
              ...article,
              ...(sectionContent.find(
                (item) => item.id === article.id,
              ) as CategoryFormSectionBoardContent),
            });
          });
        }

        if (recipesResponse?.data?.recipes?.length) {
          const { recipes } = recipesResponse.data;

          recipes.forEach((recipe) => {
            // eslint-disable-next-line fp/no-mutating-methods
            mergedContent.push({
              ...recipe,
              ...(sectionContent.find(
                (item) => item.id === recipe.id,
              ) as CategoryFormSectionBoardContent),
            });
          });
        }

        const sortedByOrderMergedContent = sortBy(
          mergedContent,
          (article) => article.order,
        );

        if (isMounted()) {
          sectionHandlers.handleSectionChange({
            fieldName: 'content',
            opts: { setFormPristine: true },
            value: sortedByOrderMergedContent,
          });
        }
      })
      .catch((error) => {
        pushNotification({
          type: NotificationTypeEnum.error,
          message: `[Unable to fetch content details for section "${
            sectionProps.title
          }"]
              ${(error as Error).message}
            `,
        });
      })
      .finally(() => {
        if (isMounted()) {
          timeoutId = setTimeout(() => {
            setIsLoading(false);
          }, 500);
        }
      });
  }, [
    isMounted,
    pushNotification,
    searchArticles,
    searchRecipes,
    sectionContent,
    sectionHandlers,
    sectionProps.title,
  ]);

  useOnMount(() => {
    if (!sectionContent.every((content) => !!content.__typename)) {
      fetchContent();
    }
  });

  useEffect(
    () => () => {
      timeoutId && clearTimeout(timeoutId);
    },
    [],
  );

  return { content: sectionContent, handlers, isLoading };
};
