import { datadogRum } from '@datadog/browser-rum';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import {
  CropDialog,
  LockStatus,
  NotificationTypeEnum,
  PageWithDrawer,
  useConcurrentialAccess,
  useDialog,
  useNotification,
} from '@prismamedia/one-components';
import 'cropperjs/dist/cropper.css';
import { format } from 'date-fns';
import { isEmpty } from 'lodash';
import { findIndex } from 'ramda';
import {
  FC,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { RouteComponentProps, generatePath } from 'react-router-dom';
import {
  ArticleFormat,
  ArticleStatus,
  BrandKey,
  GetArticle_article,
} from '../../__generated__/queries-web';
import {
  useArticleUpsert,
  useUpdateArticleLockerId,
} from '../../apollo/mutations/article.web.graphql';
import {
  useArticleGetter,
  useGetArticle,
} from '../../apollo/queries/articles.web.graphql';
import { ArticlesRelatedMediaDialog } from '../../components/ArticlesRelatedMediaDialog';
import { paths } from '../../routing/Router/paths';
import { DATADOG_ACTIONS } from '../../tracking/actions';
import { MediaBlock } from '../../types/draft';
import { assert } from '../../utils/assert';
import { auth } from '../../utils/auth';
import { parseDraft } from '../../utils/draft';
import { standardizeArticleUrl } from '../../utils/url';
import { AppBar } from './AppBar';
import { DrawerContent } from './DrawerContent';
import { Grid } from './Grid';
import { UploadButton } from './UploadButton';
import { computeUpsertArticleVariables } from './computeUpsertArticleVariables';
import { actions, initialState, reducer } from './reducer';
import { useStyles } from './styles';

interface SlideshowEditProps {
  articleData: GetArticle_article;
  afterSave?: (id: string) => void;
  history?: RouteComponentProps['history'];
}

const SlideshowEdit: FC<SlideshowEditProps> = ({
  articleData,
  afterSave,
  history,
}) => {
  const getArticle = useArticleGetter();
  const [upsert] = useArticleUpsert();
  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    article: articleData,
  });
  const classes = useStyles();
  const { openDialog, closeDialog } = useDialog();
  const { pushNotification } = useNotification();

  const { article, selectedSlidesIndex, slideMap, slidesIndex } = state;
  const body = article && article.body;

  const updateLockerId = useUpdateArticleLockerId();
  const [lockStatus, setLockStatus] = useState<LockStatus | undefined | null>(
    undefined,
  );

  const onLock = useCallback(
    async (firstLock) => {
      const { data } = await updateLockerId(
        articleData.id,
        auth?.user?.id || null,
        !firstLock,
      );
      setLockStatus(data?.updateArticle?.articleExtension);
    },
    [articleData.id, updateLockerId],
  );

  useConcurrentialAccess({
    lockStatus: articleData.articleExtension || {},
    unlockTimeout: 600000,
    currentUser: auth.user,
    onLock,
    onNavigateBack: () => history?.push(paths.SLIDESHOW_LIST),
    disableLockForV0: true,
  });

  const getImagesWithoutCredit = (draft: MediaBlock[]): number[] => {
    return draft
      .map(({ data, key }: MediaBlock): string | boolean =>
        !data.credit && !data.iframely.meta.credit ? key : false,
      )
      .filter(Boolean)
      .map((key: string | boolean): number =>
        findIndex((slideIndex) => key === slideIndex, slidesIndex),
      );
  };

  const imagesWithoutCredit = getImagesWithoutCredit(Object.values(slideMap));
  useEffect(() => {
    if (!body) {
      return;
    }
    const draft = parseDraft(body);
    dispatch(
      actions.setSlides({
        slides: draft.blocks.filter(
          (b: any) => b.type === 'atomic',
        ) as MediaBlock[],
      }),
    );
  }, [body, dispatch]);

  const validateSlideShow = useCallback(
    (status: ArticleStatus, hasCredit: boolean) => {
      if (
        [ArticleStatus.Published, ArticleStatus.Scheduled].includes(status) &&
        !hasCredit
      ) {
        const errorMessage =
          'Le champ crédit doit être renseigné pour les images suivantes :';

        imagesWithoutCredit.length > 0 &&
          openDialog(
            <>
              <DialogTitle>{errorMessage}</DialogTitle>
              <DialogContent>
                {imagesWithoutCredit.map((item, index) => (
                  <span key={item}>
                    {index === imagesWithoutCredit.length - 1
                      ? `${item + 1}`
                      : ` ${item + 1} , `}
                  </span>
                ))}
              </DialogContent>
              <DialogActions>
                <Button onClick={closeDialog} color="primary">
                  OK
                </Button>
              </DialogActions>
            </>,
          );
        return false;
      }
      return true;
    },
    [openDialog, closeDialog, imagesWithoutCredit],
  );

  const saveSlideshow = useCallback(
    async (forcedArticle?: GetArticle_article) => {
      const articleToSave = forcedArticle || article;
      if (!articleToSave) {
        return;
      }
      if (
        !validateSlideShow(
          articleToSave.status,
          imagesWithoutCredit.length === 0,
        )
      ) {
        return;
      }

      let serverVersionArticle = articleToSave;

      if (
        articleToSave.status === ArticleStatus.Published &&
        !articleToSave.publishedAt
      ) {
        // eslint-disable-next-line immutable/no-mutation
        articleToSave.publishedAt = format(
          Date.now(),
          "yyyy-MM-dd'T'HH:mm:ss.SSS",
        );
      }

      if (articleToSave.id) {
        const { data } = await getArticle(articleToSave.id);
        if (data.article) {
          serverVersionArticle = data.article;
        }
      }
      const variables = computeUpsertArticleVariables(
        serverVersionArticle,
        articleToSave,
        slideMap,
        slidesIndex,
      );

      try {
        if (!articleToSave.title) {
          throw new Error('Un titre est requis pour enregistrer ce diapo');
        }

        datadogRum.addAction(DATADOG_ACTIONS.SAVE_DIAPO, {
          articleDiapo: articleToSave,
        });

        const result = await upsert({ variables });

        if (!result || !result.data || !result.data.upsertArticle) {
          console.error('error', result);
          pushNotification({
            type: NotificationTypeEnum.error,
            message: "Erreur lors de l'enregistrement",
          });
          return;
        }

        if (afterSave) {
          afterSave(result.data.upsertArticle.id);
        }
        pushNotification({
          type: NotificationTypeEnum.success,
          message: 'Diaporama enregistré',
        });
      } catch (e) {
        pushNotification({
          type: NotificationTypeEnum.error,
          message:
            e instanceof Error ? e.message : "Erreur lors de l'enregistrement",
        });
      }
    },
    [
      article,
      slidesIndex,
      slideMap,
      upsert,
      afterSave,
      pushNotification,
      imagesWithoutCredit,
      getArticle,
      validateSlideShow,
    ],
  );

  useEffect(() => {
    const handleEvent = (e: KeyboardEvent) => {
      if (e.key === 's' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        e.stopImmediatePropagation();
        saveSlideshow();
      }
    };

    window.addEventListener('keydown', handleEvent);

    return () => {
      window.removeEventListener('keydown', handleEvent);
    };
  }, [saveSlideshow]);

  const selectedSlides = useMemo(
    () => selectedSlidesIndex.map((key) => slideMap[key]),
    [selectedSlidesIndex, slideMap],
  );

  const selectedSlideMeta = assert(
    selectedSlides,
    (slides) => slides[0].data.iframely.meta,
  );

  if (!articleData) {
    return <Typography>ERROR</Typography>;
  }
  const id = (article && article.id) || undefined;
  const title = (article && article.title) || '';
  const brandKey = (article && article.brandKey) || null;
  const publishedAt =
    (article &&
      article.publishedAt &&
      format(new Date(article.publishedAt), "yyyy-MM-dd'T'HH:mm:ss.SSS")) ||
    '';
  const categoryId =
    (article &&
      article.articleCategories.length > 0 &&
      article.articleCategories[0].category.id) ||
    '';
  const status = article && (article.status as ArticleStatus);
  const publicUrl =
    articleData && standardizeArticleUrl(articleData.contextualizedUrl);

  const updateSlideshow = async (newStatus: ArticleStatus) => {
    if (!validateSlideShow(newStatus, imagesWithoutCredit.length === 0)) {
      return;
    }
    await saveSlideshow({
      ...article,
      status: newStatus,
    } as GetArticle_article);

    dispatch(actions.setArticleStatus({ status: newStatus }));
  };

  const changeStatus = async (newStatus: ArticleStatus) => {
    if (newStatus === 'Deleted' && article?.format === 'Slideshow') {
      openDialog(
        <ArticlesRelatedMediaDialog
          idMedia={article?.slug || undefined}
          title={article.title}
          articleFormat={article.format}
          handleDelete={async () => {
            await updateSlideshow(newStatus);
            closeDialog();
          }}
          handleClose={closeDialog}
        />,
        { maxWidth: 'lg' },
      );
    } else {
      await updateSlideshow(newStatus);
    }
  };

  const getSlidesCaptions = () => {
    if (!isEmpty(slideMap)) {
      return Object.values(slideMap).map(({ data }) => data.caption);
    }
  };

  const selectedFocusPoint = selectedSlides[0]?.data.focusPoint;

  return (
    <>
      <AppBar
        id={id}
        brandKey={brandKey}
        slidesIndex={slidesIndex}
        selectedSlidesIndex={selectedSlidesIndex}
        title={title}
        slug={article?.slug}
        format={article?.format}
        status={status}
        changeStatus={changeStatus}
        saveSlideshow={saveSlideshow}
        dispatch={dispatch}
        publicUrl={publicUrl}
        slideMap={slideMap}
        openCropModal={() => {
          openDialog(
            <CropDialog
              closeDialog={closeDialog}
              imageSrc={selectedSlideMeta.url}
              config={{
                height: selectedSlideMeta.height,
                width: selectedSlideMeta.width,
              }}
              initialCrop={assert(
                selectedSlides,
                (slides) => slides[0].data.crop,
              )}
              initialFocusPoint={selectedFocusPoint}
              onValidate={({ crop, focusPoint }) => {
                dispatch(
                  actions.setSlideCrop({
                    slideKey: selectedSlides[0].key,
                    crop,
                    focusPoint,
                  }),
                );
              }}
            />,
            { fullScreen: true },
          );
        }}
        articleHistories={articleData.articleHistories}
        updateLockerId={updateLockerId}
        concurrentialAccess={{
          lockStatus: lockStatus || articleData.articleExtension,
          disableLockForV0: true,
        }}
      />

      <PageWithDrawer
        fullWidth
        className={classes.content}
        paddingTop={selectedSlides.length ? 48 : 0}
        rightDrawer={
          article ? (
            <DrawerContent
              categoryId={categoryId}
              dispatch={dispatch}
              publishedAt={publishedAt}
              selectedSlides={selectedSlides}
              article={article}
            />
          ) : undefined
        }
      >
        <Grid
          dispatch={dispatch}
          selectedSlidesIndex={selectedSlidesIndex}
          slidesIndex={slidesIndex}
          slideMap={slideMap}
          brandKey={brandKey}
        />
        <UploadButton
          brandKey={brandKey}
          articleStatus={status}
          dispatch={dispatch}
          captions={getSlidesCaptions()}
        />
      </PageWithDrawer>
    </>
  );
};

export const EditSlideshowWrapper: FC<RouteComponentProps<{
  id: string;
}>> = ({ match, history }) => {
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const { loading, data } = useGetArticle(match.params.id!);
  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.centered}>
        <CircularProgress size={80} />
      </div>
    );
  }

  if (!data || !data.article) {
    return <Typography>ERROR</Typography>;
  }

  return <SlideshowEdit articleData={data.article} history={history} />;
};

export const NewSlideshowWrapper: FC<RouteComponentProps<{
  brandKey: string;
}>> = ({ match, history }) => {
  const article: GetArticle_article = {
    __typename: 'Article',
    id: '',
    title: '',
    body: '{ "blocks": [], "entityMap": [] }',
    brandKey: match.params.brandKey as BrandKey,
    publishedAt: null,
    createdAt: '',
    format: ArticleFormat.Slideshow,
    status: ArticleStatus.Draft,
    articleExtension: null,
    articleHistories: [],
    articleCategories: [],
    articleTags: [],
    articleUrls: [],
    contextualizedUrl: null,
    articleAuthors: [],
    articleChannels: [],
    parentArticle: [],
    articleCreatorData: null,
    articleEvents: [],
    articleGuides: [],
    articleMachineTags: [],
    articleQualifiers: [],
    editedAt: null,
    lead: null,
    live: null,
    medias: null,
    partnerTitle: null,
    pushCount: 0,
    secondaryTitle: null,
    slug: null,
    source: null,
    platformId: null,
  };
  const afterSave = (id: string) => {
    history.push(generatePath(paths.SLIDESHOW_EDIT, { id }));
  };
  return <SlideshowEdit articleData={article} afterSave={afterSave} />;
};

export const DuplicateSlideshowWrapper: FC<RouteComponentProps<{
  brandKey: BrandKey;
  id: string;
}>> = ({ match, history }) => {
  const { data, loading } = useGetArticle(match.params.id);
  const classes = useStyles();

  if (loading) {
    return (
      <div className={classes.centered}>
        <CircularProgress size={80} />
      </div>
    );
  }

  if (!data || !data.article) {
    return <Typography>ERROR</Typography>;
  }

  const duplicate = {
    ...data.article,
    status: ArticleStatus.Draft,
    brandKey: match.params.brandKey,
    id: '',
  };

  const afterSave = (id: string) => {
    history.push(generatePath(paths.SLIDESHOW_EDIT, { id }));
  };

  return <SlideshowEdit articleData={duplicate} afterSave={afterSave} />;
};
