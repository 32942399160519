import { Create, DragIndicator } from '@mui/icons-material';
import {
  Box,
  IconButton,
  ListItemText,
  MenuItem,
  TextField,
} from '@mui/material';
import clsx from 'clsx';
import type { ChangeEvent, KeyboardEvent, MouseEvent } from 'react';
import { forwardRef, useCallback, useContext, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import type { AdditionalSortableItemProps } from '../../../../../../../components/DragAndDrop/SortableItem';
import { ErrorIcon } from '../../../../../components/ErrorIcon';
import { FormContext } from '../../../../../form.context';
import { getErrorMessages } from '../../../../../utils';
import type { SectionForm } from '../../Section/types';
import { useEditSection } from '../../Section/useEditSection';
import { DEFAULT_SECTION_TITLE } from '../constants';
import { useStyles as useSharedStyles } from '../styles';
import { useStyles } from './styles';

type SectionProps = SectionForm &
  AdditionalSortableItemProps & {
    onClickSection: (id: string) => void;
  };
export const Section = forwardRef<HTMLButtonElement, SectionProps>(
  ({ id, onClickSection, order, title, ...rest }, ref) => {
    const { isFormTouched } = useContext(FormContext);
    const history = useHistory();
    const classes = useStyles();
    const sharedClasses = useSharedStyles();
    const [initialFieldValue, setInitialFieldValue] = useState(title);
    const [isEditModeActive, setIsEditModeActive] = useState(false);

    const {
      errors: sectionErrors,
      form: sectionForm,
      handlers: sectionHandlers,
    } = useEditSection(id);

    const sectionErrorMessages = useMemo(
      () => getErrorMessages(sectionErrors),
      [sectionErrors],
    );

    const handleFieldChange = useCallback(
      (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        sectionHandlers.handleSectionChange({
          fieldName: 'title',
          value: event.target.value,
        });
      },
      [sectionHandlers],
    );

    const handleFieldKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
      if (event.key === 'Enter') {
        exitEditMode({ withReset: false });
        setInitialFieldValue(title);
      } else if (event.key === 'Escape') {
        exitEditMode({ withReset: true });
      }
    };

    const exitEditMode = useCallback(
      ({ withReset }) => {
        setIsEditModeActive(false);

        if (withReset) {
          sectionHandlers.handleSectionChange({
            fieldName: 'title',
            value: initialFieldValue,
          });
        }
      },
      [initialFieldValue, sectionHandlers],
    );

    return (
      <Box ref={ref} className={classes.wrapper}>
        <MenuItem
          className={clsx(sharedClasses.menuItem, classes.sectionItem, {
            [classes.noTitle]: !title?.length,
          })}
          classes={{ selected: sharedClasses.menuItemSelected }}
          onClick={() => {
            onClickSection(id);
          }}
          selected={history.location.hash.substring(1) === id}
        >
          {isEditModeActive ? (
            <TextField
              autoFocus
              className={classes.textField}
              fullWidth
              hiddenLabel
              onBlur={() => {
                exitEditMode({ withReset: true });
              }}
              onChange={handleFieldChange}
              onFocus={() => {
                onClickSection(id);
              }}
              onKeyDown={handleFieldKeyDown}
              value={sectionForm?.title}
              variant="standard"
              placeholder={
                !title ? `${DEFAULT_SECTION_TITLE} ${order + 1}` : ''
              }
              size="small"
            />
          ) : (
            <>
              <ListItemText>
                {title || `${DEFAULT_SECTION_TITLE} ${order + 1}`}
              </ListItemText>
              <IconButton
                className={classes.editIconButton}
                color="primary"
                component="span"
                disableRipple
                onClick={(event: MouseEvent<HTMLAnchorElement>) => {
                  event.stopPropagation();
                  setIsEditModeActive(!isEditModeActive);
                }}
              >
                <Create fontSize="small" color="secondary" />
              </IconButton>

              {!!sectionErrorMessages.length && (
                <ErrorIcon
                  errorMessages={sectionErrorMessages}
                  isTouched={isFormTouched}
                />
              )}
            </>
          )}

          {/* Drag button */}
          <IconButton
            className={classes.dragIconButton}
            disableRipple
            size="small"
            {...rest.attributes}
            {...rest.listeners}
          >
            <DragIndicator fontSize="small" />
          </IconButton>
        </MenuItem>
      </Box>
    );
  },
);

// eslint-disable-next-line immutable/no-mutation
Section.displayName = 'DrawerContentSection';
