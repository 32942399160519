import { getOneMediaContentBlocksErrors } from '@prismamedia/one-components';
import type { RawDraftContentState } from 'draft-js';
import { useMemo } from 'react';
import type { CategoryFormSectionTextContent } from '../types';
import { useEditSection } from '../useEditSection';
import type { TextProps } from './';

interface Handlers {
  handleChangeText: { (rawDraft: RawDraftContentState): void };
}

interface UseEditTextProps {
  content: CategoryFormSectionTextContent;
  handlers: Handlers;
}

export const useEditText = (sectionProps: TextProps): UseEditTextProps => {
  const { errors, handlers: sectionHandlers } = useEditSection(sectionProps.id);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const sectionContent = useMemo(() => sectionProps.content!, [
    sectionProps.content,
  ]);

  const handlers = useMemo(
    (): Handlers => ({
      handleChangeText: (newRawDraft) => {
        if (newRawDraft) {
          const newContent = { ...sectionContent, body: newRawDraft };
          const newSectionErrors = {
            ...errors,
            content: {
              body: getOneMediaContentBlocksErrors(newRawDraft.blocks),
            },
          };

          sectionHandlers.handleSectionChange({
            fieldName: 'content',
            value: newContent,
            errors: newSectionErrors as any,
            opts: { setFormPristine: false },
          });
        }
      },
    }),
    [errors, sectionContent, sectionHandlers],
  );

  return { content: sectionContent, handlers };
};
