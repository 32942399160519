import { Attachment, ExpandMore } from '@mui/icons-material';
import { List } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import {
  CharacterMetadata,
  ContentBlock,
  EntityInstance,
  RawDraftContentState,
  RawDraftEntity,
} from 'draft-js';
import { editorStateFromRaw } from 'megadraft';
import { FC, SyntheticEvent, useState } from 'react';
import { PossibleActions } from '../../../../components/Draft/DraftActions';
import {
  patternPeopleLinkRegex,
  patternRecipeLinkRegex,
  patternSlideShowRegex,
} from './constants';
import {
  EntityType,
  GetLinksType,
  LabelLinkType,
  LinkType,
  LinksContentProps,
} from './types';

const getEntities = (editorState: typeof editorStateFromRaw) => {
  const content = editorState.getCurrentContent();
  const entities: EntityType[] = [];
  content.getBlocksAsArray().forEach((block: ContentBlock) => {
    let selectedEntity: Omit<EntityType, 'start' | 'end'>;
    const filterEntityRanges = (character: CharacterMetadata) => {
      const entity = character.getEntity();
      if (entity !== null) {
        const entityContent: EntityInstance = content.getEntity(entity);
        selectedEntity = {
          entityKey: entity,
          blockKey: block.getKey(),
          entity: entityContent,
        };
        return true;
      }
      return false;
    };
    const callback = (start: number, end: number) =>
      // eslint-disable-next-line fp/no-mutating-methods
      entities.push({ ...selectedEntity, start, end });

    block.findEntityRanges(filterEntityRanges, callback);
  });
  return entities;
};

const getLinks = (
  textLinks: string[],
  entity?: RawDraftContentState['entityMap'],
): GetLinksType[] => {
  const getType = (
    type: LinkType,
    duplicate: boolean,
    link?: string,
  ): LabelLinkType => {
    if (duplicate) return 'Doublon';

    if (
      link &&
      [PossibleActions.LINK, PossibleActions.INTERNAL_LINK].includes(type)
    ) {
      if (link.match(new RegExp(patternRecipeLinkRegex))) return 'Recipe';
      if (link.match(new RegExp(patternSlideShowRegex))) return 'Diapo';
    }

    if (
      type === PossibleActions.LINK &&
      link?.match(new RegExp(patternPeopleLinkRegex))
    )
      return 'People';

    return 'Lien';
  };

  const entriesReduce = (
    accum: GetLinksType[],
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    [_, { data, type }]: [unknown, RawDraftEntity],
    i: number,
  ) => {
    const currentLink = data?.url;
    return [
      ...accum,
      {
        label: `${data?.children || textLinks[i]}`,
        link: `${currentLink}`,
        type: getType(
          type as LinkType,
          !!accum.find(({ link }) => link === currentLink),
          currentLink,
        ),
      },
    ];
  };

  return Object.entries(entity || {})?.reduce(entriesReduce, []);
};

export const LinksContent: FC<LinksContentProps> = ({ body }) => {
  const [expanded, setExpanded] = useState<string>();
  const entities = getEntities(editorStateFromRaw(body));
  const textLinks: string[] = entities.reduce((accum: string[], entity) => {
    const findBlock = body?.blocks.find(({ key }) => key === entity.blockKey);
    if (findBlock) {
      return [...accum, findBlock.text.substring(entity.start, entity.end)];
    }
    return accum;
  }, []);

  const handleChange = (panel: string) => (
    event: SyntheticEvent,
    newExpanded: boolean,
  ) => setExpanded(newExpanded ? panel : '');

  const links = getLinks(textLinks, body?.entityMap);

  return (
    <Accordion
      sx={{
        width: '100%',
        boxShadow: 'none',
        border: 'solid',
        borderWidth: '1px 0 1px 0',
        borderColor: 'divider',
        '&:not(:last-child)': {
          borderBottom: 0,
        },
        '&:before': {
          display: 'none',
        },
        marginTop: '0 !important',
      }}
      expanded={expanded === 'Liens'}
      onChange={handleChange('Liens')}
    >
      <AccordionSummary
        sx={{
          py: 2,
          '& .icon': {
            display: 'flex',
            '& p': {
              paddingLeft: '1rem',
              fontWeight: 500,
            },
          },
          '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
            transform: 'rotate(180deg)',
          },
          minHeight: 'auto !important',
          '& .MuiAccordionSummary-content': {
            marginLeft: '1px',
            marginTop: '0 !important',
            marginBottom: '0 !important',
          },
        }}
        expandIcon={<ExpandMore />}
      >
        <div className="icon">
          <Attachment />
          <Typography>{`Liens de l'article (${links.length})`}</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails
        sx={{
          padding: '0.5px',
          borderTop: '1px solid rgba(0, 0, 0, .125)',
        }}
      >
        <List component="div" disablePadding>
          {links?.map(({ label, link, type }) => (
            <ul key={link}>
              <li>
                <Typography
                  component="span"
                  sx={{
                    fontWeight: 500,
                    fontSize: '0.85rem',
                    color: `${type === 'Doublon' ? 'green' : 'black'}`,
                  }}
                >
                  {type}
                </Typography>
                :{' '}
                <a
                  style={{
                    color: `${type === 'Doublon' ? 'green' : 'black'}`,
                    fontSize: '0.85rem',
                  }}
                  target="_blank"
                  rel="noreferrer"
                  href={link}
                >
                  {label}
                </a>
              </li>
            </ul>
          ))}
        </List>
      </AccordionDetails>
    </Accordion>
  );
};
