import { getBrandLang } from '@prismamedia/one-brandkey';
import { BrandKey } from '../__generated__/queries-web';
import { useGetBrandPrompts } from '../apollo/queries/prompts.web.graphql';
import {
  BRAND_KEY_LANGUAGE,
  GENERATE_PROMPT,
  PARAPHRASE_PROMPT,
} from '../components/TextSuggestion/constants';

type PromptsKeys = 'Générer' | 'Paraphraser';

type Prompts = Record<
  PromptsKeys,
  {
    field: string;
    prompt: string;
    style: string;
    brandKey: BrandKey;
  }
>;

export const useOpenAiPrompts = (brandKey: BrandKey) => {
  const brandLang = BRAND_KEY_LANGUAGE[getBrandLang(brandKey)];
  const { data } = useGetBrandPrompts(brandKey);
  const prompts = data?.prompts?.reduce<Prompts>(
    // eslint-disable-next-line fp/no-mutating-assign
    (acc, curr) => Object.assign(acc, { [curr.label]: curr.body }),
    {} as Prompts,
  );
  const generatePrompt =
    (prompts && prompts['Générer']?.prompt.replaceAll('$lang', brandLang)) ||
    GENERATE_PROMPT(brandLang);
  const paraphrasePrompt =
    (prompts &&
      prompts['Paraphraser']?.prompt.replaceAll('$lang', brandLang)) ||
    PARAPHRASE_PROMPT(brandLang);

  return { generatePrompt, paraphrasePrompt };
};
