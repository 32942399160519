import { Delete } from '@mui/icons-material';
import {
  Box,
  Fab,
  FormControl,
  FormControlLabel,
  Switch,
  TextField,
} from '@mui/material';
import { forwardRef, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Loader } from '../../../../../../components/Loader';
import { navigateTo } from '../../../../utils';
import { ALLOWED_CATEGORY_FORM_SECTIONS } from '../../constants';
import { useStyles as useSharedStyles } from '../../styles';
import { SectionFormRenderer } from './SectionForm.renderer';
import {
  CATEGORY_FORM_SECTION_DESCRIPTION_LABEL,
  CATEGORY_FORM_SECTION_IS_ACTIVE_LABEL,
  CATEGORY_FORM_SECTION_IS_INACTIVE_LABEL,
  CATEGORY_FORM_SECTION_TITLE_LABEL,
} from './constants';
import { useStyles } from './styles';
import type { SectionForm } from './types';
import { useEditSection } from './useEditSection';

export const Section = forwardRef<HTMLButtonElement, SectionForm>(
  (sectionProps, ref) => {
    const { errors, handlers: sectionHandlers } = useEditSection(
      sectionProps.id,
    );

    const history = useHistory();
    const classes = useStyles({
      isSelected: history.location.hash.substring(1) === sectionProps.id,
      sectionIconType: sectionProps.type,
    });
    const sharedClasses = useSharedStyles();

    const SectionIcon = useMemo(
      () =>
        ALLOWED_CATEGORY_FORM_SECTIONS.find(
          ({ type: allowedSectionType }) =>
            allowedSectionType === sectionProps.type,
        )?.icon,
      [sectionProps.type],
    );

    return (
      <Box
        id={sectionProps.id}
        className={classes.wrapper}
        component="section"
        onClick={() => {
          navigateTo({ opts: { hash: sectionProps.id } });
        }}
        ref={ref}
      >
        <Box>
          <Box className={classes.sectionBgIcon}>{SectionIcon}</Box>

          <Box className={classes.header} component="header">
            <Box>
              <FormControlLabel
                className={classes.activationField}
                control={
                  <Switch
                    sx={{ zoom: 1.2 }}
                    checked={sectionProps.isActive}
                    onChange={(event) => {
                      sectionHandlers.handleSectionChange({
                        fieldName: 'isActive',
                        value: event.target.checked,
                      });
                    }}
                    size="small"
                  />
                }
                label={
                  sectionProps.isActive
                    ? CATEGORY_FORM_SECTION_IS_ACTIVE_LABEL
                    : CATEGORY_FORM_SECTION_IS_INACTIVE_LABEL
                }
              />
            </Box>

            <Fab
              className={classes.deleteIcon}
              color="primary"
              onClick={() => {
                sectionHandlers.handleSectionDelete();
              }}
              size="small"
            >
              <Delete />
            </Fab>
          </Box>

          <Box className={classes.innerWrapper} component="div">
            {!errors ? (
              <Loader />
            ) : (
              <>
                <FormControl className={sharedClasses.wrapperField} fullWidth>
                  <TextField
                    {...(errors?.title.isTouched && {
                      helperText:
                        errors?.title.errorMessages[errors.title.errors[0]],
                    })}
                    error={errors?.title.isTouched && errors.title.hasError}
                    label={CATEGORY_FORM_SECTION_TITLE_LABEL}
                    onChange={(event) => {
                      sectionHandlers.handleSectionChange({
                        fieldName: 'title',
                        value: event.target.value,
                      });
                    }}
                    required
                    value={sectionProps.title}
                    variant="standard"
                  />
                </FormControl>

                <FormControl className={sharedClasses.wrapperField} fullWidth>
                  <TextField
                    label="Indication technique"
                    onChange={(event) => {
                      sectionHandlers.handleSectionChange({
                        fieldName: 'metas',
                        value: event.target.value,
                      });
                    }}
                    value={sectionProps.metas}
                    variant="standard"
                  />
                </FormControl>

                <FormControl className={sharedClasses.wrapperField} fullWidth>
                  <TextField
                    label={CATEGORY_FORM_SECTION_DESCRIPTION_LABEL}
                    onChange={(event) => {
                      sectionHandlers.handleSectionChange({
                        fieldName: 'description',
                        value: event.target.value,
                      });
                    }}
                    value={sectionProps.description}
                    variant="standard"
                  />
                </FormControl>

                {/* Component based on section type */}
                <SectionFormRenderer {...sectionProps} />
              </>
            )}
          </Box>
        </Box>
      </Box>
    );
  },
);

// eslint-disable-next-line immutable/no-mutation
Section.displayName = 'Section';
