import Parser from 'rss-parser';
import type { PodcastEpisodeType, PodcastType } from './types';

export let seriesRequestController = new AbortController();
let episodesRequestController = new AbortController();
let cachedResponseAsJSON: PodcastType[];
let cachedTitle: string;

const parser = new Parser();
export const PLAYER_URL = config.API_PLAYER_BO;
const SUBMIT_PODCAST_URL = `${PLAYER_URL}/submit-podcast`;

export const abortRequestController = async (
  requestController: AbortController,
) => {
  requestController?.abort();
  // eslint-disable-next-line no-param-reassign
  requestController = new AbortController();

  return requestController;
};

export const getPodcasts = async (title: string): Promise<PodcastType[]> => {
  // We returns cached list if necessary and avoid useless call
  if (title === cachedTitle && cachedResponseAsJSON?.length) {
    return cachedResponseAsJSON;
  }

  // We in-flight aborting requests
  seriesRequestController = await abortRequestController(
    seriesRequestController,
  );

  const url = `${PLAYER_URL}/podcasts?q=${title}&presta=1`;
  const response = await fetch(url, {
    signal: seriesRequestController.signal,
  });

  const responseAsJSON = await response.json();

  // We locally cached response to avoid useless calls
  cachedResponseAsJSON = responseAsJSON;
  cachedTitle = title;

  return responseAsJSON;
};

export const getPodcastImageFromPodcastFeed = async (podcastFeed: string) =>
  await parser.parseURL(podcastFeed);

export const getEpisodesFromPodcast = async (
  podcastId: string,
  text: string,
): Promise<PodcastEpisodeType[]> => {
  // We in-flight cancelling requests
  episodesRequestController = await abortRequestController(
    episodesRequestController,
  );

  const url = `${PLAYER_URL}/podcast/${podcastId}/episodes?q=${text}&presta=1`;
  const response = await fetch(url, {
    signal: episodesRequestController.signal,
  });

  const res = await response.json();

  return res;
};

export const submitPodcast = async (
  feed: string,
  title: string,
  playlist: string,
) => {
  const formdata = new FormData();
  formdata.append('feed', feed);
  formdata.append('title', title);
  formdata.append('playlist', playlist);

  const requestOptions = {
    method: 'POST',
    body: formdata,
  };

  const response = await fetch(SUBMIT_PODCAST_URL, requestOptions);
  const res = await response.json();
  return res;
};
